<template>
  <ValidationObserver v-slot="{ passes }">
    <b-modal id="modal-property" title="Modal title" role="dialog" size="xl" no-close-on-backdrop @hide="clearProperty">
      <template slot="modal-header">
        <h5>{{ $t(`form.createDocumentPropertyHeader`) }}</h5>
      </template>
      <template slot="default">
        <div class="form-group ">
          <ValidationProvider v-slot="{ errors, classes }" :name="$t(`form.name`)" rules="required">
            <label for="name">{{ $t(`form.documentPropertyTitel`) }}</label>
            <CustomInput id="name" v-model="name" :classes="classes" :is-mandatory="true" :errors="errors"></CustomInput>
          </ValidationProvider>
        </div>
        <div class="form-group ">
          <ValidationProvider v-slot="{ errors, classes }" :name="$t(`form.translation`)" rules="required">
            <label for="translation">{{ $t(`form.translation`) }}</label>
            <CustomInput id="translation" v-model="translation" :classes="classes" :is-mandatory="true" :errors="errors"></CustomInput>
          </ValidationProvider>
        </div>
        <div class="mt-4">
          <div class="custom-control custom-radio custom-control-inline">
            <input id="propertyTypeAlphanumeric" v-model="type" type="radio" name="propertyType" class="custom-control-input" value="0" />
            <label class="custom-control-label" for="propertyTypeAlphanumeric">{{ $t(`form.propertyTypeAlphanumeric`) }}</label>
          </div>
          <div class="custom-control custom-radio custom-control-inline">
            <input id="propertyTypeNumeric" v-model="type" type="radio" name="propertyType" class="custom-control-input" value="1" />
            <label class="custom-control-label" for="propertyTypeNumeric">{{ $t(`form.propertyTypeNumeric`) }}</label>
          </div>
          <div class="custom-control custom-radio custom-control-inline">
            <input id="propertyTypeDate" v-model="type" type="radio" name="propertyType" class="custom-control-input" value="2" />
            <label class="custom-control-label" for="propertyTypeDate">{{ $t(`form.propertyTypeDate`) }}</label>
          </div>
        </div>
      </template>
      <template slot="modal-footer" slot-scope="{ cancel }">
        <button type="button" class="btn btn-secondary" @click="cancel">
          {{ $t(`buttons.cancel`) }}
        </button>
        <button type="button" class="btn btn-primary m-1" @click="passes(submitProperty)">
          {{ $t(`buttons.save`) }}
        </button>
      </template>
    </b-modal>
  </ValidationObserver>
</template>
<script>
import DocumentPropertyApi from '../../services/api/documentProperty';
import CustomInput from '../common/CustomInput.vue';

export default {
  name: 'DocumentPropertyDialog',
  components: {
    CustomInput
  },
  data: function() {
    return {
      name: '',
      translation: '',
      type: 0
    };
  },
  methods: {
    clearProperty() {
      this.name = '';
      this.translation = '';
      this.type = 0;
    },
    hide() {
      this.$bvModal.hide('modal-property');
    },
    emitSuccess() {
      this.$emit('success');
      this.hide();
    },
    emitError() {
      this.$emit('error');
      this.hide();
    },
    submitProperty() {
      var model = {
        name: this.name,
        translation: this.translation,
        type: this.type
      };
      DocumentPropertyApi.postDocumentProperty(model)
        .then(response => {
          console.log('Successfully added document property', response);
          this.emitSuccess();
        })
        .catch(error => {
          console.log('Adding document property failed', error);
          this.emitError();
        });
    }
  }
};
</script>
