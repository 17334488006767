<template>
  <div>
    <h1>{{ title }}</h1>
    <br />
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    }
  }
};
</script>
