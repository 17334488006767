<template>
  <div class="container-fluid">
    <img src="../../assets/logo_gross.png" class="float-right" style="margin: 20px" />
    <div class="btn-group m-3 no-print">
      <button type="button" class="btn btn-primary mr-2" @click="navigateToOrderDetails">
        <i class="far fa-chevron-left"></i>
        {{ $t(`buttons.back`) }}
      </button>
      <button type="button" class="btn btn-primary mr-2" @click="print">
        <i class="far fa-print"></i>
        {{ $t(`buttons.print`) }}
      </button>
    </div>

    <table class="table table-bordered" style="margin-top: 50px">
      <tbody>
        <tr>
          <td scope="col" style="width: 20%">
            Empfänger
            <br />
            Receiver
          </td>
          <td>
            VSM <br />
            Vereinigte Schmirgel und <br />
            Maschinenfabriken <br />
            D-30165 Hannover
          </td>
        </tr>
        <tr>
          <td>
            Artikel Nr. / <br />
            Article no.
          </td>
          <td>
            <VueBarcode :value="order.materialNumber"></VueBarcode>
          </td>
        </tr>
        <tr>
          <td>
            Artikelbezeichnung / <br />
            Artikel Nr. Lieferant <br />
            Article designation / <br />
            Article no. supplier
          </td>
          <td>
            {{ order.materialDescription }} / <br />
            {{ order.supplierMaterialNumber }}
          </td>
        </tr>
        <tr>
          <td>
            Bestell Nr. / Bestellposition / Artikel Nr. <br />
            Order no. / Order position / Article no. <br />
          </td>
          <td>
            <VueBarcode :value="orderInformation"></VueBarcode>
          </td>
        </tr>
        <tr>
          <td>
            Anfertigungs Nr. / Charge / Lot <br />
            Manufactoring no. / Lot no. <br />
          </td>
          <td>
            <VueBarcode :value="lotNumber"></VueBarcode>
          </td>
        </tr>
        <tr>
          <td>
            Gewicht <br />
            Weight <br />
          </td>
          <td>
            <VueBarcode :value="weight"></VueBarcode>
          </td>
        </tr>
        <tr>
          <td>
            Datum <br />
            Date <br />
          </td>
          <td>
            {{ new Date() | localeDate }}
          </td>
        </tr>
        <tr>
          <td>
            Lieferant <br />
            Supplier <br />
          </td>
          <td>
            {{ order.supplierInformation.name }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import VueBarcode from 'vue-barcode';
import OrderApi from '../../services/api/order';
import router from '../../router';

export default {
  name: 'Barcode',
  components: {
    VueBarcode
  },
  props: {
    id: {
      type: String,
      default: () => ''
    },
    pos: {
      type: String,
      default: () => ''
    },
    lotNumber: {
      type: String,
      default: () => ''
    },
    weight: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      order: {
        materialNumber: '',
        positionNumber: '',
        supplierMaterialNumber: '',
        materialDescription: '',
        supplierInformation: {}
      }
    };
  },
  computed: {
    orderInformation: function() {
      return (
        this.order.orderNumber + ('000'.slice(this.order.positionNumber.length) + this.order.positionNumber) + this.order.materialNumber
      );
    }
  },
  mounted() {
    this.fetchOrderDetails();
  },
  methods: {
    navigateToOrderDetails() {
      router.push({ name: 'orderpositions', params: { id: this.order.id } });
    },
    print() {
      window.print();
    },
    fetchOrderDetails() {
      OrderApi.getOrder(this.id)
        .then(response => {
          console.log('fetch successful: ', response);
          this.setOrderPosition(response);
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.showSpinner = false;
        });
    },
    setOrderPosition(order) {
      this.order.orderNumber = order.orderNumber;
      this.order.supplierInformation = order.supplierInformation;
      this.order.id = order.id;

      var orderPosition = order.orderPositions.find(x => x.positionNumber === this.pos);

      if (orderPosition) {
        this.order.positionNumber = orderPosition.positionNumber;
        this.order.materialNumber = orderPosition.materialNumber;
        this.order.materialDescription = orderPosition.materialDescription;
        this.order.supplierMaterialNumber = orderPosition.supplierMaterialNumber;
      }
    }
  }
};
</script>
