import axios from 'axios';
import AxiosDefaults from './axios-defaults';

export default {
  getOrders() {
    return axios.get('/orders', AxiosDefaults.requestConfig).then(response => response.data);
  },
  getOrder(orderId) {
    return axios.get('/orders/' + orderId, AxiosDefaults.requestConfig).then(response => response.data);
  }
};
