<template>
  <div>
    <h4>
      {{ $t(`headers.materialMasterData`) }}
    </h4>
    <div class="row header mt-3">
      <div class="col-2 tableCol d-flex justify-content-center align-items-center">
        {{ $t(`questions.materialMasterData.vsmItem`) }}
      </div>
      <div class="col-3 tableCol d-flex justify-content-center align-items-center">
        {{ $t(`questions.materialMasterData.vsmItemDescripton`) }}
      </div>
      <div class="col-3 tableCol d-flex justify-content-center align-items-center">
        {{ $t(`questions.materialMasterData.itemId`) }}
      </div>
      <div class="col-3 tableCol d-flex justify-content-center align-items-center">
        {{ $t(`questions.materialMasterData.yourItemDescription`) }}
      </div>
      <div class="col-1 tableCol d-flex justify-content-center align-items-center"></div>
    </div>
    <div v-for="(item, index) in existingArticles" :key="index" class="row mt-2">
      <div class="col-2">
        <input v-model="item.article" type="text" class="form-control" :disabled="isReleased" @change="updateValue" />
      </div>
      <div class="col-3">
        <input v-model="item.description" type="text" class="form-control" :disabled="isReleased" @change="updateValue" />
      </div>
      <div class="col-3">
        <input v-model="item.yourId" type="text" class="form-control" :disabled="isReleased" @change="updateValue" />
      </div>
      <div class="col-3">
        <input v-model="item.yourDescription" type="text" class="form-control" :disabled="isReleased" @change="updateValue" />
      </div>
      <div class="col-1">
        <button type="button" :title="$t(`buttons.delete`)" class="btn btn-primary btn-small" @click="deleteRow(index)">
          <i class="far fa-trash-alt"></i>
        </button>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <button type="button" class="btn btn-primary" :disabled="isReleased" @click="addRow()">
          <i class="far fa-plus"></i>
          {{ $t(`messages.info.hoverButtonAdd`) }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import UserInfoStore from '../../auth/user-info-store';
import constants from '../../common/constants';
import UserApi from '../../services/api/user';

export default {
  name: 'SupplierEvaluationMaterialMasterData',
  props: {
    materialMasterData: {
      type: Array,
      default: () => []
    },
    isReleased: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      existingArticles: []
    };
  },
  mounted() {
    this.existingArticles = Object.assign([], this.materialMasterData);
    if (this.existingArticles.length === 0) {
      this.addRow();
    }
  },
  methods: {
    addRow() {
      this.existingArticles.push({ article: '', description: '', furtherDescription: '', yourId: '', yourDescription: '' });
    },
    deleteRow(index) {
      this.existingArticles.splice(index, 1);
      this.$emit('materialMasterDataUpdate', this.existingCertifications);
    },
    updateValue() {
      this.$emit('materialMasterDataUpdate', this.existingArticles);
    }
  }
};
</script>
<style scoped>
.header {
  background-color: var(--primary);
  color: white;
  background-clip: content-box;
  padding-left: 15px;
  padding-right: 15px;
}

.tableCol {
  height: 40px;
}

.no-margin {
  margin: 0;
}
</style>
