import axios from 'axios';
import AxiosDefaults from './axios-defaults';

export default {
  getSuppliers() {
    return axios.get('/suppliers', AxiosDefaults.requestConfig).then(response => response.data);
  },
  getSupplier(supplierId) {
    return axios.get('/suppliers/' + supplierId, AxiosDefaults.requestConfig).then(response => response.data);
  },
  putSupplier(supplier) {
    return axios.put('/suppliers/' + supplier.id, supplier, AxiosDefaults.requestConfig).then(response => response.data);
  },
  postSupplier(supplier) {
    return axios.post('/suppliers', supplier, AxiosDefaults.requestConfig).then(response => response.data);
  },
  deleteSupplier(supplierId) {
    return axios.delete('/suppliers/' + supplierId, AxiosDefaults.requestConfig).then(response => response);
  },
  getOrders(supplierId) {
    return axios.get('/suppliers/' + supplierId + '/orders', AxiosDefaults.requestConfig).then(response => response.data);
  }
};
