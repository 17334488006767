<template>
  <div>
    <div class="tab__header">
      <a
        href="#"
        class="tab__link p-4 block bg-blue-dark hover:bg-blue-darker no-underline border-b-2 border-white flex justify-between"
        @click.prevent="active = !active"
      >
        <strong class="float-left">
          <span v-show="showError" style="color: red;">
            <i class="far fa-exclamation-triangle"></i>
          </span>
          {{ title }}
        </strong>
        <span v-show="!active" class="down-Arrow float-right">&#9660;</span>
        <span v-show="active" class="up-Arrow float-right">&#9650;</span>
      </a>
    </div>
    <div v-show="active" class="tab__content p-2">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CollapsableCard',
  // eslint-disable-next-line vue/require-prop-types
  props: ['title', 'showError', 'collapsed'],
  data() {
    return {
      active: true
    };
  },
  mounted() {
    this.active = !this.collapsed;
  }
};
</script>
