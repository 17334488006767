<template>
  <div v-if="showSpinner" class="jumbotron">
    <p class="text-center">
      <font-awesome-icon :icon="['far', 'spinner']" size="2x" spin></font-awesome-icon>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    showSpinner: {
      type: Boolean,
      default: false
    }
  }
};
</script>
