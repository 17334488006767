<template>
  <div class="container-fluid">
    <ViewHeader :title="$t(`headers.orders`)"></ViewHeader>
    <div class="input-group mb-3">
      <input v-model="searchString" class="form-control" type="text" :placeholder="$t(`form.search`)" />
    </div>
    <OrderTable :show-spinner="showSpinner" :orders="filteredOrders" :show-found-column="showFoundHeader"></OrderTable>
  </div>
</template>

<script>
import ViewHeader from '../common/ViewHeader.vue';
import OrderTable from './OrderTable.vue';
import Utils from '../../common/utils';
import OrderApi from '../../services/api/order';
import router from '../../router';

export default {
  name: 'OrderList',
  components: {
    ViewHeader,
    OrderTable
  },
  data() {
    return {
      searchString: '',
      orders: [],
      showSpinner: false
    };
  },
  computed: {
    filteredOrders: function() {
      let searchStrings = this.searchString
        .toLowerCase()
        .trim()
        .split(' ');

      if (!searchStrings || (searchStrings.length === 1 && !searchStrings[0])) {
        this.orders.forEach(o => delete o.searchedOption);
        return this.orders;
      }

      return this.orders.filter(order => {
        for (let index = 0; index < searchStrings.length; index++) {
          const searchPart = searchStrings[index];

          if (Utils.containsString(order.orderNumber, searchPart)) {
            order.searchedOption = this.$t(`form.orderNumber`);
            return true;
          }
          if (Utils.containsString(order.orderNumberLong, searchPart)) {
            order.searchedOption = this.$t(`form.orderNumberLong`);
            return true;
          }
          if (Utils.containsString(new Date(order.confirmationDate).toLocaleString(), searchPart)) {
            order.searchedOption = this.$t(`form.confirmationDate`);
            return true;
          }

          if (order.orderPositions) {
            for (let posIndex = 0; posIndex < order.orderPositions.length; posIndex++) {
              let pos = order.orderPositions[posIndex];
              if (pos) {
                if (Utils.containsString(pos.materialDescription, searchPart)) {
                  order.searchedOption = this.$t(`form.materialDescription`);
                  return true;
                }
                if (Utils.containsString(pos.materialNumber, searchPart)) {
                  order.searchedOption = this.$t(`form.materialNumber`);
                  return true;
                }
                if (Utils.containsString(pos.supplierMaterialNumber, searchPart)) {
                  order.searchedOption = this.$t(`form.supplierMaterialNumber`);
                  return true;
                }
              }
            }
          }

          delete order.searchedOption;
          return false;
        }
        return true;
      });
    },
    showFoundHeader: function() {
      if (this.searchString) {
        return true;
      }
      return false;
    }
  },
  mounted() {
    this.fetchOrders();
  },
  methods: {
    fetchOrders() {
      this.orders = [];
      this.showSpinner = true;

      OrderApi.getOrders()
        .then(response => {
          console.log('fetch successful: ', response);
          response.result.forEach(order => {
            this.orders.push(order);
          });
          this.showSpinner = false;
        })
        .catch(error => {
          console.log(error);
          this.showSpinner = false;
        });
    }
  }
};
</script>
