<template>
  <div class="container-fluid">
    <ul class="list-group">
      <li v-for="(item, index) in documents" :key="index" class="list-group-item">
        <font-awesome-icon v-if="item.status === 'pending'" class="mr-2 icon-blue" :icon="['far', 'spinner']" spin></font-awesome-icon>
        <font-awesome-icon v-if="item.status === 'error'" class="mr-2 icon-red" :icon="['far', 'exclamation-triangle']"></font-awesome-icon>
        <font-awesome-icon v-if="item.status === 'success'" class="mr-2 icon-green" :icon="['far', 'check']"></font-awesome-icon>
        {{ item.title + ' (' + item.documentType[docTypeDisplay] + ')' }}
        <div v-if="item.status === 'error'" class="float-right" @click="retry(item)">
          <font-awesome-icon class="mr-2 clickable" :title="$t(`buttons.retry`)" :icon="['far', 'redo']"></font-awesome-icon>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'UploadStatus',
  props: {
    documents: {
      type: Array,
      default: () => []
    },
    docTypeDisplay: {
      type: String,
      default: () => 'name'
    }
  },
  methods: {
    retry(item) {
      console.log('retry event: ', item);
      this.$emit('retry', item);
    }
  }
};
</script>
<style scoped>
.clickable:hover {
  cursor: pointer;
  color: var(--secondary);
}

.icon-blue {
  color: var(--secondary);
}

.icon-green {
  color: green;
}

.icon-red {
  color: red;
}
</style>
