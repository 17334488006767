<template>
  <div class="container-fluid">
    <ViewHeader :title="$t(`headers.supplierDetails`)"></ViewHeader>
    <div v-if="supplier.deletionDate" class="alert alert-danger" role="alert">
      {{ $t(`messages.info.supplierMarkedForDeletion`, { date: new Date(supplier.deletionDate).toLocaleDateString() }) }}
    </div>
    <div class="row">
      <div class="col-12">
        <div class="btn-group mb-3">
          <button type="button" class="btn btn-primary mr-2" @click="navigateToList">
            <i class="far fa-chevron-left"></i>
            {{ $t(`buttons.back`) }}
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-9">
        <div class="form-group ">
          <label for="name">{{ $t(`form.supplierName`) }}</label>
          <input id="name" :value="supplier.name" readonly="readonly" type="text" class="form-control" />
        </div>
        <div class="form-row">
          <div class="col-6">
            <div class="form-group">
              <label for="supplierNumber">{{ $t(`form.supplierNumber`) }}</label>
              <input id="supplierNumber" :value="supplier.supplierNumber" readonly="readonly" type="number" class="form-control" />
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label for="tenantNumber">{{ $t(`form.tenantNumber`) }}</label>
              <input id="tenantNumber" :value="supplier.tenantNumber" readonly="readonly" type="text" class="form-control" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <h4>
      {{ $t(`form.users`) }}
    </h4>
    <div v-if="!showSpinner" class="row mt-2">
      <div
        v-for="(contact, index) in sortedContacts"
        :key="contact.id"
        class="card col-lg-3 col-5"
        style="margin:15px;padding:0"
        :style="contact.isEmailValid ? '' : 'background-color:#e9ecef'"
      >
        <div class="card-body">
          <div
            v-if="contact.isInvited && !contact.markedForDeletion"
            :title="$t(`messages.info.contactEnabled`)"
            class="custom-tooltip color-green"
          >
            <font-awesome-icon :icon="['far', 'check']"></font-awesome-icon>
          </div>
          <div
            v-if="contact.markedForDeletion"
            :title="$t(`messages.info.contactDeleted`, { date: contact.deletionDate.toLocaleDateString() })"
            class="custom-tooltip color-red"
          >
            <font-awesome-icon :icon="['far', 'times']"></font-awesome-icon>
          </div>

          <h5 class="card-title">
            {{ contact.firstName + ' ' + contact.lastName }}
          </h5>
          <p class="card-text">
            <font-awesome-icon
              v-if="!contact.isEmailValid"
              :title="$t(`messages.error.invalidEmail`)"
              class="color-red"
              :icon="['far', 'exclamation-triangle']"
            ></font-awesome-icon>
            {{ $t(`form.emailShort`) }}
            <a :href="'mailto:' + contact.emailAddress">{{ contact.emailAddress }}</a>
            <br />
            {{ $t(`form.phoneShort`) }}
            <a :href="'tel:' + contact.phoneNumber">{{ contact.phoneNumber }}</a>
          </p>
          <div class="btn-group float-right">
            <button
              type="button"
              class="btn btn-success mr-2"
              :disabled="contact.isInvited || !contact.isEmailValid || contact.markedForDeletion"
              @click="manageContactInvitation(contact, index)"
            >
              <i class="far fa-user-check"></i>
              {{ $t(`buttons.enable`) }}
            </button>
            <button
              type="button"
              class="btn btn-danger mr-2"
              :disabled="!contact.isInvited || !contact.isEmailValid || contact.markedForDeletion"
              @click="manageContactInvitation(contact, index)"
            >
              <i class="far fa-user-times"></i>
              {{ $t(`buttons.disable`) }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <spinner :show-spinner="showSpinner" />
  </div>
</template>

<script>
import ViewHeader from '../common/ViewHeader';
import SupplierApi from '../../services/api/supplier';
import ContactApi from '../../services/api/contact';
import UserApi from '../../services/api/user';
import router from '../../router';
import Toast from '../../common/toast';
import Vue from 'vue';
import Spinner from '../common/Spinner';
import { validate } from 'vee-validate';

export default {
  name: 'SupplierDetails',
  components: {
    ViewHeader,
    Spinner
  },
  props: {
    id: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      supplier: {
        id: this.id
      },
      contacts: [],
      newUserMail: '',
      isUpdatingContacts: false,
      isFetchingSupplier: false,
      isFetchingContacts: false
    };
  },
  computed: {
    showSpinner: function() {
      return this.isFetchingContacts || this.isFetchingSupplier;
    },
    sortedContacts: function() {
      var sortedContacts = Object.assign([], this.contacts);
      return sortedContacts.sort(this.sortByIsInvited);
    }
  },
  mounted() {
    this.fetchContacts();
    this.fetchSupplier();
  },
  methods: {
    fetchSupplier() {
      this.isFetchingSupplier = true;

      SupplierApi.getSupplier(this.supplier.id)
        .then(response => {
          console.log('fetch successful: ', response);
          this.supplier = response;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.isFetchingSupplier = false;
        });
    },
    fetchContacts() {
      this.isFetchingContacts = true;

      ContactApi.getContacts(this.supplier.id)
        .then(response => {
          console.log('fetch successful: ', response);

          response.result.forEach(element => {
            validate(element.emailAddress, 'required|email').then(result => {
              element.isEmailValid = result.valid;
              if (element.markedForDeletion) {
                element.deletionDate = new Date(element.markedForDeletion);
                element.deletionDate.setDate(element.deletionDate.getDate() + 30);
              }

              this.contacts.push(element);
            });
          });
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.isFetchingContacts = false;
        });
    },
    manageContactInvitation(contact, index) {
      if (this.isUpdatingContacts) return;

      this.isUpdatingContacts = true;

      var dialogText = this.$t(`messages.info.confirmEnableContact`);

      if (contact.isInvited) dialogText = this.$t(`messages.info.confirmDisableContact`);

      this.$bvModal
        .msgBoxConfirm(dialogText, {
          cancelTitle: this.$t(`buttons.no`),
          okTitle: this.$t(`buttons.yes`)
        })
        .then(confirmed => {
          if (confirmed) {
            let invitation = {
              contactId: contact.id,
              supplierId: contact.supplierId,
              invite: !contact.isInvited
            };

            ContactApi.putInvitation(invitation)
              .then(response => {
                console.log('successfully invited user: ', response);
                this.contacts[index] = response;
                location.reload();
                if (contact.isInvited) {
                  Toast.showSuccessMessage(this, this.$t(`messages.success.disableUser`));
                } else {
                  Toast.showSuccessMessage(this, this.$t(`messages.success.enableUser`));
                }
              })
              .catch(error => {
                console.log('error inviting user: ', error);
                Toast.showErrorMessage(this, this.$t(`messages.error.save`));
              });
          }
        })
        .catch(err => {
          console.log('error confirming', error);
        })
        .finally(() => {
          this.isUpdatingContacts = false;
        });
    },
    sortByIsInvited(a, b) {
      if (a.isInvited > b.isInvited) return -1;
      if (a.isInvited < b.isInvited) return 1;
      return 0;
    },
    navigateToList() {
      router.push({ name: 'suppliers' });
    }
  }
};
</script>
<style scoped>
.margin-left {
  margin-left: 15px;
}

.small-padding {
  padding: 5px;
}

.custom-tooltip {
  position: absolute;
  top: 0;
  right: 0;
  padding-top: 10px;
  padding-right: 10px;
}

.color-red {
  color: red;
}

.color-green {
  color: green;
}
</style>
