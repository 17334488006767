import axios from 'axios';
import AxiosDefaults from './axios-defaults';

export default {
  getDocuments() {
    return axios.get('/documents', AxiosDefaults.requestConfig).then(response => response.data);
  },
  getFileDownloadLink(documentId) {
    const urlParam = encodeURIComponent(documentId);
    console.log(urlParam);

    return axios.get('/documents/' + urlParam + '/download', AxiosDefaults.requestConfig).then(response => response.data);
  },
  getFileEmbeddedLink(documentId) {
    const urlParam = encodeURIComponent(documentId);
    console.log(urlParam);

    return axios.get('/documents/' + urlParam + '/embedded', AxiosDefaults.requestConfig).then(response => response.data);
  },
  postDocument(document) {
    return axios.post('/documents', document, AxiosDefaults.requestConfig).then(response => response.data);
  },
  deleteDocument(documentId) {
    return axios.delete('/documents/' + documentId, AxiosDefaults.requestConfig).then(response => response.data);
  }
};
