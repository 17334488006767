<template>
  <div class="container-fluid">
    <ViewHeader :title="$t(`headers.documentProperties`)"></ViewHeader>
    <div class="btn-group">
      <span class="float-left mb-3">
        <button type="button" class="btn btn-primary" @click="$bvModal.show('modal-property')">
          <i class="far fa-plus"></i>
          {{ $t(`buttons.add`) }}
        </button>
      </span>
    </div>
    <table class="table table-hover">
      <thead>
        <tr>
          <th scope="col" style="width: 5%"></th>
          <th scope="col" style="width: 30%">
            {{ $t(`form.documentProperty`) }}
          </th>
          <th scope="col" style="width: 30%">
            {{ $t(`form.translation`) }}
          </th>
          <th scope="col" style="width: 30%">
            {{ $t(`form.type`) }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in existingDocumentProperties" :key="index">
          <td>
            <div :title="$t(`buttons.delete`)" @click="deleteItem(item.id)">
              <i class="far fa-trash-alt"></i>
            </div>
          </td>
          <td>
            {{ item.name }}
          </td>
          <td>
            {{ item.translation }}
          </td>
          <td>
            {{ transformToTypeText(item.type) }}
          </td>
        </tr>
        <tr v-if="!showSpinner && existingDocumentProperties.length === 0" style="background:none">
          <td colspan="4">
            <div class="jumbotron">
              {{ $t(`messages.info.noDocumentPropertiesFound`) }}
            </div>
          </td>
        </tr>
        <tr v-if="showSpinner" style="background:none">
          <td colspan="4">
            <spinner :show-spinner="showSpinner" />
          </td>
        </tr>
      </tbody>
    </table>
    <DocumentPropertyDialog @success="handleSuccess" @error="showErrorMessage"></DocumentPropertyDialog>
  </div>
</template>

<script>
import ViewHeader from '../common/ViewHeader.vue';
import DocumentPropertyDialog from './DocumentPropertyDialog.vue';
import DocumentPropertyApi from '../../services/api/documentProperty';
import Spinner from '../common/Spinner';
import Toast from '../../common/toast';

export default {
  name: 'DocumentPropertyList',
  components: {
    ViewHeader,
    DocumentPropertyDialog
  },
  props: {
    showSpinner: {
      type: Boolean,
      default: () => false
    }
  },
  data: function() {
    return {
      existingDocumentProperties: [],
      propertyDescription: ''
    };
  },
  mounted() {
    this.getDocumentProperties();
  },
  methods: {
    getDocumentProperties() {
      DocumentPropertyApi.getDocumentProperties().then(response => {
        console.log('get successful ', response);
        if (response) {
          this.existingDocumentProperties = response.result;
        }
      });
    },
    transformToTypeText(value) {
      switch (value) {
        case 0:
          return this.$t(`form.propertyTypeAlphanumeric`);
        case 1:
          return this.$t(`form.propertyTypeNumeric`);
        case 2:
          return this.$t(`form.propertyTypeDate`);
      }
    },
    deleteItem(id) {
      this.$bvModal
        .msgBoxConfirm(this.$t(`messages.info.confirmDeleteDocumentType`), {
          cancelTitle: this.$t(`buttons.no`),
          okTitle: this.$t(`buttons.yes`)
        })
        .then(confirmed => {
          if (confirmed) {
            DocumentPropertyApi.deleteDocumentProperty(id)
              .then(response => {
                console.log('delete successful ', response);
                Toast.showSuccessMessage(this, this.$t(`messages.success.delete`));
                this.getDocumentProperties();
              })
              .catch(error => {
                console.log(error);
                console.log('response: ', error.response);
                if (error.response && error.response.status === 409) {
                  Toast.showErrorMessage(this, this.$t(`messages.error.stillInUse`));
                } else {
                  Toast.showErrorMessage(this, this.$t(`messages.error.delete`));
                }
              });
          }
        })
        .catch(err => {
          console.log('error confirming deletion', error);
        });
    },
    handleSuccess() {
      Toast.showSuccessMessage(this, this.$t(`messages.success.saveDocumentProperty`));
      this.getDocumentProperties();
    },
    showErrorMessage() {
      Toast.showErrorMessage(this, this.$t(`messages.error.save`));
    }
  }
};
</script>
<style scoped>
.fa-trash-alt:hover {
  color: var(--secondary);
  cursor: pointer;
}
</style>
