<template>
  <div class="container-fluid text-center">
    <img src="../../assets/logo_gross.png" :alt="$t(`links.home`)" class="navbarBrand" align="right" />
    <h3>{{ $t(`messages.info.welcomeHeader`) }}</h3>
    <div class="login-text">
      {{ $t(`messages.info.welcomeLogin`) }}
      <router-link to="/login">
        {{ $t(`links.login`) }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Welcome'
};
</script>
<style scoped>
.navbarBrand {
  margin-right: 5%;
}
.container-fluid {
  margin-top: 3%;
}
.login-text {
  font-size: 177%;
}
</style>
