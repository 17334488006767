import 'babel-polyfill';
import Vue from 'vue';
import axios from 'axios';
import BootstrapVue from 'bootstrap-vue';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faKey as fasKey } from '@fortawesome/free-solid-svg-icons';
import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons';
import { authProvider } from './auth/AuthProvider';

import {
  faTrashAlt,
  faFile,
  faFileExcel,
  faFilePowerpoint,
  faFileWord,
  faFileImage,
  faFilePdf,
  faEye,
  faAddressBook,
  faEdit,
  faFileAlt,
  faSave,
  faShareSquare,
  faCalendarAlt,
  faCaretSquareUp,
  faCaretSquareDown
} from '@fortawesome/free-regular-svg-icons';
import {
  faSpinner,
  faArrowFromTop,
  faArrowToTop,
  faExclamationTriangle,
  faFolders,
  faPlus,
  faChevronLeft,
  faChevronRight,
  faUsers,
  faSignOutAlt,
  faBarcode,
  faTruck,
  faBarcodeRead,
  faPrintSearch,
  faPrint,
  faCommentAltCheck,
  faFilePlus,
  faFileSearch,
  faCheck,
  faBallot,
  faChevronDoubleLeft,
  faChevronDoubleRight,
  faChevronDown,
  faChevronUp,
  faKey,
  faAsterisk,
  faRedo,
  faCog,
  faFileExport,
  faFileImport,
  faUserCheck,
  faUserTimes,
  faTimes,
  faCalculator,
  faText,
  faHome
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import App from './App.vue';
import router from './router';
import i18n from './i18n/i18n';
import { ValidationProvider, ValidationObserver, extend, configure } from 'vee-validate';
import { required, email, numeric } from 'vee-validate/dist/rules';
import Utils from './common/utils';
import VModal from 'vue-js-modal';
import cssVars from 'css-vars-ponyfill';
//import AuthModule from './auth/AuthModule';

Vue.use(VModal);

Vue.use(BootstrapVue);
Vue.component('font-awesome-icon', FontAwesomeIcon);

cssVars({
  include: 'style',
  onlyLegacy: false,
  watch: false,
  onComplete(cssText, styleNode, cssVariables) {}
});

const maximum = (value, { max } = {}) => {
  return value.length < max;
};

// Validation
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
extend('required', required);
extend('email', email);
extend('numeric', numeric);
extend('max', {
  validate: maximum,
  params: ['max'],
  message: (_, values) => {
    return i18n.t(`validation.${values._rule_}`, values);
  }
});
extend('hasFileExtension', {
  validate: value => Utils.hasFileExtension(value)
});
configure({
  // this will be used to generate messages.
  defaultMessage: (_, values) => i18n.t(`validation.${values._rule_}`, values)
});
configure({
  classes: {
    valid: '',
    invalid: 'is-invalid'
  }
});

library.add(
  faSpinner,
  faTrashAlt,
  faArrowFromTop,
  faArrowToTop,
  faFile,
  faFileExcel,
  faFilePowerpoint,
  faFileWord,
  faFileImage,
  faFilePdf,
  faExclamationTriangle,
  faFolders,
  faAddressBook,
  faEye,
  faEdit,
  faPlus,
  faSave,
  faChevronLeft,
  faUsers,
  faSignOutAlt,
  faBarcode,
  faTruck,
  faBarcodeRead,
  faPrintSearch,
  faPrint,
  faFilePlus,
  faFileSearch,
  faCommentAltCheck,
  faCheck,
  faBallot,
  faChevronDoubleLeft,
  faChevronDoubleRight,
  faChevronDown,
  faChevronUp,
  faKey,
  fasKey,
  faAsterisk,
  faSpinnerThird,
  faChevronRight,
  faRedo,
  faCog,
  faFileExport,
  faFileImport,
  faFileAlt,
  faCog,
  faUserCheck,
  faUserTimes,
  faTimes,
  faShareSquare,
  faCalendarAlt,
  faCalculator,
  faText,
  faCaretSquareUp,
  faCaretSquareDown,
  faHome
);
dom.watch(); // sorgt dafür, dass man ganz normal <i> Elemente nutzen kann, statt der Vue-Komponenten

Vue.config.productionTip = false;
axios.defaults.baseURL = process.env.VUE_APP_ROOT_API;

Vue.prototype.$eventHub = new Vue(); // Global event bus

Vue.filter('formatFileSize', function(value) {
  if (!value) return '0 B';

  let number = Number(value);
  let extension = ' B';

  if (number > 1024 * 1024 * 1024) {
    number = Math.round((number / (1024 * 1024 * 1024)) * 10) / 10;
    extension = ' GB';
  } else if (number > 1024 * 1024) {
    number = Math.round((number / (1024 * 1024)) * 10) / 10;
    extension = ' MB';
  } else if (number > 1024) {
    number = Math.round((number / 1024) * 10) / 10;
    extension = ' KB';
  }
  return number.toLocaleString() + extension;
});

Vue.filter('localeDateTime', function(date) {
  if (date) {
    return new Date(date).toLocaleString();
  }
  return null;
});

Vue.filter('localeDate', function(date) {
  if (date) {
    return new Date(date).toLocaleDateString();
  }
  return null;
});
new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app');
