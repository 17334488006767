<template>
  <button type="button" :disabled="!anyFileSelected" class="btn btn-primary mr-1" @click="downloadFiles">
    <i class="far fa-arrow-from-top"></i>
    {{ $t(`buttons.download`) }}
  </button>
</template>

<script>
import DocumentApi from '../../services/api/document';

export default {
  name: 'DocumentDownloadButton',
  props: {
    files: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    anyFileSelected: function() {
      return this.files && this.files.length > 0;
    }
  },
  methods: {
    downloadFiles() {
      if (!this.files || this.files.length === 0) {
        return;
      }

      if (this.files.length <= 5) {
        this.downloadFilesSeperately(this.files);
      } else {
        this.$bvModal
          .msgBoxConfirm(
            this.$t(`messages.info.confirmDownload`, {
              count: this.files.length
            })
          )
          .then(continueDownload => {
            if (continueDownload) {
              this.downloadFilesSeperately(this.files);
            }
          })
          .catch(err => {
            console.log('error confirming download', error);
            this.showErrorMessage(this.$t(`messages.error.download`));
          });
      }
    },
    downloadFilesSeperately(files) {
      if (!files || files.length === 0) {
        return;
      }
      const fileId = files.pop().id;

      DocumentApi.getFileDownloadLink(fileId)
        .then(response => {
          console.log('download response: ', response);
          this.doDownloadFile(fileId, response.url);

          // Etwas Zeit zwischen den Downloads lassen, damit sie nicht vom Browser blockiert werden
          setTimeout(() => {
            this.downloadFilesSeperately(files);
          }, 500);
        })
        .catch(error => {
          console.log('error getting download link: ', error);
          this.showErrorMessage(this.$t(`messages.error.download`));
        });
    },
    doDownloadFile(fileName, url) {
      let link = document.createElement('a');
      link.download = fileName;
      link.href = url;
      document.body.appendChild(link);
      console.log('download file: ', fileName);
      link.click();
      document.body.removeChild(link);
    }
  }
};
</script>
