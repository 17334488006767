<template>
  <table class="table table-hover">
    <thead>
      <tr>
        <th scope="col" style="width: 5%"></th>
        <th scope="col" style="width: 30%">
          {{ $t(`form.supplierName`) }}
        </th>
        <th scope="col" class="d-none d-md-table-cell" style="width: 30%">
          {{ $t(`form.supplierNumber`) }}
        </th>
        <th scope="col" class="d-none d-md-table-cell" style="width: 30%">
          {{ $t(`form.tenantNumber`) }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="supplier in suppliers" :key="supplier.id">
        <td>
          <div :title="$t(`buttons.showDetails`)" @click="edit(supplier.id)">
            <i class="far fa-file-search clickable"></i>
          </div>
        </td>
        <td>
          {{ supplier.name }}
        </td>
        <td class="d-none d-md-table-cell">
          {{ supplier.supplierNumber }}
        </td>
        <td class="d-none d-md-table-cell">
          {{ supplier.tenantNumber }}
        </td>
      </tr>
      <tr v-if="!showSpinner && suppliers.length === 0" style="background:none">
        <td colspan="4">
          <div class="jumbotron">
            {{ $t(`messages.info.noSuppliersFound`) }}
          </div>
        </td>
      </tr>
      <tr v-if="showSpinner" style="background:none">
        <td colspan="4">
          <spinner :show-spinner="showSpinner" />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import router from '../../router';
import Spinner from '../common/Spinner';

export default {
  name: 'SupplierTable',
  components: {
    Spinner
  },
  props: {
    suppliers: {
      type: Array,
      default: () => []
    },
    showSpinner: {
      type: Boolean,
      default: () => false
    }
  },
  methods: {
    edit: function(id) {
      router.push({ name: 'supplierdetails', params: { id: id } });
    }
  }
};
</script>

<style scoped>
.clickable:hover {
  color: var(--secondary);
  cursor: pointer;
}
</style>
