<template>
  <div class="container-fluid">
    <h5 class="mb-3">
      {{ $t(`headers.mandatoryAndOrder`) }}
    </h5>
    <div class="row">
      <div class="col-1 text-center align-self-center">
        <div @click="moveUp">
          <i :title="$t(`buttons.showDetails`)" class="far fa-chevron-up fa-2x clickable"></i>
        </div>
        <div class="mt-3" @click="moveDown">
          <i :title="$t(`buttons.showDetails`)" class="far fa-chevron-down fa-2x clickable"></i>
        </div>
      </div>
      <div class="col-11">
        <div class="list-group">
          <a
            v-for="(item, index) in selectedItems"
            :key="index"
            href="#"
            class="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
            :class="index === selectedIndex ? 'active' : ''"
            @click="setSelectedItem(index)"
          >
            {{ item[displayValue] }}
            <div class="clickable" @click.stop="setMandatory(item)">
              <!-- <i :class="item.isMandatory ? 'fas fa-key' : 'far fa-key'"></i> -->
              <font-awesome-icon v-if="item.isMandatory" :icon="['fas', 'key']"></font-awesome-icon>
              <font-awesome-icon v-else :icon="['far', 'key']"></font-awesome-icon>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Utils from '../../common/utils';

export default {
  name: 'MandatoryProperties',
  props: {
    selectedItems: {
      type: Array,
      default: () => []
    },
    displayValue: {
      type: String,
      default: () => 'name'
    }
  },
  data() {
    return {
      selectedIndex: undefined
    };
  },
  methods: {
    moveUp() {
      if (this.selectedIndex === undefined || this.selectedIndex === 0) {
        return;
      }

      let newIndex = this.selectedIndex - 1;
      Utils.move(this.selectedItems, this.selectedIndex, newIndex);
      this.selectedIndex--;
      this.setIndexValues();
    },
    moveDown() {
      if (this.selectedIndex === undefined || this.selectedIndex === this.selectedItems.length - 1) {
        return;
      }

      let newIndex = this.selectedIndex + 1;
      Utils.move(this.selectedItems, this.selectedIndex, newIndex);
      this.selectedIndex++;
      this.setIndexValues();
    },
    setIndexValues() {
      this.selectedItems.forEach((item, index) => {
        item.index = index;
      });
      return;
    },
    setMandatory(item) {
      item.isMandatory = !item.isMandatory;
    },
    setSelectedItem(index) {
      if (this.selectedIndex === index) {
        this.selectedIndex = undefined;
      } else {
        this.selectedIndex = index;
      }
    }
  }
};
</script>
<style scoped>
.clickable:hover {
  color: var(--secondary);
  cursor: pointer;
}
</style>
