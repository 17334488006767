<template>
  <div class="container-fluid">
    <ViewHeader :title="$t(`headers.globalConfiguration`)"></ViewHeader>
    <div class="row">
      <div class="col-12">
        <div class="btn-group mb-3">
          <button type="button" class="btn btn-primary mr-2" @click="navigateBack">
            <i class="far fa-chevron-left"></i>
            {{ $t(`buttons.back`) }}
          </button>
        </div>
      </div>
    </div>
    <div class="card-deck">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">
            {{ $t(`form.supplierEvaluation`) }}
          </h5>
          <toggle-button
            id="showSupplierEvaluation"
            v-model="configuration.showSupplierEvaluation"
            :sync="true"
            class="mr-2"
            @change="save"
          />
          <label for="showSupplierEvaluation">{{ $t(`form.showSupplierEvaluation`) }}</label>
        </div>
      </div>
      <div v-if="showImportExport" class="card">
        <div class="card-body">
          <h5 class="card-title">
            {{ $t(`form.exportConfiguration`) }}
          </h5>
          <p class="card-text clickable" @click="openEditDialog()">
            <i class="far fa-file-export"></i>
            {{ $t(`buttons.exportSelectedDocumentTypes`) }}
          </p>
        </div>
      </div>
      <div v-if="showImportExport" class="card">
        <div class="card-body">
          <h5 class="card-title">
            {{ $t(`form.importConfiguration`) }}
          </h5>
          <p class="card-text clickable">
            <i class="far fa-file-import"></i>
            {{ $t(`form.importConfiguration`) }}
          </p>
        </div>
      </div>
    </div>
    <SelectExportConfigurationDialog v-if="showImportExport"></SelectExportConfigurationDialog>
  </div>
</template>

<script>
import ViewHeader from '../common/ViewHeader';
import { ToggleButton } from 'vue-js-toggle-button';
import ConfigApi from '../../services/api/config';
import SelectExportConfigurationDialog from './SelectExportConfigurationDialog.vue';

export default {
  name: 'GlobalConfiguration',
  components: {
    ViewHeader,
    ToggleButton,
    SelectExportConfigurationDialog
  },
  data() {
    return {
      configuration: {},
      // Einblenden, sobald umgesetzt
      showImportExport: false
    };
  },
  mounted() {
    this.getConfig();
  },
  methods: {
    openEditDialog() {
      this.showDialog = true;
      this.$nextTick(() => {
        this.$bvModal.show('modal-selectConfig');
      });
    },
    navigateBack() {
      window.history.back();
    },
    getConfig() {
      ConfigApi.getGlobalConfig()
        .then(response => {
          console.log('get successful ', response);
          if (response) {
            this.configuration = response;
          }
        })
        .catch(error => {
          console.log('get failed: ', error);
        });
    },
    save() {
      ConfigApi.putGlobalConfig(this.configuration)
        .then(response => {
          console.log('put successful ', response);
        })
        .catch(error => {
          console.log('put failed: ', error);
        });
    }
  }
};
</script>
<style scoped>
.clickable:hover {
  cursor: pointer;
}
.clickable:hover {
  color: var(--secondary);
  cursor: pointer;
}
</style>
