<template>
  <div class="full-height">
    <div class="row full-height">
      <div class="col-3 verticalLine full-height">
        <ul class="list-group">
          <li
            v-for="(item, index) in documents"
            ref="documentList"
            :key="index"
            class="list-group-item"
            :title="item.fileName"
            :class="item.isActiveDocument ? 'active' : ''"
          >
            <div class="row">
              <span class="col-10 text-truncate align-self-center">
                {{ item.fileName }}
              </span>
              <div class="float-right col-2" @click="deleteDocument(index, item.isActiveDocument)">
                <font-awesome-icon
                  :icon="['far', 'trash-alt']"
                  :title="$t(`buttons.delete`)"
                  class="clickable"
                  :class="item.isActiveDocument ? 'icon-white' : ''"
                ></font-awesome-icon>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="col-9 full-height">
        <DocumentDetailsEditor
          class="full-height"
          :document="activeDocument"
          :existing-document-types="existingDocumentTypes"
          :doc-type-display="currentLanguage === 'de' ? 'name' : 'translation'"
          :display-value="currentLanguage === 'de' ? 'name' : 'translation'"
        ></DocumentDetailsEditor>
      </div>
    </div>
  </div>
</template>
<script>
import DocumentDetailsEditor from './DocumentDetailsEditor.vue';

export default {
  name: 'DocumentTab',
  components: {
    DocumentDetailsEditor
  },
  props: {
    documents: {
      type: Array,
      default: () => {}
    },
    existingDocumentTypes: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    activeDocument: function() {
      return this.documents.find(doc => {
        return doc.isActiveDocument === true;
      });
    },
    currentLanguage: function() {
      return navigator.language.split('-')[0];
    }
  },
  methods: {
    deleteDocument(index, isActive) {
      console.log('index to delete: ', index);

      if (isActive && this.documents.length > 1) {
        let nextIndex;

        if (index === this.documents.length - 1) {
          nextIndex = this.documents.length - 2;
        } else if (index === 0) {
          nextIndex = 1;
        }

        this.documents.forEach(doc => {
          doc.isActiveDocument = false;
        });

        this.documents[nextIndex].isActiveDocument = true;
      }

      this.documents.splice(index, 1);
    },
    scrollToActiveItem(index) {
      var activeListItem = this.$refs.documentList[index];
      activeListItem.scrollIntoView();
    }
  }
};
</script>
<style scoped>
.clickable:hover {
  cursor: pointer;
  color: var(--secondary);
}

.icon-white {
  color: white;
}

.verticalLine {
  border-right: 1px solid #ccc;
}

.full-height {
  height: 100%;
  white-space: nowrap;
  overflow-y: auto;
  overflow-x: hidden;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 6px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}
</style>
