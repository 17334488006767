<template>
  <div>
    <div class="input-group">
      <b-form-datepicker
        v-if="inputType === 'date'"
        :id="id"
        :value="value"
        type="date"
        class="form-control"
        :class="classes"
        label-help=""
        reset-button
        :readonly="readonly"
        :label-reset-button="$t(`buttons.delete`)"
        reset-button-variant="secondary"
        hide-header
        @input="updateValue($event)"
        @blur="updateValue($event)"
      ></b-form-datepicker>
      <input
        v-else
        :id="id"
        :value="value"
        :type="inputType"
        class="form-control"
        :class="classes"
        :readonly="readonly"
        @input="updateValue($event.target.value)"
        @blur="updateValue($event.target.value)"
      />
      <font-awesome-icon
        :title="isMandatory ? $t(`messages.info.requiredField`) : ''"
        :class="isMandatory ? '' : 'hide-me'"
        :icon="['far', 'asterisk']"
        size="xs"
        class="required-prop ml-1"
      ></font-awesome-icon>
    </div>
    <small v-if="errors && errors.length > 0" class="red-color">
      {{ errors[0] }}
    </small>
  </div>
</template>
<script>
export default {
  name: 'CustomInput',
  props: {
    value: {
      type: String,
      default: () => {}
    },
    inputType: {
      type: String,
      default: 'text'
    },
    isMandatory: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: undefined
    },
    classes: {
      type: Object,
      default: () => {}
    },
    errors: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    updateValue: function(value) {
      this.$emit('input', value);
    }
  }
};
</script>
<style scoped>
.form-control {
  border-radius: 0.25rem !important;
}

.required-prop {
  color: var(--secondary);
}

.red-color {
  color: red;
}

.hide-me {
  opacity: 0;
}
</style>
