import { authProvider } from '../../auth/AuthProvider';
import MsalAB2C from '../../auth/MsalAB2C';
console.log('axios begin');
const AppAuthProvider = authProvider;
const tokenRequest = {
  scopes: ['https://vsmsupplierportal.onmicrosoft.com/66948131-96e0-478c-8658-686c12693b0c/access_api']
};
const silentgraphRequest = {
  account: AppAuthProvider.getAccount(),
  scopes: ['https://vsmsupplierportal.onmicrosoft.com/66948131-96e0-478c-8658-686c12693b0c/access_api']
};
const AxiosDefaults = {};
//Possible cause for problems with invalid access tokens, since the token is only retrieved once
//for all future operations, eventually causing errors once the token lifetime runs out.
AppAuthProvider.getTokenRedirect(silentgraphRequest, tokenRequest).then(
  response => {
    console.log('User logged in', response.accessToken);
    const defaultConfig = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${response.accessToken}`
      }
    };
    AxiosDefaults.requestConfig = defaultConfig;
  },
  reject => {
    console.log('User not logged in !', reject);
  }
);
console.log('axios end');
export default AxiosDefaults;
