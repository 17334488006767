const toaster = {
  showSuccessMessage: function(component, message) {
    component.$bvToast.toast(message, {
      title: component.$t(`headers.success`),
      toaster: 'b-toaster-top-center',
      autoHideDelay: 1000 * 3,
      variant: 'success'
    });
  },
  showErrorMessage: function(component, message) {
    component.$bvToast.toast(message, {
      title: component.$t(`headers.error`),
      toaster: 'b-toaster-top-center',
      autoHideDelay: 1000 * 3,
      variant: 'danger'
    });
  }
};

export default toaster;
