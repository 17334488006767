<template>
  <modal name="modal-show-document" :adaptive="true" width="80%" height="90%">
    <object :data="documentLink" type="application/pdf" style="width:100%;height:100%">
      <embed :src="documentLink" type="application/pdf" />
    </object>
  </modal>
</template>

<script>
export default {
  name: 'DocumentViewer',
  props: {
    documentLink: {
      type: String,
      default: ''
    }
  }
};
</script>
