<template>
  <div class="customNavbar no-print">
    <router-link to="/">
      <img src="../../assets/VSM_Logo_white.png" :alt="$t(`links.home`)" class="navbarBrand" />
    </router-link>
    <ul class="list-group">
      <li v-if="loggedIn" id="navbarItemHome">
        <router-link to="/">
          <i class="far fa-home navbarItem"></i>
        </router-link>
        <b-tooltip target="navbarItemHome" placement="right" triggers="hover">
          {{ $t(`links.home`) }}
        </b-tooltip>
      </li>
      <li v-if="showDocuments" id="navbarItemDocuments">
        <router-link to="/documents">
          <i class="far fa-folders navbarItem"></i>
        </router-link>
        <b-tooltip target="navbarItemDocuments" placement="right" triggers="hover">
          {{ $t(`links.documents`) }}
        </b-tooltip>
      </li>
      <li v-if="showBarcode" id="navbarItemOrders">
        <router-link to="/orders">
          <i class="far fa-barcode navbarItem"></i>
        </router-link>
        <b-tooltip target="navbarItemOrders" placement="right" triggers="hover">
          {{ $t(`buttons.barcodes`) }}
        </b-tooltip>
      </li>
      <li v-if="showSuppliers" id="navbarItemSupplier">
        <router-link to="/suppliers">
          <i class="far fa-address-book navbarItem"></i>
        </router-link>
        <b-tooltip target="navbarItemSupplier" placement="right" triggers="hover">
          {{ $t(`links.suppliers`) }}
        </b-tooltip>
      </li>
      <li v-if="showUsers" id="navbarItemUsers">
        <router-link to="/users">
          <i class="far fa-users navbarItem"></i>
        </router-link>
        <b-tooltip target="navbarItemUsers" placement="right" triggers="hover">
          {{ $t(`links.users`) }}
        </b-tooltip>
      </li>
      <li v-if="showSupplierEvaluation" id="supplierEvaluation">
        <router-link to="/evaluation">
          <i class="far fa-comment-alt-check navbarItem"></i>
        </router-link>
        <b-tooltip target="supplierEvaluation" placement="right" triggers="hover">
          {{ $t(`links.supplierEvaluation`) }}
        </b-tooltip>
      </li>
      <li v-if="showDocumentProperties" id="documentProperties">
        <router-link to="/documentProperties">
          <i class="far fa-ballot navbarItem"></i>
        </router-link>
        <b-tooltip target="documentProperties" placement="right" triggers="hover">
          {{ $t(`links.documentProperties`) }}
        </b-tooltip>
      </li>
      <li v-if="showDocumentTypes" id="documentTypes" class="hoverable">
        <router-link to="/documentTypes">
          <i class="far fa-file-plus navbarItem"></i>
        </router-link>
        <b-tooltip target="documentTypes" placement="right" triggers="hover">
          {{ $t(`links.defineDocuments`) }}
        </b-tooltip>
      </li>
      <li v-if="showGlobalConfiguration" id="globalConfiguration" class="hoverable">
        <router-link to="/globalConfiguration">
          <i class="far fa-cog navbarItem"></i>
        </router-link>
        <b-tooltip target="globalConfiguration" placement="right" triggers="hover">
          {{ $t(`links.globalConfiguration`) }}
        </b-tooltip>
      </li>
      <li v-if="loggedIn" id="navbarItemLogout" class="navbarFooter">
        <router-link to="/logout">
          <i class="far fa-sign-out-alt navbarItem"></i>
        </router-link>
        <b-tooltip target="navbarItemLogout" placement="right" triggers="hover">
          {{ $t(`links.logout`) }}
        </b-tooltip>
      </li>
    </ul>
    <div></div>
  </div>
</template>

<script>
import UserInfoStore from '../../auth/user-info-store';
import Constants from '../../common/constants';
import ConfigApi from '../../services/api/config';
import MsalAB2C from '../../auth/MsalAB2C';

export default {
  data() {
    return {
      globalConfig: {},
      showDocuments: false,
      showSuppliers: false,
      showUsers: false,
      loggedIn: false,
      showBarcode: false,
      showSupplierEvaluation: false,
      showDocumentTypes: false,
      showDocumentProperties: false,
      showGlobalConfiguration: false
    };
  },
  created() {
    this.$eventHub.$on('logged-in', this.getGlobalConfig);
  },
  beforeDestroy() {
    this.$eventHub.$off('logged-in');
  },
  methods: {
    getGlobalConfig() {
      ConfigApi.getGlobalConfig()
        .then(response => {
          console.log('get config successful ', response);
          if (response) {
            this.globalConfig = response;
          }
        })
        .catch(error => {
          console.log('get config failed: ', error);
        })
        .finally(() => {
          this.updateVisibility();
        });
    },
    updateVisibility: function() {
      this.showDocuments = UserInfoStore.state.isInGroup(Constants.supplierGroup);
      this.showBarcode = UserInfoStore.state.isInGroup(Constants.supplierGroup);
      this.showSuppliers = UserInfoStore.state.isInGroup(Constants.clerkGroup);
      this.showSupplierEvaluation = UserInfoStore.state.isInGroup(Constants.supplierGroup) && this.globalConfig.showSupplierEvaluation;
      this.showUsers = UserInfoStore.state.isInGroup(Constants.adminGroup);
      this.showDocumentTypes = UserInfoStore.state.isInGroup(Constants.adminGroup);
      this.showDocumentProperties = UserInfoStore.state.isInGroup(Constants.adminGroup);
      this.showGlobalConfiguration = UserInfoStore.state.isInGroup(Constants.adminGroup);
      this.loggedIn = MsalAB2C.AppAuthProvider.getAccount() ? true : false;
    }
  }
};
</script>

<style scoped>
.customNavbar {
  background-color: var(--primary);
  width: 50px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
}

.navbarItem {
  height: 30px;
  width: 30px;
  margin: 10px;
  color: white;
}

.navbarItem:hover {
  color: var(--secondary);
}

.navbarBrand {
  margin-bottom: 10px;
  margin-left: 4px;
  margin-top: 5px;
  height: 42px;
  width: 42px;
}

.navbarFooter {
  bottom: 0;
  position: fixed;
}
</style>

<style>
:root {
  --tooltip-color: var(--secondary);
}

.tooltip-inner {
  background-color: var(--tooltip-color) !important;
}
.tooltip.bs-tooltip-right .arrow:before {
  border-right-color: var(--tooltip-color) !important;
}
.tooltip.bs-tooltip-left .arrow:before {
  border-left-color: var(--tooltip-color) !important;
}
.tooltip.bs-tooltip-bottom .arrow:before {
  border-bottom-color: var(--tooltip-color) !important;
}
.tooltip.bs-tooltip-top .arrow:before {
  border-top-color: var(--tooltip-color) !important;
}
.tooltip.show {
  opacity: 1;
}
</style>
