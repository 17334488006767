import axios from 'axios';
import AxiosDefaults from './axios-defaults';

export default {
  getGlobalConfig() {
    return axios.get('/globalConfiguration', AxiosDefaults.requestConfig).then(response => response.data);
  },
  putGlobalConfig(config) {
    return axios.put('/globalConfiguration', config, AxiosDefaults.requestConfig).then(response => response.data);
  }
};
