var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('b-modal',{attrs:{"id":"modal-property","title":"Modal title","role":"dialog","size":"xl","no-close-on-backdrop":""},on:{"hide":_vm.clearProperty},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
return [_c('button',{staticClass:"btn btn-secondary",attrs:{"type":"button"},on:{"click":cancel}},[_vm._v(" "+_vm._s(_vm.$t("buttons.cancel"))+" ")]),_c('button',{staticClass:"btn btn-primary m-1",attrs:{"type":"button"},on:{"click":function($event){return passes(_vm.submitProperty)}}},[_vm._v(" "+_vm._s(_vm.$t("buttons.save"))+" ")])]}}],null,true)},[_c('template',{slot:"modal-header"},[_c('h5',[_vm._v(_vm._s(_vm.$t("form.createDocumentPropertyHeader")))])]),_c('template',{slot:"default"},[_c('div',{staticClass:"form-group "},[_c('ValidationProvider',{attrs:{"name":_vm.$t("form.name"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('label',{attrs:{"for":"name"}},[_vm._v(_vm._s(_vm.$t("form.documentPropertyTitel")))]),_c('CustomInput',{attrs:{"id":"name","classes":classes,"is-mandatory":true,"errors":errors},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)})],1),_c('div',{staticClass:"form-group "},[_c('ValidationProvider',{attrs:{"name":_vm.$t("form.translation"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('label',{attrs:{"for":"translation"}},[_vm._v(_vm._s(_vm.$t("form.translation")))]),_c('CustomInput',{attrs:{"id":"translation","classes":classes,"is-mandatory":true,"errors":errors},model:{value:(_vm.translation),callback:function ($$v) {_vm.translation=$$v},expression:"translation"}})]}}],null,true)})],1),_c('div',{staticClass:"mt-4"},[_c('div',{staticClass:"custom-control custom-radio custom-control-inline"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.type),expression:"type"}],staticClass:"custom-control-input",attrs:{"id":"propertyTypeAlphanumeric","type":"radio","name":"propertyType","value":"0"},domProps:{"checked":_vm._q(_vm.type,"0")},on:{"change":function($event){_vm.type="0"}}}),_c('label',{staticClass:"custom-control-label",attrs:{"for":"propertyTypeAlphanumeric"}},[_vm._v(_vm._s(_vm.$t("form.propertyTypeAlphanumeric")))])]),_c('div',{staticClass:"custom-control custom-radio custom-control-inline"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.type),expression:"type"}],staticClass:"custom-control-input",attrs:{"id":"propertyTypeNumeric","type":"radio","name":"propertyType","value":"1"},domProps:{"checked":_vm._q(_vm.type,"1")},on:{"change":function($event){_vm.type="1"}}}),_c('label',{staticClass:"custom-control-label",attrs:{"for":"propertyTypeNumeric"}},[_vm._v(_vm._s(_vm.$t("form.propertyTypeNumeric")))])]),_c('div',{staticClass:"custom-control custom-radio custom-control-inline"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.type),expression:"type"}],staticClass:"custom-control-input",attrs:{"id":"propertyTypeDate","type":"radio","name":"propertyType","value":"2"},domProps:{"checked":_vm._q(_vm.type,"2")},on:{"change":function($event){_vm.type="2"}}}),_c('label',{staticClass:"custom-control-label",attrs:{"for":"propertyTypeDate"}},[_vm._v(_vm._s(_vm.$t("form.propertyTypeDate")))])])])])],2)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }