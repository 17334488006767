import axios from 'axios';
import AxiosDefaults from './axios-defaults';

export default {
  getUsers(supplierId) {
    if (supplierId) {
      return axios.get('/users' + '?supplierId=' + supplierId, AxiosDefaults.requestConfig).then(response => response.data);
    } else {
      return axios.get('/users', AxiosDefaults.requestConfig).then(response => response.data);
    }
  },
  getGroups() {
    return axios.get('/groups', AxiosDefaults.requestConfig).then(response => response.data);
  },
  getUser(userId, supplierId) {
    if (supplierId) {
      return axios.get('/users/' + userId + '?supplierId=' + supplierId, AxiosDefaults.requestConfig).then(response => response.data);
    }

    return axios.get('/users/' + userId, AxiosDefaults.requestConfig).then(response => response.data);
  },
  getSupplierForCurrentUser() {
    return axios.get('/users/current/supplier', AxiosDefaults.requestConfig).then(response => response.data);
  },
  getUserDocuments() {
    return axios.get('/users/documents/latest', AxiosDefaults.requestConfig).then(response => response.data);
  },
  postUser(user, supplierId) {
    if (supplierId) {
      return axios.post('/users' + '?supplierId=' + supplierId, user, AxiosDefaults.requestConfig).then(response => response.data);
    } else {
      return axios.post('/users', user, AxiosDefaults.requestConfig).then(response => response.data);
    }
  },
  putUser(user, userId) {
    return axios.put('/users/' + userId, user, AxiosDefaults.requestConfig).then(response => response.data);
  },
  deleteUser(userId, supplierId) {
    if (supplierId) {
      return axios.delete('/users/' + userId + '?supplierId=' + supplierId, AxiosDefaults.requestConfig).then(response => response);
    } else {
      return axios.delete('/users/' + userId, AxiosDefaults.requestConfig).then(response => response);
    }
  }
};
