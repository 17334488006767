<template>
  <div class="full-height">
    <div class="h4">
      <label>{{ $t(`headers.basicInformation`) }}</label>
    </div>
    <div v-for="(item, index) in questions" :key="index" class="row">
      <div class="form-group col-12">
        <label>{{ item.question }}</label>
        <input v-model="basicInformationData[item.id]" :disabled="isReleased" class="form-control" type="text" @change="updateValue" />
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-6">
        <label class="question">{{ $t(`questions.basicInformation.addressStreet`) }}</label>
        <input v-model="basicInformationData.addressStreet" type="text" class="form-control" :disabled="isReleased" @change="updateValue" />
      </div>
      <div class="col-3">
        <label class="question">{{ $t(`questions.basicInformation.addressCity`) }}</label>
        <input v-model="basicInformationData.addressCity" type="text" class="form-control" :disabled="isReleased" @change="updateValue" />
      </div>
      <div class="col-3">
        <label class="question">{{ $t(`questions.basicInformation.addressZip`) }}</label>
        <input v-model="basicInformationData.addressZip" type="text" class="form-control" :disabled="isReleased" @change="updateValue" />
      </div>
    </div>
    <div class="row">
      <div class="form-group col-12">
        <label class="question">{{ $t(`questions.basicInformation.areaOfActivity`) }}</label>
        <select v-model="basicInformationData.areaOfActivity" class="form-control" :disabled="isReleased" @change="updateValue">
          <option :value="null" disabled="disabled" hidden="hidden">
            {{ $t(`questions.basicInformation.pleaseChoose`) }}
          </option>
          <option value="option1">
            {{ $t(`questions.basicInformation.areaOfActivityOption1`) }}
          </option>
          <option value="option2">
            {{ $t(`questions.basicInformation.areaOfActivityOption2`) }}
          </option>
          <option value="option3">
            {{ $t(`questions.basicInformation.areaOfActivityOption3`) }}
          </option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-12">
        <label class="question">{{ $t(`questions.basicInformation.partOfCompanyGroup`) }}</label>
        <select v-model="basicInformationData.partOfCompanyGroup" class="form-control" :disabled="isReleased" @change="updateValue">
          <option :value="null" disabled="disabled" hidden="hidden">
            {{ $t(`questions.basicInformation.pleaseChoose`) }}
          </option>
          <option value="option1">
            {{ $t(`questions.basicInformation.partOfCompanyGroupOption1`) }}
          </option>
          <option value="option2">
            {{ $t(`questions.basicInformation.partOfCompanyGroupOption2`) }}
          </option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-12">
        <label class="question">{{ $t(`questions.basicInformation.packagingGuaranteedBy`) }}</label>
        <select v-model="basicInformationData.packagingGuaranteedBy" class="form-control" :disabled="isReleased" @change="updateValue">
          <option :value="null" disabled="disabled" hidden="hidden">
            {{ $t(`questions.basicInformation.pleaseChoose`) }}
          </option>
          <option value="option1">
            {{ $t(`questions.basicInformation.packagingGuaranteedByOption1`) }}
          </option>
          <option value="option2">
            {{ $t(`questions.basicInformation.packagingGuaranteedByOption2`) }}
          </option>
          <option value="option3">
            {{ $t(`questions.basicInformation.packagingGuaranteedByOption3`) }}
          </option>
          <option value="option4">
            {{ $t(`questions.basicInformation.packagingGuaranteedByOption4`) }}
          </option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-12">
        <label class="question">{{ $t(`questions.basicInformation.recyclingSystemInformation`) }}</label>
        <textarea
          v-model="basicInformationData.recyclingSystemInformation"
          class="form-control"
          rows="10"
          :disabled="isReleased"
          @change="updateValue"
        ></textarea>
      </div>
    </div>
  </div>
</template>

<script>
import UserInfoStore from '../../auth/user-info-store';
import constants from '../../common/constants';
import UserApi from '../../services/api/user';

export default {
  name: 'SupplierEvaluationBasicInformation',
  props: {
    basicInformation: {
      type: Object,
      default: () => {}
    },
    isReleased: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      basicInformationData: {},
      questions: [
        { question: this.$t(`questions.basicInformation.corporateName`), id: 'corporateName' },
        { question: this.$t(`questions.basicInformation.legalFormOfCorporate`), id: 'legalFormOfCorporate' },
        { question: this.$t(`questions.basicInformation.dunsNumber`), id: 'dunsNumber' },
        { question: this.$t(`questions.basicInformation.industrialSector`), id: 'industrialSector' },
        { question: this.$t(`questions.basicInformation.naceCode`), id: 'naceCode' },
        { question: this.$t(`questions.basicInformation.employeesTotal`), id: 'employeesTotal' },
        { question: this.$t(`questions.basicInformation.employeesRDQA`), id: 'employeesRdqa' },
        { question: this.$t(`questions.basicInformation.employeesProduction`), id: 'employeesProduction' },
        { question: this.$t(`questions.basicInformation.employeesAdministration`), id: 'employeesAdministration' },
        { question: this.$t(`questions.basicInformation.turnoverLastYear`), id: 'turnoverLastYear' },
        { question: this.$t(`questions.basicInformation.ourCustomerNumber`), id: 'ourCustomerNumber' },
        { question: this.$t(`questions.basicInformation.yourSupplierNumber`), id: 'yourSupplierNumber' }
      ]
    };
  },
  mounted() {
    this.basicInformationData = Object.assign({}, this.basicInformation);
  },
  methods: {
    updateValue() {
      this.$emit('basicInformationUpdate', this.basicInformationData);
    }
  }
};
</script>
<style scoped>
.full-height {
  height: 100%;
  white-space: nowrap;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
