const state = {
  azureInfo: {},
  groups: {},
  loggedIn: false,
  loadingState: true,
  errorLoadingState: false,
  isInGroup(group) {
    if (!this.azureInfo.groups) return false;

    return this.azureInfo.groups.indexOf(group) >= 0;
  },
  hasAnyGroup(groups) {
    if (!groups) {
      return false;
    }

    groups.forEach(group => {
      if (this.cognitoInfo.groups.indexOf(group) >= 0) return true;
    });

    return false;
  }
};

function setLoggedIn(newValue) {
  state.loggedIn = newValue;
}

function setLoggedOut() {
  state.loggedIn = false;
  state.cognitoInfo = {};
}

function setAzureInfo(newValue) {
  state.azureInfo = newValue;
}

export default {
  state,
  setLoggedIn,
  setLoggedOut,
  setAzureInfo
};
