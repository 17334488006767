<template>
  <ValidationObserver v-slot="{ failed, passes }">
    <b-modal id="modal-documentType" role="dialog" size="xl" no-close-on-backdrop no-close-on-esc>
      <template slot="modal-header">
        <h5>{{ $t(`form.documentType`) }}</h5>
      </template>
      <template slot="default">
        <div>
          <b-tabs content-class="mt-3" fill>
            <b-tab :title="$t(`headers.tabBasic`)" active>
              <h5 class="mb-3">
                {{ $t(`headers.tabBasic`) }}
              </h5>
              <div class="form-group">
                <ValidationProvider v-slot="{ errors, classes }" :name="$t(`form.name`)" rules="required">
                  <label for="name">{{ $t(`form.name`) }}</label>
                  <CustomInput id="name" v-model="name" :classes="classes" :is-mandatory="true" :errors="errors"></CustomInput>
                </ValidationProvider>
              </div>
              <div class="form-group">
                <ValidationProvider v-slot="{ errors, classes }" :name="$t(`form.translation`)" rules="required">
                  <label for="translation">{{ $t(`form.translation`) }}</label>
                  <CustomInput
                    id="translation"
                    v-model="translation"
                    :classes="classes"
                    :is-mandatory="true"
                    :errors="errors"
                  ></CustomInput>
                </ValidationProvider>
              </div>
              <div class="form-group">
                <label for="description">{{ $t(`form.description`) }}</label>
                <input id="description" v-model="description" type="text" class="form-control" />
              </div>
            </b-tab>
            <b-tab :title="$t(`headers.tabProperties`)">
              <MultiListSelect
                :available-items="availableProperties"
                :selected-items="selectedProperties"
                :display-value="currentLanguage === 'de' ? 'name' : 'translation'"
                :selected-header="$t(`headers.selectedProps`)"
                :available-header="$t(`headers.availableProps`)"
              ></MultiListSelect>
            </b-tab>
            <b-tab :title="$t(`headers.tabMandatory`)" :disabled="selectedProperties.length === 0">
              <MandatoryProperties
                :selected-items="selectedProperties"
                :display-value="currentLanguage === 'de' ? 'name' : 'translation'"
              ></MandatoryProperties>
            </b-tab>
          </b-tabs>
        </div>
      </template>
      <template slot="modal-footer">
        <div class="w-100">
          <button v-if="id" type="button" class="btn btn-danger" @click="deleteItem">
            <i class="far fa-trash-alt"></i>
            {{ $t(`buttons.delete`) }}
          </button>
          <button type="button" class="btn btn-primary float-right" :disabled="failed" @click="passes(saveDocumentType)">
            {{ $t(`buttons.save`) }}
          </button>
          <button type="button" class="btn btn-secondary float-right mr-1" @click="hide">
            {{ $t(`buttons.cancel`) }}
          </button>
        </div>
      </template>
    </b-modal>
  </ValidationObserver>
</template>

<script>
import MultiListSelect from '../common/MultiListSelect.vue';
import MandatoryProperties from './MandatoryProperties.vue';
import DocumentPropertyApi from '../../services/api/documentProperty';
import DocumentTypeApi from '../../services/api/documentType';
import Toast from '../../common/toast';
import CustomInput from '../common/CustomInput.vue';

export default {
  name: 'DocumentTypeDialog',
  components: { MultiListSelect, MandatoryProperties, CustomInput },
  props: {
    id: {
      type: String,
      default: () => ''
    }
  },
  data: function() {
    return {
      name: '',
      translation: '',
      description: '',
      selectedProperties: [],
      availableProperties: []
    };
  },
  computed: {
    currentLanguage: function() {
      return navigator.language.split('-')[0];
    }
  },
  mounted() {
    this.getDocumentProperties();
  },
  methods: {
    hide() {
      this.$emit('hidedialog');
      this.$bvModal.hide('modal-documentType');
    },
    emitSuccessSave() {
      this.$emit('success-save');
      this.hide();
    },
    emitSuccessDelete() {
      this.$emit('success-delete');
      this.hide();
    },
    showErrorMessage(message) {
      Toast.showErrorMessage(this, message);
    },
    getDocumentType() {
      if (!this.id) {
        return;
      }

      DocumentTypeApi.getDocumentType(this.id).then(response => {
        console.log('get successful ', response);
        if (response) {
          this.name = response.name;
          this.translation = response.translation;
          this.description = response.description;

          if (response.properties) {
            response.properties.forEach(prop => {
              var found = this.availableProperties.find(function(element) {
                return element.name === prop.name;
              });
              console.log('found: ', found);

              var index = this.availableProperties.indexOf(found);

              console.log('found index: ', index);
              if (found) {
                this.selectedProperties.push(prop);
                this.availableProperties.splice(index, 1);
              }
            });
          }

          this.selectedProperties.sort(this.sortItems);
        }
      });
    },
    deleteItem() {
      if (!this.id) return;

      this.$bvModal
        .msgBoxConfirm(this.$t(`messages.info.confirmDeleteDocumentType`), {
          cancelTitle: this.$t(`buttons.no`),
          okTitle: this.$t(`buttons.yes`)
        })
        .then(confirmed => {
          if (confirmed) {
            DocumentTypeApi.deleteDocumentType(this.id)
              .then(response => {
                console.log('delete successful ', response);
                this.emitSuccessDelete();
              })
              .catch(error => {
                console.log(error);
                if (error.response && error.response.status === 409) {
                  Toast.showErrorMessage(this, this.$t(`messages.error.stillInUse`));
                } else {
                  Toast.showErrorMessage(this, this.$t(`messages.error.delete`));
                }
              });
          }
        })
        .catch(err => {
          console.log('error confirming deletion', err);
        });
    },
    sortItems(a, b) {
      if (a[this.index] < b[this.index]) return -1;
      if (a[this.index] > b[this.index]) return 1;
      return 0;
    },
    getDocumentProperties() {
      DocumentPropertyApi.getDocumentProperties().then(response => {
        console.log('get successful ', response);
        if (response) {
          response.result.forEach(prop => {
            prop.isMandatory = false;
            this.availableProperties.push(prop);
          });
        }
        this.getDocumentType();
      });
    },
    saveDocumentType() {
      if (this.id) {
        DocumentTypeApi.putDocumentType(this.getPropertyObject())
          .then(response => {
            console.log('success put: ', response);
            this.emitSuccessSave();
          })
          .catch(error => {
            console.log('Error put: ', error);
            this.showErrorMessage(this.$t(`messages.error.save`));
          });
      } else {
        DocumentTypeApi.postDocumentType(this.getPropertyObject())
          .then(response => {
            console.log('success post: ', response);
            this.emitSuccessSave();
          })
          .catch(error => {
            console.log('Error post: ', error);
            this.showErrorMessage(this.$t(`messages.error.save`));
          });
      }
    },
    getPropertyObject() {
      var prop = {
        id: this.id,
        name: this.name,
        translation: this.translation,
        description: this.description,
        properties: this.selectedProperties
      };
      return prop;
    }
  }
};
</script>
<style scoped>
.tabs /deep/ .tab-pane:focus {
  outline: none;
}

*:not(input textarea) {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
