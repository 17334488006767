<template>
  <table class="table table-hover">
    <thead>
      <tr>
        <th scope="col" style="width: 5%"></th>
        <th scope="col" style="width: 35%">
          {{ $t(`form.orderNumber`) }}
        </th>
        <th scope="col" style="width: 35%">
          {{ $t(`form.orderNumberLong`) }}
        </th>
        <th scope="col" style="width: 20%">
          <span v-if="showFoundColumn">
            {{ $t(`form.foundIn`) }}
          </span>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="order in orders" :key="order.id">
        <td>
          <div :title="$t(`buttons.barcode`)" @click="showPositions(order.id)">
            <i class="far fa-print-search"></i>
          </div>
        </td>
        <td>
          {{ order.orderNumber }}
        </td>
        <td>
          {{ order.orderNumberLong }}
        </td>
        <td>
          <span v-if="order.searchedOption">
            <b-badge variant="secondary" style="margin:5px">{{ order.searchedOption }}</b-badge>
          </span>
        </td>
      </tr>
      <tr v-if="!showSpinner && orders.length === 0" style="background:none">
        <td colspan="4">
          <div class="jumbotron">
            {{ $t(`messages.info.noOrdersFound`) }}
          </div>
        </td>
      </tr>
      <tr v-if="showSpinner" style="background:none">
        <td colspan="4">
          <spinner :show-spinner="showSpinner" />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import router from '../../router';
import Spinner from '../common/Spinner';

export default {
  name: 'OrderTable',
  components: {
    Spinner
  },
  props: {
    orders: {
      type: Array,
      default: () => []
    },
    showSpinner: {
      type: Boolean,
      default: () => false
    },
    showFoundColumn: {
      type: Boolean,
      default: () => false
    }
  },
  methods: {
    showPositions: function(id) {
      router.push({ name: 'orderpositions', params: { id: id } });
    }
  }
};
</script>

<style scoped>
.fa-print-search:hover {
  color: var(--secondary);
  cursor: pointer;
}
</style>

<style scoped>
.highlightText {
  background: yellow;
}
</style>
