import axios from 'axios';
import AxiosDefaults from './axios-defaults';

export default {
  getDocumentTypes() {
    return axios.get('/documentTypes', AxiosDefaults.requestConfig).then(response => response.data);
  },
  getDocumentType(id) {
    return axios.get('/documentTypes/' + id, AxiosDefaults.requestConfig).then(response => response.data);
  },
  putDocumentType(documentType) {
    return axios.put('/documentTypes/' + documentType.id, documentType, AxiosDefaults.requestConfig).then(response => response.data);
  },
  postDocumentType(documentType) {
    return axios.post('/documentTypes', documentType, AxiosDefaults.requestConfig).then(response => response.data);
  },
  deleteDocumentType(id) {
    return axios.delete('/documentTypes/' + id, AxiosDefaults.requestConfig).then(response => response);
  }
};
