const de = {
  links: {
    home: 'Home',
    logout: 'Log out',
    login: 'Log in',
    documents: 'Dokumente anzeigen',
    documentProperties: 'Dokumenteigenschaften verwalten',
    secure: 'Sicher anzeigen',
    subscribe: 'Registrieren',
    upload: 'Datei hochladen',
    suppliers: 'Lieferanten verwalten',
    users: 'Benutzer verwalten',
    defineDocuments: 'Dokumenttypen verwalten',
    supplierEvaluation: 'Lieferantenselbstauskunft',
    supplierEvaluationBasicInformation: 'Grundinformationen',
    supplierEvaluationContactDetails: 'Kontaktdaten',
    supplierEvaluationRisksAndProperties: 'Risiken und Perspektiven',
    supplierEvaluationMaterialMasterData: 'Materialstammdaten',
    supplierEvaluationCertificates: 'Zertifikate',
    barcode: 'Barcodes erzeugen',
    orders: 'Bestellungen anzeigen',
    globalConfiguration: 'Globale Einstellungen'
  },
  messages: {
    error: {
      multipleFiles: 'Bitte wählen Sie nur ein Dokument aus',
      getting: 'Es ist ein Fehler beim Aufrufen der Eigenschaften aufgetreten.',
      forbidden: 'Ihnen fehlen die entsprechenden Berechtigungen, um diese Seite zu sehen',
      pageNotFound: 'Die angeforderte Seite konnte nicht gefunden werden',
      uploading: 'Es ist ein Fehler beim Hochladen aufgetreten.',
      tryAgain: 'Bitte versuchen Sie es erneut oder kontaktieren Sie Ihren Administrator, falls der Fehler bestehen bleibt.',
      download: 'Beim Download ist ein Fehler aufgetreten.',
      missingFileName: 'Dateiname fehlt',
      atLeastOneGroup: 'Bitte wählen Sie mindestens eine Gruppe aus',
      delete: 'Es ist ein Fehler beim Löschen aufgetreten',
      emptyFile: 'Es können keine leeren Dateien hochgeladen werden.',
      save: 'Es ist ein Fehler beim Speichern aufgetreten.',
      stillInUse: 'Das Element ist noch in Verwendung und kann daher nicht gelöscht werden.',
      invalidEmail: 'Der Benutzer hat keine gültige E-Mail Adresse und kann daher nicht aktiviert werden.'
    },
    info: {
      homeLatestDocuments: 'Neueste Dokumente',
      homeWelcome: 'Herzlich Willkommen beim Lieferantenportal der VSM',
      welcomeHeader: 'Willkommen bei unserem Lieferantenportal',
      welcomeLogin: 'Bitte melden Sie sich hier an:',
      subscription: 'Registriere dich bei unserem coolen web service!',
      uploadFile: 'Wählen Sie die Dateien zum Hochladen aus',
      selectFile: 'Dateien auswählen oder hier hinziehen...',
      selectDocType: 'Dokumenttyp auswählen...',
      noFiles: 'Es sind noch keine Dateien vorhanden',
      hoverButtonAdd: 'Reihe hinzufügen',
      noFilesFound: 'Es wurden keine Dateien gefunden',
      noDocumentPropertiesFound: 'Es wurden keine Dokumenteigenschaften gefunden',
      noDocumentTypesFound: 'Es wurden keine Dokumenttypen gefunden',
      confirmDownload: 'Alle {count} Dateien herunterladen?',
      noSuppliersFound: 'Es wurden keine Lieferanten gefunden',
      confirmDeleteSupplier: 'Möchten Sie den Lieferanten wirklich löschen?',
      noUsersFound: 'Es wurden keine Benutzer gefunden',
      confirmDeleteUser: 'Möchten Sie den Benutzer wirklich löschen?',
      confirmDeleteDocument: 'Möchten Sie das/die Dokument(e) wirklich löschen?',
      confirmDeleteDocumentType: 'Möchten Sie den Dokumententyp wirklich löschen?',
      noOrdersFound: 'Es wurden keine Bestellungen gefunden',
      overdueDocuments: 'Überfällige Dokumente',
      supplierDeletionWarning: 'Ihr Account ist zum Löschen markiert. Ihre Daten und Benutzer werden am {date} endgültig gelöscht.',
      supplierMarkedForDeletion: 'Dieser Lieferant ist zum Löschen markiert und wird am {date} endgültig gelöscht.',
      requiredField: 'Pflichtfeld',
      uploadStatus: 'Upload Status',
      confirmEnableContact: 'Möchten Sie den Kontakt als Benutzer für das Lieferantenportal freischalten?',
      confirmDisableContact: 'Möchten Sie den Kontakt als Benutzer für das Lieferantenportal deaktivieren?',
      contactDeleted: 'Der Benutzer ist zum Löschen markiert und wird am {date} endgültig entfernt.',
      contactEnabled: 'Der Benutzer ist für die Nutzung des Lieferantenportals aktiviert.',
      confirmEvaluationRelease:
        'Möchten Sie die Lieferantenselbstauskunft wirklich freigeben? Die Werte können danach nicht mehr bearbeitet werden.',
      showingLastYearsEvaluation:
        'Für dieses Jahr wurden noch keine Werte gespeichert. Die angezeigten Werte entstammen der Auskunft aus {year}.',
      confirmLeavePage: 'Es gibt noch ausstehende Änderungen. Möchten Sie die Seite wirklich verlassen?'
    },
    success: {
      logout: 'Ausloggen erfolgreich',
      upload: 'Datei erfolgreich hochgeladen',
      saveSupplier: 'Lieferant wurde erfolreich gespeichert',
      saveUser: 'Benutzer wurde erfolreich gespeichert',
      saveDocumentProperty: 'Dokumenteigenschaft wurde erfolgreich gespeichert',
      delete: 'Löschen erfolgreich',
      documentType: 'Dokumentart wurde erfolgreich gespeichert',
      evaluation: 'Auskunft erfolgreich hochgeladen',
      enableUser: 'Benutzer erfolgreich aktiviert',
      disableUser: 'Benutzer erfolgreich deaktiviert'
    }
  },
  questions: {
    basicInformation: {
      pleaseChoose: 'Bitte wählen Sie eine Option aus',
      corporateName: 'Firmierung des Unternehmens',
      address: 'Adresse',
      addressStreet: 'Straße',
      addressCity: 'Stadt',
      addressZip: 'Postleitzahl',
      legalFormOfCorporate: 'Rechtsform des Unternehmens',
      dunsNumber: 'DUNS-Nummer',
      industrialSector: 'Branchenzugehörigkeit',
      naceCode: 'Wirtschaftszweig (Systematik 2008)',
      areaOfActivity: 'Tätigkeitsspektrum',
      areaOfActivityOption1: 'Hersteller',
      areaOfActivityOption2: 'Dienstleister',
      areaOfActivityOption3: 'Händler bzw. Distributeur',
      partOfCompanyGroup: 'Teil eines Unternehmensverbundes',
      partOfCompanyGroupOption1: 'Ja',
      partOfCompanyGroupOption2: 'Nein',
      employeesTotal: 'Mitarbeiter Gesamt',
      employeesRDQA: 'Mitarbeiter im Bereich F&E / QM',
      employeesProduction: 'Mitarbeiter im Bereich Produktion',
      employeesAdministration: 'Mitarbeiter im Bereich Administration',
      turnoverLastYear: 'Umsatz im zuletzt abgeschlossenen Geschäftsjahr',
      ourCustomerNumber: 'Eigene Kundennummer',
      yourSupplierNumber: 'Lieferantennummer bei VSM AG',
      packagingGuaranteedBy:
        // eslint-disable-next-line max-len
        'Die Rücknahme von Transport- und Umverpackungen i.S.d. Verpackungsverordnung in der jeweils gültigen Fassung wird gewährleistet durch',
      packagingGuaranteedByOption1: 'Lizensiertes Recyclingsystem',
      packagingGuaranteedByOption2: 'Eigene Abholung',
      packagingGuaranteedByOption3: 'Entsorgungsgutschrift',
      packagingGuaranteedByOption4: 'Pfandverpackungen',
      recyclingSystemInformation:
        // eslint-disable-next-line max-len
        'Bitte geben Sie hier das von Ihnen vorgegebene Recyclingsystem, die Höhe der von Ihnen erteilten Entsorgungsgutschrift bzw. sonstige Anmerkungen zum Entsorgungsbereich mit.',
      existingCertifications: 'Bestehende Zertifikate nach DIN EN ISO, Öko-Tex oder weiteren Anforderungen',
      existingCertificationsKindOfCertification: 'Zertifizierung nach',
      existingCertificationsNumber: 'Nummer',
      existingCertificationsRevision: 'Revision',
      existingCertificationsTopic: 'Thema',
      existingCertificationsValidUntil: 'Zertifikat gültig bis'
    },
    contactDetails: {
      name: 'Firmierung',
      companyAddress: 'Geschäftsadresse',
      address: 'Adresse',
      phone: 'Telefon (Vermittlung)',
      email: 'E-Mail',
      fax: 'Fax',
      firstLastName: 'Vorname, Name',
      processingOfOrders: 'Übersendung von Bestellungen (möglichst nicht namensbezogen)',
      generalData: 'Allgemeine Daten',
      commercialContact: 'Kaufmännische(r) Ansprechpartner(in)',
      internalSales: 'Kaufmännische(r) Ansprechpartner(in) Innendienst',
      superior: 'Vorgesetzte(r)',
      fieldSales: 'Kaufmännische(r) Ansprechpartner(in) Außendienst',
      technicalQualityRelatedContact: 'Technische(r) Ansprechpartner(in)/ Ansprechpartner(in) QM',
      technicalContact: 'Technische(r) Ansprechpartner(in)',
      qualityRelatedContact: 'Ansprechpartner Qualitätswesen',
      furtherContactPersons: 'Sonstige Ansprechpartner/ Erreichbarkeiten',
      furtherContactPersonsBusiness: 'Bereich',
      emailContacts: 'Kontakt Email-Adressen',
      emailSafetyDataSheets: 'E-Mail-Adresse für die Abfrage von Sicherheitsdatenblättern',
      emailTechnicalDataSheets: 'E-Mail-Adresse für die Abfrage von technischen Datenblättern',
      emailVendorDeclarations: 'E-Mail-Adresse für die Abfrage von (Langzeit-)Lieferantenerklärungen (sofern der Lieferant aus Europa ist)',
      emailIso: 'E-Mail-Adresse für die Abfrage von ISO-Zertifikaten',
      emailEndUse: 'E-Mail-Adresse für die Abfrage von Endverbleibserklärungen',
      takeContactValues: 'Kontakt-Informationen übernehmen',
      selectContact: 'Kontakt auswählen'
    },
    risksAndProperties: {
      deptRepaymentCapacity: 'Wie hoch ist Ihre fiktive Verschuldungsdauer?',
      deptRepaymentCapacityOption1: 'Kleiner als drei Jahre',
      deptRepaymentCapacityOption2: 'zwischen drei und fünf Jahren',
      deptRepaymentCapacityOption3: 'zwischen fünf und sieben Jahren',
      deptRepaymentCapacityOption4: 'zwischen sieben und neun Jahren',
      deptRepaymentCapacityOption5: 'zwischen neun und 11 Jahren',
      deptRepaymentCapacityOption6: 'größer als 11 Jahre',
      equityRatio: 'Wie hoch ist Ihre Eigenkapitalquote im zuletzt abgeschlossenen Geschäftsjahr?	',
      productLiabilityInsurance: 'Wie hoch ist die Eindeckung Ihrer Produkthaftpflichtversicherung?',
      productLiabilityInsuranceOption1: 'Deckung größer oder gleich EUR 5 Mio je Schadensfall UND größer oder gleich 10 Mio p.a.',
      productLiabilityInsuranceOption2: 'Deckung kleiner EUR 5 Mio je Schadensfall ODER EUR 10 Mio p.a.',
      productLiabilityInsuranceOption3: 'Es besteht keine Produkthaftpflichtversicherung',
      thirdPartyLiabilityInsurance: 'Wie hoch ist die Eindeckung Ihrer Betriebshaftpflichtversicherung?',
      thirdPartyLiabilityInsuranceOption1: 'Deckung größer oder gleich EUR 5 Mio je Schadensfall UND größer oder gleich 10 Mio p.a.	',
      thirdPartyLiabilityInsuranceOption2: 'Deckung kleiner EUR 5 Mio je Schadensfall ODER EUR 10 Mio p.a.	',
      thirdPartyLiabilityInsuranceOption3: 'Es besteht keine Produkthaftpflichtversicherung	',
      totalSales: 'Hat einer Ihrer Kunden einen Umsatzanteil von mehr als 15% am relevanten Gesamtumsatz (Business Unit/ Unternehmen)?',
      totalSalesOption1: 'Ja',
      totalSalesOption2: 'Nein',
      totalSaleShare: 'Wie groß ist der Umsatzanteil Ihrer Hauptbranche?',
      totalSaleShareOption1: 'deutlich unter 30%',
      totalSaleShareOption2: 'kleiner 30%',
      totalSaleShareOption3: 'größer 30%',
      totalSaleShareOption4: 'größer 50%',
      // eslint-disable-next-line max-len
      regionOfGoodsProduced:
        // eslint-disable-next-line max-len
        'In welcher Region werden die an VSM AG Hannover gelieferten Produkte produziert (Bitte geben Sie bei Bedarf mehrere Regionen an)?',
      regionOfGoodsProducedOption1: 'Afrika',
      regionOfGoodsProducedOption2: 'Asien (exkl. China)',
      regionOfGoodsProducedOption3: 'China',
      regionOfGoodsProducedOption4: 'Europa (geographisch)',
      regionOfGoodsProducedOption5: 'Nordamerika',
      regionOfGoodsProducedOption6: 'Ozeanien und Australien',
      regionOfGoodsProducedOption7: 'Südamerika',
      modesOfTransport:
        // eslint-disable-next-line max-len
        'Wieviele Verkehrsträger setzen Sie regelmäßig für die Lieferung aus den o.g. Produktionswerk ein (Bitte geben Sie auch hier bei Bedarf mehrere Daten an)?',
      modesOfTransportOption1: '1',
      modesOfTransportOption2: '2',
      modesOfTransportOption3: '3',
      modesOfTransportOption4: '4',
      durationOfDelivery:
        // eslint-disable-next-line max-len
        'Mit welcher reinen Logistiklaufzeit (Verladung des Lkw bis Lieferung bei VSM AG) ist aus Ihrem jeweiligen Lieferwerk zu rechnen?',
      durationOfDeliveryOption1: 'Lieferung innerhalb eines Tages möglich',
      durationOfDeliveryOption2: 'Lieferung innerhalb von drei Tagen möglich',
      durationOfDeliveryOption3: 'Lieferung benötigt mehr als drei Tage',
      averageStockKeeping:
        // eslint-disable-next-line max-len
        'Wie hoch ist Ihre durchschnittliche Lagerhaltung in Tagen im Vergleich zu Ihrer eigenen Wiederbeschaffungszeit für den jeweiligen Artikel? ',
      averageStockKeepingOption1: 'Die Lagerhaltung entspricht der doppelten Wiederbeschaffungszeit oder höher',
      averageStockKeepingOption2: 'Die Lagerhaltung entspricht der eigenen Wiederbeschaffungszeit',
      averageStockKeepingOption3: 'Die eigene Lagerhaltung ist geringer als die eigene Wiederbeschaffungszeit',
      internalGuidelines: 'Haben Sie interne Vorgaben zu Mitarbeiterverhalten (Einhaltung von Vorschriften und Kodizes) erlassen?',
      internalGuidelinesOption1: 'Es existieren interne Vorgaben.',
      internalGuidelinesOption2: 'Es existieren keine internen Vorgaben, die Mitarbeiter werden jedoch regelmäßig sensibilisiert.',
      internalGuidelinesOption3: 'Es existieren keine internen Vorgaben.',
      complianceEnsurance: 'Wie gewährleisten Sie die Einhaltung von Vorschriften und Kodizes bei Ihren Lieferanten? ',
      complianceEnsuranceOption1: 'Die Einhaltung wird durch eigene Audits überprüft.',
      complianceEnsuranceOption2: 'Die Einhaltung wird mir durch meine Lieferanten zugesichert.',
      complianceEnsuranceOption3: 'Die Forderung an meine Lieferanten ist Bestandteil unserer Geschäftsbedingungen.',
      complianceEnsuranceOption4: 'Es existieren keine Vorgaben an unsere Lieferanten.',
      // eslint-disable-next-line max-len
      measuresToCompensateOutage:
        'Durch welche getroffenen Maßnahmen können Sie den Ausfall eines Produktions- bzw. Lieferstandortes kompensieren?',
      developmentDeliveredGoods: 'Welche Maßnahmen entlang Ihrer Lieferkette haben Sie grundsätzlich getroffen?',
      supplyChain: 'Welche Entwicklungslinien sehen Sie für die von Ihnen an uns gelieferten Artikel in der Zukunft?',
      businessJudging:
        'Wo sehen Sie in unserem gemeinsamen Geschäft mögliche neue Ansatzpunkte, alternative Produkte oder sonstige mögliche Impulse?',
      risks: 'Welche Risiken sehen Sie mittelfristig für Ihr Geschäft bzw. Ihre Branche?',
      anythingElse: 'Was möchten Sie uns ansonsten noch mitteilen?'
    },
    materialMasterData: {
      vsmItem: 'VSM Artikel',
      vsmItemDescripton: 'VSM Artikelbeschreibung',
      itemId: 'Ihre Artikel-ID',
      yourItemDescription: 'Ihre Artikelbeschreibung'
    }
  },
  cognitoGroups: {
    administrator: 'Administrator',
    clerk: 'Sachbearbeiter',
    supplier: 'Lieferant'
  },
  form: {
    firstName: 'Vorname',
    files: 'Dateien',
    lastName: 'Nachname',
    emailAddress: 'E-Mail-Adresse',
    comment: 'Kommentar',
    fileName: 'Dateiname',
    date: 'Datum',
    documentNumber: 'Dokumentennummer',
    documentProperty: 'Eigenschaft',
    selectconfigurationForExport: 'Dokumenttypen für den Export auswählen',
    translation: 'Übersetzung',
    documentType: 'Dokumenttyp',
    translation: 'Übersetzung',
    createDocumentPropertyHeader: 'Neue Dokumenteigenschaft',
    description: 'Beschreibung',
    documentPropertyTitel: 'Titel',
    orderNumber: 'Bestellnummer',
    supplier: 'Lieferant',
    supplierName: 'Lieferantenname',
    supplierNumber: 'Lieferantennummer',
    supplierEvaluation: 'Lieferantenselbstauskunft',
    exportConfiguration: 'Einstellungen exportieren',
    importConfiguration: 'Einstellungen importieren',
    supplierId: 'Lieferanten ID',
    tenantNumber: 'Mandant',
    documentDate: 'Belegdatum',
    lastModified: 'Zuletzt geändert',
    size: 'Größe',
    search: 'Suche...',
    group: 'Rolle',
    groups: 'Rollen',
    groupSelectedLabel: 'Ausgewählt',
    users: 'Benutzer',
    title: 'Titel',
    confirmationDate: 'Bestätigungsdatum',
    name: 'Name',
    positionNumber: 'Pos. Nr.',
    materialNumber: 'Material Nr.',
    supplierMaterialNumber: 'Lieferanten Material Nr.',
    materialDescription: 'Artikel-Bezeichnung',
    lotNumber: 'Chargennummer',
    weight: 'Gewicht',
    deadline: 'Review Datum',
    reason: 'Grund',
    materialDescription: 'Artikelname',
    documentCategory: 'Dokumentkategorie',
    showSupplierEvaluation: 'Lieferantenselbstauskunft anzeigen',
    exportEvaluationConfig: 'Exportiere die Konfiguration für die Lieferantenselbstauskunft',
    emailShort: 'Mail: ',
    phoneShort: 'Tel: ',
    propertyTypeAlphanumeric: 'Alphanumerisch',
    propertyTypeNumeric: 'Numerisch',
    propertyTypeDate: 'Datum',
    type: 'Typ',
    foundIn: 'Gefunden in',
    orderNumberLong: 'Vollständige Bestellnummer'
  },
  buttons: {
    subscribe: 'Registrieren',
    delete: 'Löschen',
    download: 'Download',
    upload: 'Hochladen',
    cancel: 'Abbrechen',
    show: 'Anzeigen',
    add: 'Hinzufügen',
    save: 'Speichern',
    back: 'Zurück',
    edit: 'Bearbeiten',
    yes: 'Ja',
    no: 'Nein',
    send: 'Absenden',
    barcode: 'Barcode generieren',
    barcodes: 'Barcodes generieren',
    print: 'Drucken',
    showDetails: 'Details anzeigen',
    selectAll: 'Alle auswählen',
    clearAll: 'Auswahl löschen',
    next: 'Weiter',
    close: 'Schließen',
    retry: 'Erneut versuchen',
    exportAllDocumentTypes: 'Alle Dokumenttypen exportieren',
    exportSelectedDocumentTypes: 'Dokumenttypen exportieren',
    export: 'Exportieren',
    properties: 'Eigenschaften',
    enable: 'Aktivieren',
    disable: 'Deaktivieren',
    release: 'Freigeben'
  },
  headers: {
    documents: 'Dokumente',
    documentProperties: 'Dokumenteigenschaften',
    documentTypes: 'Dokumenttypen',
    tabDocumentProperties: 'Dokumenteigenschaften',
    tabDocumentTypes: 'Dokumenttypen',
    tabEvaluation: 'Lieferantenselbstauskunft',
    error: 'Fehler',
    success: 'Erfolg',
    suppliers: 'Lieferanten',
    supplierDetails: 'Lieferant',
    users: 'Benutzer',
    userDetails: 'Benutzer',
    orders: 'Bestellungen',
    tabBasic: 'Allgemeine Informationen',
    tabProperties: 'Eigenschaften',
    tabMandatory: 'Pflichtfelder',
    availableProps: 'Verfügbare Eigenschaften',
    selectedProps: 'Ausgewählte Eigenschaften',
    availableDefaultHeader: 'Verfügbare Werte',
    selectedDefaultHeader: 'Ausgewählte Werte',
    mandatoryAndOrder: 'Pflichtfelder und Reihenfolge definieren',
    globalConfiguration: 'Globale Einstellungen',
    availableDocumentTypes: 'Verfügbare Dokumenttypen',
    selectedDocumentTypes: 'Ausgewählte Dokumenttypen',
    evaluation: 'Lieferantenselbstauskunft',
    basicInformation: 'Grundinformationen',
    risksAndProperties: 'Risiken und Perspektiven',
    materialMasterData: 'Materialstammdaten'
  },
  // Dies sind die overrides für VeeValidate Error messages
  validation: {
    required: '{_field_} ist ein Pflichtfeld',
    hasFileExtension: 'Bitte geben Sie eine Dateiendung an',
    email: 'Bitte geben Sie eine gültige E-Mail-Adresse an',
    max: 'Es sind nur {max} Zeichen erlaubt',
    numeric: 'Es sind nur Zahlenwerte erlaubt.'
  }
};

export default de;
