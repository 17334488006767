import { CognitoAuth, StorageHelper } from 'amazon-cognito-auth-js';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import UserInfoStore from './user-info-store';
import * as Msal from 'msal';
import * as msalBrowser from '@azure/msal-browser';
import { authProvider } from '../auth/AuthProvider';
import AxiosDefaults from '../services/api/axios-defaults';

const APP_DOMAIN = process.env.VUE_APP_COGNITO_APP_DOMAIN;
const CLIENT_ID = process.env.VUE_APP_COGNITO_CLIENT_ID;
const USERPOOL_ID = process.env.VUE_APP_COGNITO_USERPOOL_ID;
const AppAuthProvider = authProvider;
console.log('initialize MsalAB2C');
async function getUserInfo() {
  const silentRequest = {
    account: AppAuthProvider.getAccount(),
    scopes: [
      'openid',
      'profile',
      'offline_access',
      'https://vsmsupplierportal.onmicrosoft.com/66948131-96e0-478c-8658-686c12693b0c/access_api'
    ]
  };
  var token = await AppAuthProvider.getTokenRedirect(silentRequest);
  var headers = new Headers();
  var bearer = 'Bearer ' + token.accessToken;
  headers.append('Authorization', bearer);
  let userId = AppAuthProvider.getAccount().homeAccountId;
  var apiEndpunkt =
    (window.location.origin.includes('localhost:5002') ? 'https://localhost:5001' : window.location.origin) + '/api/GroupsRequest/';
  var options = {
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + token.accessToken
    }
  };
  let apiresponse = await fetch(apiEndpunkt, options);

  const groups = await apiresponse.json();
  const lowerCaseGroups = groups.map(group => group.toLowerCase());

  const userinfotokenRequest = {
    scopes: ['openid', 'profile', 'email']
  };

  const userinfosilenttokenRequest = {
    account: AppAuthProvider.getAccount(),
    scopes: ['openid', 'profile', 'email']
  };

  let user_info_token = await AppAuthProvider.getTokenRedirect(userinfosilenttokenRequest, userinfotokenRequest);
  var familyname = user_info_token.idTokenClaims.name.split(' ');

  const azureProperties = {
    groups: lowerCaseGroups,
    userInfo: {
      sub: user_info_token.idTokenClaims.sub,
      name: user_info_token.idTokenClaims.name,
      given_name: user_info_token.idTokenClaims.given_name,
      family_name: familyname[familyname.length - 1],
      preferred_username: user_info_token.account.username,
      email: user_info_token.idTokenClaims.emails[0]
    }
  };

  return azureProperties;
}

function getUserInfoStorageKey() {
  const keyPrefix = `CognitoIdentityServiceProvider.${auth.getClientId()}`;
  const tokenUserName = auth.signInUserSession.getAccessToken().getUsername();
  const userInfoKey = `${keyPrefix}.${tokenUserName}.userInfo`;
  return userInfoKey;
}

export default {
  AppAuthProvider,
  login() {
    authProvider.login();
  },
  logout() {
    if (authProvider.getAccount()) {
      AppAuthProvider.logout();
    }
  },
  getUserInfoStorageKey,
  getUserInfo
};
