const utils = {
  getFileExtension(fileName) {
    if (fileName) {
      return fileName.split('.').pop();
    }
  },
  getImageForFileName(fileName) {
    const extension = this.getFileExtension(fileName);
    switch (extension) {
      case 'doc':
      case 'docx':
      case 'dot':
      case 'dotx':
        return 'far fa-file-word';
      case 'xls':
      case 'xlsx':
      case 'xlst':
        return 'far fa-file-excel';
      case 'ppt':
      case 'pptx':
      case 'xlst':
        return 'far fa-file-powerpoint';
      case 'pdf':
        return 'far fa-file-pdf';
      case 'png':
      case 'jpg':
      case 'jpeg':
      case 'bmp':
      case 'gif':
      case 'tiff':
        return 'far fa-file-image';
      default:
        return 'far fa-file';
    }
  },
  hasFileExtension(fileName) {
    var parts = fileName.split('.');
    var hasExtension = parts.length > 1 && !!parts.pop();
    return hasExtension;
  },
  containsString(value, searchString) {
    if (!value) return false;
    if (!searchString) return true;

    return value
      .toString()
      .toLowerCase()
      .includes(searchString);
  },
  move(array, index, newIndex) {
    if (newIndex > -1 && newIndex < array.length) {
      // Remove the element from the array
      var removedElement = array.splice(index, 1)[0];

      // At "newIndex", remove 0 elements, insert the removed element
      array.splice(newIndex, 0, removedElement);
      return array;
    }
  },
  exportJson(jsonObject, filename) {
    var blob = new Blob([JSON.stringify(jsonObject)], { type: 'text/json' }),
      e = document.createEvent('MouseEvents'),
      a = document.createElement('a');

    a.download = filename;
    a.href = window.URL.createObjectURL(blob);
    a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
    e.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
    a.dispatchEvent(e);
  }
};

export default utils;
