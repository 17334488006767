<template>
  <form>
    <div class="h4">
      <label>{{ $t(`headers.risksAndProperties`) }}</label>
    </div>
    <div class="form-group">
      <label class="question">{{ $t(`questions.risksAndProperties.deptRepaymentCapacity`) }}</label>
      <select v-model="risksAndPropertiesData.deptRepaymentCapacity" class="form-control" :disabled="isReleased" @change="updateValue">
        <option :value="null" disabled="disabled" hidden="hidden">
          {{ $t(`questions.basicInformation.pleaseChoose`) }}
        </option>
        <option value="option1">
          {{ $t(`questions.risksAndProperties.deptRepaymentCapacityOption1`) }}
        </option>
        <option value="option2">
          {{ $t(`questions.risksAndProperties.deptRepaymentCapacityOption2`) }}
        </option>
        <option value="option3">
          {{ $t(`questions.risksAndProperties.deptRepaymentCapacityOption3`) }}
        </option>
        <option value="option4">
          {{ $t(`questions.risksAndProperties.deptRepaymentCapacityOption4`) }}
        </option>
        <option value="option5">
          {{ $t(`questions.risksAndProperties.deptRepaymentCapacityOption5`) }}
        </option>
        <option value="option6">
          {{ $t(`questions.risksAndProperties.deptRepaymentCapacityOption6`) }}
        </option>
      </select>
    </div>
    <div class="form-group">
      <label class="question">{{ $t(`questions.risksAndProperties.equityRatio`) }}</label>
      <input v-model="risksAndPropertiesData.equityRatio" type="text" class="form-control" :disabled="isReleased" @change="updateValue" />
    </div>
    <div class="form-group">
      <label class="question">{{ $t(`questions.risksAndProperties.productLiabilityInsurance`) }}</label>
      <select v-model="risksAndPropertiesData.productLiabilityInsurance" class="form-control" :disabled="isReleased" @change="updateValue">
        <option :value="null" disabled="disabled" hidden="hidden">
          {{ $t(`questions.basicInformation.pleaseChoose`) }}
        </option>
        <option value="option1">
          {{ $t(`questions.risksAndProperties.productLiabilityInsuranceOption1`) }}
        </option>
        <option value="option2">
          {{ $t(`questions.risksAndProperties.productLiabilityInsuranceOption2`) }}
        </option>
        <option value="option3">
          {{ $t(`questions.risksAndProperties.productLiabilityInsuranceOption1`) }}
        </option>
      </select>
    </div>
    <div class="form-group">
      <label class="question">{{ $t(`questions.risksAndProperties.thirdPartyLiabilityInsurance`) }}</label>
      <select
        v-model="risksAndPropertiesData.thirdPartyLiabilityInsurance"
        class="form-control"
        :disabled="isReleased"
        @change="updateValue"
      >
        <option :value="null" disabled="disabled" hidden="hidden">
          {{ $t(`questions.basicInformation.pleaseChoose`) }}
        </option>
        <option value="option1">
          {{ $t(`questions.risksAndProperties.thirdPartyLiabilityInsuranceOption1`) }}
        </option>
        <option value="option2">
          {{ $t(`questions.risksAndProperties.thirdPartyLiabilityInsuranceOption2`) }}
        </option>
        <option value="option3">
          {{ $t(`questions.risksAndProperties.thirdPartyLiabilityInsuranceOption3`) }}
        </option>
      </select>
    </div>
    <div class="form-group">
      <label class="question">{{ $t(`questions.risksAndProperties.totalSales`) }}</label>
      <select v-model="risksAndPropertiesData.totalSales" class="form-control" :disabled="isReleased" @change="updateValue">
        <option :value="null" disabled="disabled" hidden="hidden">
          {{ $t(`questions.basicInformation.pleaseChoose`) }}
        </option>
        <option value="option1">
          {{ $t(`questions.risksAndProperties.totalSalesOption1`) }}
        </option>
        <option value="option2">
          {{ $t(`questions.risksAndProperties.totalSalesOption2`) }}
        </option>
      </select>
    </div>
    <div class="form-group">
      <label class="question">{{ $t(`questions.risksAndProperties.totalSaleShare`) }}</label>
      <select v-model="risksAndPropertiesData.totalSaleShare" class="form-control" :disabled="isReleased" @change="updateValue">
        <option :value="null" disabled="disabled" hidden="hidden">
          {{ $t(`questions.basicInformation.pleaseChoose`) }}
        </option>
        <option value="option1">
          {{ $t(`questions.risksAndProperties.totalSaleShareOption1`) }}
        </option>
        <option value="option2">
          {{ $t(`questions.risksAndProperties.totalSaleShareOption2`) }}
        </option>
        <option value="option3">
          {{ $t(`questions.risksAndProperties.totalSaleShareOption3`) }}
        </option>
        <option value="option4">
          {{ $t(`questions.risksAndProperties.totalSaleShareOption4`) }}
        </option>
      </select>
    </div>
    <div class="form-group">
      <label class="question">
        {{ $t(`questions.risksAndProperties.regionOfGoodsProduced`) }}
      </label>
      <div>
        <div class="form-check">
          <input
            v-model="risksAndPropertiesData.regions.africa.showModes"
            class="form-check-input"
            type="checkbox"
            :disabled="isReleased"
            @change="updateValue"
          />
          <label class="form-check-label question">
            {{ $t(`questions.risksAndProperties.regionOfGoodsProducedOption1`) }}
          </label>
          <div v-if="risksAndPropertiesData.regions.africa.showModes" class="form-group">
            <label class="question">{{ $t(`questions.risksAndProperties.modesOfTransport`) }}</label>
            <select v-model="risksAndPropertiesData.regions.africa.modes" class="form-control" :disabled="isReleased" @change="updateValue">
              <option :value="null" disabled="disabled" hidden="hidden">
                {{ $t(`questions.basicInformation.pleaseChoose`) }}
              </option>
              <option value="option1">
                {{ $t(`questions.risksAndProperties.modesOfTransportOption1`) }}
              </option>
              <option value="option2">
                {{ $t(`questions.risksAndProperties.modesOfTransportOption2`) }}
              </option>
              <option value="option3">
                {{ $t(`questions.risksAndProperties.modesOfTransportOption3`) }}
              </option>
              <option value="option4">
                {{ $t(`questions.risksAndProperties.modesOfTransportOption4`) }}
              </option>
            </select>
          </div>
          <div v-if="risksAndPropertiesData.regions.africa.showModes" class="form-group">
            <label class="question">{{ $t(`questions.risksAndProperties.durationOfDelivery`) }}</label>
            <select
              v-model="risksAndPropertiesData.regions.africa.duration"
              class="form-control"
              :disabled="isReleased"
              @change="updateValue"
            >
              <option :value="null" disabled="disabled" hidden="hidden">
                {{ $t(`questions.basicInformation.pleaseChoose`) }}
              </option>
              <option value="option1">
                {{ $t(`questions.risksAndProperties.durationOfDeliveryOption1`) }}
              </option>
              <option value="option2">
                {{ $t(`questions.risksAndProperties.durationOfDeliveryOption2`) }}
              </option>
              <option value="option3">
                {{ $t(`questions.risksAndProperties.durationOfDeliveryOption3`) }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-check">
          <input
            v-model="risksAndPropertiesData.regions.asia.showModes"
            class="form-check-input"
            type="checkbox"
            :disabled="isReleased"
            @change="updateValue"
          />
          <label class="form-check-label question">
            {{ $t(`questions.risksAndProperties.regionOfGoodsProducedOption2`) }}
          </label>
          <div v-if="risksAndPropertiesData.regions.asia.showModes" class="form-group">
            <label class="question">{{ $t(`questions.risksAndProperties.modesOfTransport`) }}</label>
            <select v-model="risksAndPropertiesData.regions.asia.modes" class="form-control" :disabled="isReleased" @change="updateValue">
              <option :value="null" disabled="disabled" hidden="hidden">
                {{ $t(`questions.basicInformation.pleaseChoose`) }}
              </option>
              <option value="option1">
                {{ $t(`questions.risksAndProperties.modesOfTransportOption1`) }}
              </option>
              <option value="option2">
                {{ $t(`questions.risksAndProperties.modesOfTransportOption2`) }}
              </option>
              <option value="option3">
                {{ $t(`questions.risksAndProperties.modesOfTransportOption3`) }}
              </option>
              <option value="option4">
                {{ $t(`questions.risksAndProperties.modesOfTransportOption4`) }}
              </option>
            </select>
          </div>
          <div v-if="risksAndPropertiesData.regions.asia.showModes" class="form-group">
            <label class="question">{{ $t(`questions.risksAndProperties.durationOfDelivery`) }}</label>
            <select
              v-model="risksAndPropertiesData.regions.asia.duration"
              class="form-control"
              :disabled="isReleased"
              @change="updateValue"
            >
              <option :value="null" disabled="disabled" hidden="hidden">
                {{ $t(`questions.basicInformation.pleaseChoose`) }}
              </option>
              <option value="option1">
                {{ $t(`questions.risksAndProperties.durationOfDeliveryOption1`) }}
              </option>
              <option value="option2">
                {{ $t(`questions.risksAndProperties.durationOfDeliveryOption2`) }}
              </option>
              <option value="option3">
                {{ $t(`questions.risksAndProperties.durationOfDeliveryOption3`) }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-check">
          <input
            v-model="risksAndPropertiesData.regions.china.showModes"
            class="form-check-input"
            type="checkbox"
            :disabled="isReleased"
            @change="updateValue"
          />
          <label class="form-check-label question">
            {{ $t(`questions.risksAndProperties.regionOfGoodsProducedOption3`) }}
          </label>
          <div v-if="risksAndPropertiesData.regions.china.showModes" class="form-group">
            <label class="question">{{ $t(`questions.risksAndProperties.modesOfTransport`) }}</label>
            <select v-model="risksAndPropertiesData.regions.china.modes" class="form-control" :disabled="isReleased" @change="updateValue">
              <option :value="null" disabled="disabled" hidden="hidden">
                {{ $t(`questions.basicInformation.pleaseChoose`) }}
              </option>
              <option value="option1">
                {{ $t(`questions.risksAndProperties.modesOfTransportOption1`) }}
              </option>
              <option value="option2">
                {{ $t(`questions.risksAndProperties.modesOfTransportOption2`) }}
              </option>
              <option value="option3">
                {{ $t(`questions.risksAndProperties.modesOfTransportOption3`) }}
              </option>
              <option value="option4">
                {{ $t(`questions.risksAndProperties.modesOfTransportOption4`) }}
              </option>
            </select>
          </div>
          <div v-if="risksAndPropertiesData.regions.china.showModes" class="form-group">
            <label class="question">{{ $t(`questions.risksAndProperties.durationOfDelivery`) }}</label>
            <select
              v-model="risksAndPropertiesData.regions.china.duration"
              class="form-control"
              :disabled="isReleased"
              @change="updateValue"
            >
              <option :value="null" disabled="disabled" hidden="hidden">
                {{ $t(`questions.basicInformation.pleaseChoose`) }}
              </option>
              <option value="option1">
                {{ $t(`questions.risksAndProperties.durationOfDeliveryOption1`) }}
              </option>
              <option value="option2">
                {{ $t(`questions.risksAndProperties.durationOfDeliveryOption2`) }}
              </option>
              <option value="option3">
                {{ $t(`questions.risksAndProperties.durationOfDeliveryOption3`) }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-check">
          <input
            v-model="risksAndPropertiesData.regions.europe.showModes"
            class="form-check-input"
            type="checkbox"
            :disabled="isReleased"
            @change="updateValue"
          />
          <label class="form-check-label question">
            {{ $t(`questions.risksAndProperties.regionOfGoodsProducedOption4`) }}
          </label>
          <div v-if="risksAndPropertiesData.regions.europe.showModes" class="form-group">
            <label class="question">{{ $t(`questions.risksAndProperties.modesOfTransport`) }}</label>
            <select v-model="risksAndPropertiesData.regions.europe.modes" class="form-control" :disabled="isReleased" @change="updateValue">
              <option :value="null" disabled="disabled" hidden="hidden">
                {{ $t(`questions.basicInformation.pleaseChoose`) }}
              </option>
              <option value="option1">
                {{ $t(`questions.risksAndProperties.modesOfTransportOption1`) }}
              </option>
              <option value="option2">
                {{ $t(`questions.risksAndProperties.modesOfTransportOption2`) }}
              </option>
              <option value="option3">
                {{ $t(`questions.risksAndProperties.modesOfTransportOption3`) }}
              </option>
              <option value="option4">
                {{ $t(`questions.risksAndProperties.modesOfTransportOption4`) }}
              </option>
            </select>
          </div>
          <div v-if="risksAndPropertiesData.regions.europe.showModes" class="form-group">
            <label class="question">{{ $t(`questions.risksAndProperties.durationOfDelivery`) }}</label>
            <select
              v-model="risksAndPropertiesData.regions.europe.duration"
              class="form-control"
              :disabled="isReleased"
              @change="updateValue"
            >
              <option :value="null" disabled="disabled" hidden="hidden">
                {{ $t(`questions.basicInformation.pleaseChoose`) }}
              </option>
              <option value="option1">
                {{ $t(`questions.risksAndProperties.durationOfDeliveryOption1`) }}
              </option>
              <option value="option2">
                {{ $t(`questions.risksAndProperties.durationOfDeliveryOption2`) }}
              </option>
              <option value="option3">
                {{ $t(`questions.risksAndProperties.durationOfDeliveryOption3`) }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-check">
          <input
            v-model="risksAndPropertiesData.regions.northAmerica.showModes"
            class="form-check-input"
            type="checkbox"
            :disabled="isReleased"
            @change="updateValue"
          />
          <label class="form-check-label question">
            {{ $t(`questions.risksAndProperties.regionOfGoodsProducedOption5`) }}
          </label>
          <div v-if="risksAndPropertiesData.regions.northAmerica.showModes" class="form-group">
            <label class="question">{{ $t(`questions.risksAndProperties.modesOfTransport`) }}</label>
            <select
              v-model="risksAndPropertiesData.regions.northAmerica.modes"
              class="form-control"
              :disabled="isReleased"
              @change="updateValue"
            >
              <option :value="null" disabled="disabled" hidden="hidden">
                {{ $t(`questions.basicInformation.pleaseChoose`) }}
              </option>
              <option value="option1">
                {{ $t(`questions.risksAndProperties.modesOfTransportOption1`) }}
              </option>
              <option value="option2">
                {{ $t(`questions.risksAndProperties.modesOfTransportOption2`) }}
              </option>
              <option value="option3">
                {{ $t(`questions.risksAndProperties.modesOfTransportOption3`) }}
              </option>
              <option value="option4">
                {{ $t(`questions.risksAndProperties.modesOfTransportOption4`) }}
              </option>
            </select>
          </div>
          <div v-if="risksAndPropertiesData.regions.northAmerica.showModes" class="form-group">
            <label class="question">{{ $t(`questions.risksAndProperties.durationOfDelivery`) }}</label>
            <select
              v-model="risksAndPropertiesData.regions.northAmerica.duration"
              class="form-control"
              :disabled="isReleased"
              @change="updateValue"
            >
              <option :value="null" disabled="disabled" hidden="hidden">
                {{ $t(`questions.basicInformation.pleaseChoose`) }}
              </option>
              <option value="option1">
                {{ $t(`questions.risksAndProperties.durationOfDeliveryOption1`) }}
              </option>
              <option value="option2">
                {{ $t(`questions.risksAndProperties.durationOfDeliveryOption2`) }}
              </option>
              <option value="option3">
                {{ $t(`questions.risksAndProperties.durationOfDeliveryOption3`) }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-check">
          <input
            v-model="risksAndPropertiesData.regions.oceania.showModes"
            class="form-check-input"
            type="checkbox"
            :disabled="isReleased"
            @change="updateValue"
          />
          <label class="form-check-label question">
            {{ $t(`questions.risksAndProperties.regionOfGoodsProducedOption6`) }}
          </label>
          <div v-if="risksAndPropertiesData.regions.oceania.showModes" class="form-group">
            <label class="question">{{ $t(`questions.risksAndProperties.modesOfTransport`) }}</label>
            <select
              v-model="risksAndPropertiesData.regions.oceania.modes"
              class="form-control"
              :disabled="isReleased"
              @change="updateValue"
            >
              <option :value="null" disabled="disabled" hidden="hidden">
                {{ $t(`questions.basicInformation.pleaseChoose`) }}
              </option>
              <option value="option1">
                {{ $t(`questions.risksAndProperties.modesOfTransportOption1`) }}
              </option>
              <option value="option2">
                {{ $t(`questions.risksAndProperties.modesOfTransportOption2`) }}
              </option>
              <option value="option3">
                {{ $t(`questions.risksAndProperties.modesOfTransportOption3`) }}
              </option>
              <option value="option4">
                {{ $t(`questions.risksAndProperties.modesOfTransportOption4`) }}
              </option>
            </select>
          </div>
          <div v-if="risksAndPropertiesData.regions.oceania.showModes" class="form-group">
            <label class="question">{{ $t(`questions.risksAndProperties.durationOfDelivery`) }}</label>
            <select
              v-model="risksAndPropertiesData.regions.oceania.duration"
              class="form-control"
              :disabled="isReleased"
              @change="updateValue"
            >
              <option :value="null" disabled="disabled" hidden="hidden">
                {{ $t(`questions.basicInformation.pleaseChoose`) }}
              </option>
              <option value="option1">
                {{ $t(`questions.risksAndProperties.durationOfDeliveryOption1`) }}
              </option>
              <option value="option2">
                {{ $t(`questions.risksAndProperties.durationOfDeliveryOption2`) }}
              </option>
              <option value="option3">
                {{ $t(`questions.risksAndProperties.durationOfDeliveryOption3`) }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-check">
          <input
            v-model="risksAndPropertiesData.regions.southAmerica.showModes"
            class="form-check-input"
            type="checkbox"
            :disabled="isReleased"
            @change="updateValue"
          />
          <label class="form-check-label question">
            {{ $t(`questions.risksAndProperties.regionOfGoodsProducedOption7`) }}
          </label>
          <div v-if="risksAndPropertiesData.regions.southAmerica.showModes" class="form-group">
            <label class="question">{{ $t(`questions.risksAndProperties.modesOfTransport`) }}</label>
            <select
              v-model="risksAndPropertiesData.regions.southAmerica.modes"
              class="form-control"
              :disabled="isReleased"
              @change="updateValue"
            >
              <option :value="null" disabled="disabled" hidden="hidden">
                {{ $t(`questions.basicInformation.pleaseChoose`) }}
              </option>
              <option value="option1">
                {{ $t(`questions.risksAndProperties.modesOfTransportOption1`) }}
              </option>
              <option value="option2">
                {{ $t(`questions.risksAndProperties.modesOfTransportOption2`) }}
              </option>
              <option value="option3">
                {{ $t(`questions.risksAndProperties.modesOfTransportOption3`) }}
              </option>
              <option value="option4">
                {{ $t(`questions.risksAndProperties.modesOfTransportOption4`) }}
              </option>
            </select>
          </div>
          <div v-if="risksAndPropertiesData.regions.southAmerica.showModes" class="form-group">
            <label class="question">{{ $t(`questions.risksAndProperties.durationOfDelivery`) }}</label>
            <select
              v-model="risksAndPropertiesData.regions.southAmerica.duration"
              class="form-control"
              :disabled="isReleased"
              @change="updateValue"
            >
              <option :value="null" disabled="disabled" hidden="hidden">
                {{ $t(`questions.basicInformation.pleaseChoose`) }}
              </option>
              <option value="option1">
                {{ $t(`questions.risksAndProperties.durationOfDeliveryOption1`) }}
              </option>
              <option value="option2">
                {{ $t(`questions.risksAndProperties.durationOfDeliveryOption2`) }}
              </option>
              <option value="option3">
                {{ $t(`questions.risksAndProperties.durationOfDeliveryOption3`) }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <br />
      <div class="form-group">
        <label class="question">{{ $t(`questions.risksAndProperties.averageStockKeeping`) }}</label>
        <select v-model="risksAndPropertiesData.averageStockKeeping" class="form-control" :disabled="isReleased" @change="updateValue">
          <option :value="null" disabled="disabled" hidden="hidden">
            {{ $t(`questions.basicInformation.pleaseChoose`) }}
          </option>
          <option value="option1">
            {{ $t(`questions.risksAndProperties.averageStockKeepingOption1`) }}
          </option>
          <option value="option2">
            {{ $t(`questions.risksAndProperties.averageStockKeepingOption2`) }}
          </option>
          <option value="option3">
            {{ $t(`questions.risksAndProperties.averageStockKeepingOption3`) }}
          </option>
        </select>
      </div>
      <div class="form-group">
        <label class="question">{{ $t(`questions.risksAndProperties.internalGuidelines`) }}</label>
        <select v-model="risksAndPropertiesData.internalGuidelines" class="form-control" :disabled="isReleased" @change="updateValue">
          <option :value="null" disabled="disabled" hidden="hidden">
            {{ $t(`questions.basicInformation.pleaseChoose`) }}
          </option>
          <option value="option1">
            {{ $t(`questions.risksAndProperties.internalGuidelinesOption1`) }}
          </option>
          <option value="option2">
            {{ $t(`questions.risksAndProperties.internalGuidelinesOption2`) }}
          </option>
          <option value="option3">
            {{ $t(`questions.risksAndProperties.internalGuidelinesOption3`) }}
          </option>
        </select>
      </div>
      <div class="form-group">
        <label class="question">{{ $t(`questions.risksAndProperties.complianceEnsurance`) }}</label>
        <select v-model="risksAndPropertiesData.complianceEnsurance" class="form-control" :disabled="isReleased" @change="updateValue">
          <option :value="null" disabled="disabled" hidden="hidden">
            {{ $t(`questions.basicInformation.pleaseChoose`) }}
          </option>
          <option value="option1">
            {{ $t(`questions.risksAndProperties.complianceEnsuranceOption1`) }}
          </option>
          <option value="option2">
            {{ $t(`questions.risksAndProperties.complianceEnsuranceOption2`) }}
          </option>
          <option value="option3">
            {{ $t(`questions.risksAndProperties.complianceEnsuranceOption3`) }}
          </option>
          <option value="option4">
            {{ $t(`questions.risksAndProperties.complianceEnsuranceOption4`) }}
          </option>
        </select>
      </div>
      <div v-for="(item, index) in questions" :key="index" class="form-group">
        <label class="question">{{ item.question }}</label>
        <div class="form control">
          <textarea v-model="risksAndPropertiesData[item.id]" class="form-control" rows="" :disabled="isReleased" @change="updateValue" />
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import UserInfoStore from '../../auth/user-info-store';
import constants from '../../common/constants';
import UserApi from '../../services/api/user';

export default {
  name: 'SupplierEvaluationRisksAndProperties',
  props: {
    risksAndProperties: {
      type: Object,
      default: () => {}
    },
    isReleased: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      risksAndPropertiesData: {
        regions: {
          africa: { showModes: false },
          asia: { showModes: false },
          china: { showModes: false },
          europe: { showModes: false },
          northAmerica: { showModes: false },
          oceania: { showModes: false },
          southAmerica: { showModes: false }
        }
      },
      questions: [
        { question: this.$t(`questions.risksAndProperties.measuresToCompensateOutage`), id: 'measuresToCompensateOutage' },
        { question: this.$t(`questions.risksAndProperties.developmentDeliveredGoods`), id: 'developmentDeliveredGoods' },
        { question: this.$t(`questions.risksAndProperties.supplyChain`), id: 'supplyChain' },
        { question: this.$t(`questions.risksAndProperties.businessJudging`), id: 'businessJudging' },
        { question: this.$t(`questions.risksAndProperties.risks`), id: 'risks' },
        { question: this.$t(`questions.risksAndProperties.anythingElse`), id: 'anythingElse' }
      ]
    };
  },
  mounted() {
    this.risksAndPropertiesData = Object.assign({}, this.risksAndProperties);
  },
  methods: {
    updateValue() {
      this.$emit('risksAndPropertiesUpdate', this.risksAndPropertiesData);
    }
  }
};
</script>
