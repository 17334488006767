<template>
  <div id="app" class="container-fluid">
    <CustomSideBar></CustomSideBar>
    <div class="row" style="padding-left:60px">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import CustomSideBar from './components/navigation/CustomSideBar.vue';
import DocumentList from './components/document/DocumentList.vue';
export default {
  name: 'App',
  components: {
    CustomSideBar
  },
  created() {
    document.title = process.env.VUE_APP_TITLE;
  }
};
</script>

<style lang="scss">
// Import custom SASS variable overrides, or alternatively
// define your variable overrides here instead
@import 'assets/custom.scss';

// Import Bootstrap and BootstrapVue source SCSS files
@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap-vue/src/index.scss';

:root {
  --fa-primary-color: var(--primary) !important;
  --fa-secondary-color: var(--secondary) !important;
  --fa-primary-opacity: 1 !important;
  --fa-secondary-opacity: 1 !important;
}

// General style overrides and custom classes
a {
  color: #cd0529;
}

a:hover {
  color: #b3b3b4;
}

body {
  font-family: 'Myriad Pro', Calibri, sans-serif;
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }

  @page {
    margin: 0; /* this affects the margin in the printer settings */
  }

  body {
    margin: 1.6cm;
  }
}
</style>
