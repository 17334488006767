import axios from 'axios';
import AxiosDefaults from './axios-defaults';

export default {
  getDocumentProperties() {
    return axios.get('/documentproperties', AxiosDefaults.requestConfig).then(response => response.data);
  },
  postDocumentProperty(property) {
    return axios.post('/documentproperties', property, AxiosDefaults.requestConfig).then(response => response.data);
  },
  deleteDocumentProperty(id) {
    return axios.delete(`/documentproperties/${id}`, AxiosDefaults.requestConfig).then(response => response.data);
  }
};
