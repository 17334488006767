<template>
  <div class="container-fluid">
    <ViewHeader :title="$t(`headers.users`)"></ViewHeader>
    <div class="input-group mb-3">
      <input v-model="searchString" class="form-control" type="text" :placeholder="$t(`form.search`)" />
    </div>
    <div class="btn-group">
      <span class="float-left mb-3">
        <button type="button" class="btn btn-primary" @click="addUser">
          <i class="far fa-plus"></i>
          {{ $t(`buttons.add`) }}
        </button>
      </span>
    </div>
    <UserTable :show-spinner="showSpinner" :users="filteredUsers"></UserTable>
  </div>
</template>

<script>
import ViewHeader from '../common/ViewHeader.vue';
import UserTable from '../user/UserTable.vue';
import Utils from '../../common/utils';
import router from '../../router';
import UserApi from '../../services/api/user';

export default {
  name: 'UserList',
  components: {
    ViewHeader,
    UserTable
  },
  data() {
    return {
      searchString: '',
      users: [],
      showSpinner: false
    };
  },
  computed: {
    filteredUsers: function() {
      let searchStrings = this.searchString
        .toLowerCase()
        .trim()
        .split(' ');

      return this.users.filter(user => {
        for (let index = 0; index < searchStrings.length; index++) {
          const searchPart = searchStrings[index];

          let userFound = Utils.containsString(user.emailAddress, searchPart);
          if (!userFound) return false;
        }
        return true;
      });
    }
  },
  mounted() {
    this.fetchUsers();
  },
  methods: {
    addUser: function() {
      router.push({ name: 'userdetails', params: { id: 'new' } });
    },
    fetchUsers() {
      this.users = [];
      this.showSpinner = true;

      UserApi.getUsers()
        .then(response => {
          console.log('fetch successful: ', response);
          response.result.forEach(user => {
            this.users.push(user);
          });
          this.showSpinner = false;
        })
        .catch(error => {
          console.log(error);
          this.showSpinner = false;
        });
    }
  }
};
</script>
