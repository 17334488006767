<template>
  <table class="table table-hover">
    <thead>
      <tr>
        <th scope="col" style="width: 5%">
          <div class="custom-control custom-checkbox">
            <input id="selectAll" type="checkbox" class="custom-control-input" :checked="allFilesSelected" @click="selectAll" />
            <label class="custom-control-label" for="selectAll"></label>
          </div>
        </th>
        <th scope="col" style="width: 30%">
          {{ $t(`form.title`) }}
        </th>
        <th scope="col" class="d-none d-md-table-cell" style="width: 30%">
          {{ $t(`form.documentType`) }}
        </th>
        <th scope="col" class="d-none d-xl-table-cell" style="width: 20%">
          {{ $t(`form.fileName`) }}
        </th>
        <th scope="col" class="d-none d-md-table-cell" style="width: 10%">
          {{ $t(`form.documentDate`) }}
        </th>
        <th scope="col" class="d-none d-lg-table-cell" style="width: 5%">
          {{ $t(`form.size`) }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="file in files"
        :key="file.id"
        class="clickable"
        :class="file.isSelected ? 'table-active' : ''"
        @click.stop="file.isSelected = !file.isSelected"
      >
        <td>
          <div v-if="file.isSelected">
            <i class="far fa-check"></i>
          </div>
        </td>
        <td>
          {{ file.title }}
        </td>
        <td class="d-none d-md-table-cell">
          {{ file[docTypeDisplay] }}
        </td>
        <td class="d-none d-xl-table-cell">
          <i :class="[file.imageClass, 'mr-1']"></i>
          {{ file.fileName }}
        </td>
        <td class="d-none d-md-table-cell">
          <p>{{ file.documentDate | localeDate }}</p>
        </td>
        <td class="d-none d-lg-table-cell">
          {{ file.sizeInBytes | formatFileSize }}
        </td>
      </tr>
      <tr v-if="!showSpinner && files.length === 0" style="background:none">
        <td colspan="8">
          <div class="jumbotron">
            {{ $t(`messages.info.noFilesFound`) }}
          </div>
        </td>
      </tr>
      <tr v-if="showSpinner" style="background:none">
        <td colspan="8">
          <spinner :show-spinner="showSpinner" />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { type } from 'os';
import Spinner from '../common/Spinner';

export default {
  name: 'DocumentTable',
  components: {
    Spinner
  },
  props: {
    files: {
      type: Array,
      default: () => []
    },
    showSpinner: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    allFilesSelected: function() {
      if (this.files.length === 0) {
        return false;
      }
      return this.files.every(file => {
        return file.isSelected;
      });
    },
    currentLanguage: function() {
      return navigator.language.split('-')[0];
    },
    docTypeDisplay: function() {
      if (this.currentLanguage === 'de') return 'documentTypeName';
      else return 'documentTypeTranslation';
    }
  },
  methods: {
    selectAll() {
      const selected = !this.allFilesSelected;

      this.files.forEach((file, index) => {
        file.isSelected = selected;
      });
    }
  }
};
</script>

<style scoped>
.clickable:hover {
  cursor: pointer;
}
</style>
