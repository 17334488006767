<template>
  <b-modal id="modal-document-details" :title="$t(`headers.documentProperties`)" role="dialog" size="lg" no-close-on-backdrop>
    <div class="row">
      <div class="col-3">
        <b>
          {{ $t(`form.title`) }}
        </b>
      </div>
      <div class="col-9">
        {{ document.title }}
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <b>
          {{ $t(`form.documentType`) }}
        </b>
      </div>
      <div class="col-9">
        {{ document[docTypeDisplay] }}
      </div>
    </div>
    <div v-for="(prop, index) in document.properties" :key="index" class="row">
      <div class="col-3">
        <b>
          {{ prop[displayValue] }}
        </b>
      </div>
      <div v-if="prop.type === 2" class="col-9">
        {{ prop.value | localeDate }}
      </div>
      <div v-else class="col-9">
        {{ prop.value }}
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <b>
          {{ $t(`form.documentDate`) }}
        </b>
      </div>
      <div class="col-9">
        {{ document.documentDate | localeDate }}
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <b>
          {{ $t(`form.fileName`) }}
        </b>
      </div>
      <div class="col-9">
        {{ document.fileName }}
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <b>
          {{ $t(`form.size`) }}
        </b>
      </div>
      <div class="col-9">
        {{ document.sizeInBytes | formatFileSize }}
      </div>
    </div>
    <template v-slot:modal-footer="{ ok }">
      <button class="btn btn-primary" @click="ok">
        {{ $t(`buttons.close`) }}
      </button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'DocumentDetails',
  components: {},
  props: {
    document: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    currentLanguage: function() {
      return navigator.language.split('-')[0];
    },
    docTypeDisplay: function() {
      if (this.currentLanguage === 'de') return 'documentTypeName';
      else return 'documentTypeTranslation';
    },
    displayValue: function() {
      if (this.currentLanguage === 'de') return 'name';
      else return 'translation';
    }
  }
};
</script>
