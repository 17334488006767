<template>
  <div class="container-fluid">
    <ViewHeader :title="$t(`headers.suppliers`)"></ViewHeader>
    <div class="input-group mb-3">
      <input v-model="searchString" class="form-control" type="text" :placeholder="$t(`form.search`)" />
    </div>
    <SupplierTable :show-spinner="showSpinner" :suppliers="filteredSuppliers"></SupplierTable>
  </div>
</template>

<script>
import ViewHeader from '../common/ViewHeader.vue';
import SupplierTable from './SupplierTable.vue';
import Utils from '../../common/utils';
import SupplierApi from '../../services/api/supplier';
import router from '../../router';

export default {
  name: 'SupplierList',
  components: {
    ViewHeader,
    SupplierTable
  },
  data() {
    return {
      searchString: '',
      suppliers: [],
      showSpinner: false
    };
  },
  computed: {
    filteredSuppliers: function() {
      let searchStrings = this.searchString
        .toLowerCase()
        .trim()
        .split(' ');

      return this.suppliers.filter(supplier => {
        for (let index = 0; index < searchStrings.length; index++) {
          const searchPart = searchStrings[index];

          let supplierFound =
            Utils.containsString(supplier.name, searchPart) ||
            Utils.containsString(supplier.supplierNumber, searchPart) ||
            Utils.containsString(supplier.tenantNumber, searchPart);
          if (!supplierFound) return false;
        }
        return true;
      });
    }
  },
  mounted() {
    this.fetchSuppliers();
  },
  methods: {
    fetchSuppliers() {
      this.suppliers = [];
      this.showSpinner = true;

      SupplierApi.getSuppliers()
        .then(response => {
          console.log('fetch successful: ', response);
          response.result.forEach(supplier => {
            this.suppliers.push(supplier);
          });
          this.showSpinner = false;
        })
        .catch(error => {
          console.log(error);
          this.showSpinner = false;
        });
    }
  }
};
</script>
