<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-5">
        <h5 class="mb-3">
          {{ selectedHeaderText }}
        </h5>
        <div class="list-group">
          <a
            v-for="(item, index) in selectedItems"
            :key="index"
            href="#"
            class="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
            @click="deselectItem(item, index)"
          >
            <div>
              <font-awesome-icon
                v-if="item.type !== null && item.type !== undefined"
                class="fa-sm mr-2 low-opacity"
                :icon="['far', selectIcon(item)]"
              ></font-awesome-icon>
              {{ item[displayValue] }}
            </div>
          </a>
        </div>
      </div>
      <div class="col-2 text-center align-self-center">
        <div @click="selectAllItems">
          <i :title="$t(`buttons.selectAll`)" class="far fa-chevron-double-left fa-2x clickable"></i>
        </div>
        <div class="mt-3" @click="clearAllItems">
          <i :title="$t(`buttons.clearAll`)" class="far fa-chevron-double-right fa-2x clickable"></i>
        </div>
      </div>
      <div class="col-5">
        <h5 class="mb-3">
          {{ availableHeaderText }}
        </h5>
        <div class="list-group">
          <a
            v-for="(item, index) in availableItems"
            :key="index"
            href="#"
            class="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
            @click="selectItem(item, index)"
          >
            <div>
              <font-awesome-icon
                v-if="item.type !== null && item.type !== undefined"
                class="fa-sm mr-2 low-opacity"
                :icon="['far', selectIcon(item)]"
              ></font-awesome-icon>
              {{ item[displayValue] }}
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MultiListSelect',
  props: {
    selectedItems: {
      type: Array,
      default: () => []
    },
    availableItems: {
      type: Array,
      default: () => []
    },
    displayValue: {
      type: String,
      default: () => 'name'
    },
    availableHeader: {
      type: String,
      default: () => ''
    },
    selectedHeader: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    selectedHeaderText: function() {
      if (!this.selectedHeader) {
        return this.$t(`headers.selectedDefaultHeader`);
      }

      return this.selectedHeader;
    },
    availableHeaderText: function() {
      if (!this.availableHeader) {
        return this.$t(`headers.availableDefaultHeader`);
      }

      return this.availableHeader;
    }
  },
  mounted() {
    this.availableItems.sort(this.sortItems);
    this.selectedItems.sort(this.sortItems);
  },
  methods: {
    selectIcon: function(item) {
      switch (item.type) {
        case 0:
          return 'text';
        case 1:
          return 'calculator';
        case 2:
          return 'calendar-alt';
      }
    },
    selectItem(item, index) {
      item.index = this.selectedItems.length;
      this.selectedItems.push(item);
      this.availableItems.splice(index, 1);
    },
    deselectItem(item, index) {
      item.isMandatory = false;
      this.availableItems.push(item);
      this.availableItems.sort(this.sortAvailableItems);
      this.selectedItems.splice(index, 1);
    },
    selectAllItems() {
      this.availableItems.forEach(item => {
        item.index = this.selectedItems.length;
        this.selectedItems.push(item);
      });

      this.availableItems.splice(0, this.availableItems.length);
    },
    clearAllItems() {
      this.selectedItems.forEach(item => {
        item.isMandatory = false;
        this.availableItems.push(item);
      });
      this.availableItems.sort(this.sortAvailableItems);
      this.selectedItems.splice(0, this.selectedItems.length);
    },
    sortAvailableItems(a, b) {
      if (a[this.displayValue] < b[this.displayValue]) return -1;
      if (a[this.displayValue] > b[this.displayValue]) return 1;
      return 0;
    }
  }
};
</script>

<style scoped>
.list-group {
  max-height: 500px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.clickable:hover {
  color: var(--secondary);
  cursor: pointer;
}

.low-opacity {
  opacity: 0.5;
}
</style>
