<template>
  <div class="container-fluid" style="height:100%">
    <ViewHeader :title="$t(`headers.evaluation`)"></ViewHeader>
    <div class="row rest-height">
      <div class="col-2 vertical-line">
        <div class="sticky-top">
          <div class="btn-group mt-3 mb-3 w-100 row">
            <div class="col-6 no-padding-right">
              <button
                type="button"
                class="btn btn-secondary w-100 text-truncate"
                :disabled="supplierResponses.isReleased"
                :title="$t(`buttons.release`)"
                @click="releaseEvaluation"
              >
                <span>
                  <i class="far fa-share-square"></i>
                  {{ $t(`buttons.release`) }}
                </span>
              </button>
            </div>
            <div class="col-6 no-padding-right float-right">
              <button
                type="button"
                class="btn btn-primary w-100 text-truncate"
                :disabled="supplierResponses.isReleased"
                :title="$t(`buttons.save`)"
                @click="putEvaluation"
              >
                <span>
                  <i class="far fa-save"></i>
                  {{ $t(`buttons.save`) }}
                </span>
              </button>
            </div>
          </div>
          <div class="btn-group-vertical col-12 no-padding-left">
            <button
              type="button"
              class="btn btn-outline-primary btn-lg text-truncate"
              :title="$t(`links.supplierEvaluationBasicInformation`)"
              :class="isBasicInformationActive ? 'active' : ''"
              @click="showPage(true, false, false, false, false)"
            >
              {{ $t(`links.supplierEvaluationBasicInformation`) }}
            </button>
            <button
              type="button"
              :title="$t(`links.supplierEvaluationCertificates`)"
              class="btn btn-outline-primary btn-lg text-truncate"
              :class="isCertificatesActive ? 'active' : ''"
              @click="showPage(false, true, false, false, false)"
            >
              {{ $t(`links.supplierEvaluationCertificates`) }}
            </button>
            <button
              type="button"
              class="btn btn-outline-primary btn-lg text-truncate"
              :class="isContactDetailsActive ? 'active' : ''"
              :title="$t(`links.supplierEvaluationContactDetails`)"
              @click="showPage(false, false, true, false, false)"
            >
              {{ $t(`links.supplierEvaluationContactDetails`) }}
            </button>
            <button
              type="button"
              class="btn btn-outline-primary btn-lg text-truncate"
              :class="isRisksAndPropertiesActive ? 'active' : ''"
              :title="$t(`links.supplierEvaluationRisksAndProperties`)"
              @click="showPage(false, false, false, true, false)"
            >
              {{ $t(`links.supplierEvaluationRisksAndProperties`) }}
            </button>
            <button
              type="button"
              class="btn btn-outline-primary btn-lg text-truncate"
              :class="isMaterialMasterDataActive ? 'active' : ''"
              :title="$t(`links.supplierEvaluationMaterialMasterData`)"
              @click="showPage(false, false, false, false, true)"
            >
              {{ $t(`links.supplierEvaluationMaterialMasterData`) }}
            </button>
          </div>
        </div>
      </div>
      <div id="questionid" ref="questions" class="col-10 full-height">
        <div v-if="supplierResponses.year < new Date().getFullYear()" class="alert alert-info">
          {{ $t(`messages.info.showingLastYearsEvaluation`, { year: supplierResponses.year }) }}
        </div>
        <SupplierEvaluationBasicInformation
          v-if="isBasicInformationActive"
          :basic-information="supplierResponses.basicInformation"
          :is-released="supplierResponses.isReleased"
          @basicInformationUpdate="updateValue"
        ></SupplierEvaluationBasicInformation>
        <SupplierEvaluationCertificates
          v-if="isCertificatesActive"
          :certificates="supplierResponses.certificates"
          :is-released="supplierResponses.isReleased"
          @certificatesUpdate="updateValueCertificates"
        ></SupplierEvaluationCertificates>
        <SupplierEvaluationContactDetails
          v-if="isContactDetailsActive"
          :contact-details="supplierResponses.contactDetails"
          :is-released="supplierResponses.isReleased"
          @contactDetailsUpdate="updateValueContactDetails"
        ></SupplierEvaluationContactDetails>
        <SupplierEvaluationRisksAndProperties
          v-if="isRisksAndPropertiesActive"
          :risks-and-properties="supplierResponses.risksAndProperties"
          :is-released="supplierResponses.isReleased"
          @risksAndPropertiesUpdate="updateValueRisksAndProperties"
        ></SupplierEvaluationRisksAndProperties>
        <SupplierEvaluationMaterialMasterData
          v-if="isMaterialMasterDataActive"
          :material-master-data="supplierResponses.materialMasterData"
          :is-released="supplierResponses.isReleased"
          @materialMasterDataUpdate="updateValueMaterialMasterData"
        ></SupplierEvaluationMaterialMasterData>
      </div>
    </div>
  </div>
</template>

<script>
import EvaluationApi from '../../services/api/evaluation';
import UserInfoStore from '../../auth/user-info-store';
import constants from '../../common/constants';
import Toast from '../../common/toast';
import SupplierEvaluationBasicInformation from '../evaluation/SupplierEvaluationBasicInformation';
import SupplierEvaluationCertificates from '../evaluation/SupplierEvaluationCertificates';
import SupplierEvaluationContactDetails from '../evaluation/SupplierEvaluationContactDetails';
import SupplierEvaluationRisksAndProperties from '../evaluation/SupplierEvaluationRisksAndProperties';
import SupplierEvaluationMaterialMasterData from '../evaluation/SupplierEvaluationMaterialMasterData';
import ViewHeader from '../common/ViewHeader';
import jq from 'jquery';

export default {
  name: 'SupplierEvaluation',
  components: {
    SupplierEvaluationBasicInformation,
    SupplierEvaluationCertificates,
    SupplierEvaluationContactDetails,
    SupplierEvaluationRisksAndProperties,
    SupplierEvaluationMaterialMasterData,
    ViewHeader
  },
  data() {
    return {
      isBasicInformationActive: false,
      isCertificatesActive: false,
      isContactDetailsActive: false,
      isRisksAndPropertiesActive: false,
      isMaterialMasterDataActive: false,
      supplierResponses: {
        isReleased: false,
        basicInformation: {},
        certificates: [],
        risksAndProperties: {},
        materialMasterData: [],
        contactDetails: {
          internalSales: {},
          internalSalesSuperior: {},
          externalSales: {},
          externalSalesSuperior: {},
          technicalContact: {},
          qualityContact: {},
          furtherContact: {},
          furtherFurtherContact: {}
        }
      }
    };
  },
  mounted() {
    this.getEvaluation();
  },
  methods: {
    showPage(showBasicInformation, showCertificates, showContactDetails, showRisksAndProperties, showMaterialMasterData) {
      (this.isBasicInformationActive = showBasicInformation),
        (this.isCertificatesActive = showCertificates),
        (this.isContactDetailsActive = showContactDetails),
        (this.isRisksAndPropertiesActive = showRisksAndProperties),
        (this.isMaterialMasterDataActive = showMaterialMasterData);
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
    updateValue(data) {
      this.supplierResponses.basicInformation = data;
    },
    updateValueCertificates(data) {
      this.supplierResponses.certificates = data;
    },
    updateValueRisksAndProperties(data) {
      this.supplierResponses.risksAndProperties = data;
    },
    updateValueContactDetails(data) {
      this.supplierResponses.contactDetails = data;
    },
    updateValueMaterialMasterData(data) {
      this.supplierResponses.materialMasterData = data;
    },
    putEvaluation() {
      EvaluationApi.putEvaluation(this.supplierResponses).then(response => {
        console.log('put successful ', response);
        this.supplierResponses = response;

        Toast.showSuccessMessage(this, this.$t(`messages.success.evaluation`));
      });
    },
    releaseEvaluation() {
      this.$bvModal
        .msgBoxConfirm(this.$t(`messages.info.confirmEvaluationRelease`), {
          cancelTitle: this.$t(`buttons.no`),
          okTitle: this.$t(`buttons.yes`)
        })
        .then(confirmed => {
          if (confirmed) {
            this.supplierResponses.isReleased = true;
            this.putEvaluation();
          }
        })
        .catch(err => {
          console.log('error confirming release', err);
        });
    },
    getEvaluation() {
      EvaluationApi.getEvaluation(this.supplierResponses).then(response => {
        console.log('get successful ', response);
        if (response) {
          this.supplierResponses.year = response.year;
          this.supplierResponses.isReleased = response.isReleased;
          if (response.basicInformation) {
            this.supplierResponses.basicInformation = response.basicInformation;
          }
          if (response.certificates) {
            this.supplierResponses.certificates = response.certificates;
          }
          if (response.risksAndProperties) {
            this.supplierResponses.risksAndProperties = response.risksAndProperties;
          }
          if (response.materialMasterData) {
            this.supplierResponses.materialMasterData = response.materialMasterData;
          }
          if (response.contactDetails) {
            this.supplierResponses.contactDetails = response.contactDetails;
            /*eslint-disable */
            this.supplierResponses.contactDetails.internalSales = response.contactDetails.internalSales ? response.contactDetails.internalSales : {};
            this.supplierResponses.contactDetails.internalSalesSuperior = response.contactDetails.internalSalesSuperior ? response.contactDetails.internalSalesSuperior : {};
            this.supplierResponses.contactDetails.externalSales = response.contactDetails.externalSales ? response.contactDetails.externalSales : {};
            this.supplierResponses.contactDetails.externalSalesSuperior = response.contactDetails.externalSalesSuperior ? response.contactDetails.externalSalesSuperior : {};
            this.supplierResponses.contactDetails.technicalContact = response.contactDetails.technicalContact ? response.contactDetails.technicalContact : {};
            this.supplierResponses.contactDetails.qualityContact = response.contactDetails.qualityContact ? response.contactDetails.qualityContact : {};
            this.supplierResponses.contactDetails.furtherContact = response.contactDetails.furtherContact ? response.contactDetails.furtherContact : {};
            this.supplierResponses.contactDetails.furtherFurtherContact = response.contactDetails.furtherFurtherContact ? response.contactDetails.furtherFurtherContact : {};
            this.supplierResponses.risksAndProperties.regions = response.risksAndProperties.regions ? response.risksAndProperties.regions : {africa: {}, asia:{}, europe:{}, china:{}, southAmerica:{}, northAmerica:{}, oceania:{}};
            this.supplierResponses.risksAndProperties.regions.africa = response.risksAndProperties.regions.africa ? response.risksAndProperties.regions.africa : {showModes: false};
            this.supplierResponses.risksAndProperties.regions.asia = response.risksAndProperties.regions.asia ? response.risksAndProperties.regions.asia : {showModes: false};
            this.supplierResponses.risksAndProperties.regions.china = response.risksAndProperties.regions.china ? response.risksAndProperties.regions.china : {showModes: false};
            this.supplierResponses.risksAndProperties.regions.europe = response.risksAndProperties.regions.europe ? response.risksAndProperties.regions.europe : {showModes: false};
            this.supplierResponses.risksAndProperties.regions.northAmerica = response.risksAndProperties.regions.northAmerica ? response.risksAndProperties.regions.northAmerica : {showModes: false};
            this.supplierResponses.risksAndProperties.regions.southAmerica = response.risksAndProperties.regions.southAmerica ? response.risksAndProperties.regions.southAmerica : {showModes: false};
            this.supplierResponses.risksAndProperties.regions.oceania = response.risksAndProperties.regions.oceania ? response.risksAndProperties.regions.oceania : {showModes: false};
            /*eslint-enable */
          }
        } else {
          this.supplierResponses.risksAndProperties.regions = {
            africa: {},
            asia: {},
            china: {},
            europe: {},
            northAmerica: {},
            southAmerica: {},
            oceania: {}
          };
        }
        this.isBasicInformationActive = true;
      });
    }
  }
};
</script>

<style scoped>
.vertical-line {
  border-right: 1px solid #ccc;
}

.rest-height {
  height: calc(100% - (80px + 54px));
}

.full-height {
  height: 100%;
  white-space: nowrap;
  overflow-y: auto;
  overflow-x: hidden;
}

.no-padding-left {
  padding-left: 0;
}

.no-padding-right {
  padding-right: 0;
}

.margin-right {
  margin-right: 15px;
}

.color-red {
  color: red;
}
</style>
