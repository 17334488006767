import axios from 'axios';
import AxiosDefaults from './axios-defaults';

export default {
  putEvaluation(evaluation) {
    return axios.put('/evaluation', evaluation, AxiosDefaults.requestConfig).then(response => response.data);
  },
  getEvaluation() {
    return axios.get('/evaluation', AxiosDefaults.requestConfig).then(response => response.data);
  }
};
