<template>
  <div class="container-fluid">
    <ViewHeader :title="$t(`headers.documents`)"></ViewHeader>
    <DocumentUpload ref="documentUpload" @documentUploaded="addFileToList" />
    <DocumentDetails v-if="selectedFiles && selectedFiles.length > 0" :document="selectedFiles[0]" />
    <DocumentViewer :document-link="viewerLink" />
    <div class="input-group mb-3">
      <input v-model="searchString" class="form-control" type="text" :placeholder="$t(`form.search`)" />
    </div>
    <div class="btn-group">
      <span class="float-left mb-3">
        <button type="button" class="btn btn-primary mr-1" @click="$bvModal.show('modal-upload')">
          <i class="far fa-arrow-to-top"></i>
          {{ $t(`buttons.upload`) }}
        </button>
        <DocumentDownloadButton class="mr-1" :files="selectedFiles"> </DocumentDownloadButton>
        <button type="button" :disabled="disableShowButton" class="btn btn-primary mr-1" @click="showFile">
          <i class="far fa-eye"></i>
          {{ $t(`buttons.show`) }}
        </button>
        <button
          type="button"
          :disabled="!selectedFiles || selectedFiles.length !== 1"
          class="btn btn-primary mr-1"
          @click="showDocumentDetails"
        >
          <i class="far fa-file-alt"></i>
          {{ $t(`buttons.properties`) }}
        </button>
        <button type="button" :disabled="disableDeleteButton" class="btn btn-danger mr-1" @click="deleteSelectedDocuments">
          <i class="far fa-trash-alt"></i>
          {{ $t(`buttons.delete`) }}
        </button>
      </span>
    </div>
    <DocumentTable :show-spinner="isFetchingSuppliers" :files="filteredFiles"></DocumentTable>
  </div>
</template>

<script>
import DocumentApi from '../../services/api/document';
import DocumentUpload from './DocumentUpload';
import Utils from '../../common/utils';
import Toast from '../../common/toast';
import DocumentPropertyApi from '../../services/api/documentProperty';
import i18n from '../../i18n/i18n';
import ViewHeader from '../common/ViewHeader.vue';
import DocumentTable from './DocumentTable.vue';
import DocumentDownloadButton from './DocumentDownloadButton.vue';
import DocumentViewer from './DocumentViewer.vue';
import DocumentDetails from '../document/DocumentDetails.vue';

export default {
  name: 'DocumentList',
  components: {
    DocumentUpload,
    DocumentDetails,
    ViewHeader,
    DocumentTable,
    DocumentDownloadButton,
    DocumentViewer
  },
  props: {
    selectedId: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      file: '',
      files: [],
      currentUser: {},
      searchString: '',
      isFetchingSuppliers: false,
      viewerLink: ''
    };
  },
  beforeRouteLeave(to, from, next) {
    if (!this.$refs.documentUpload.disableCloseButton) {
      next();
    } else {
      this.$bvModal
        .msgBoxConfirm(this.$t(`messages.info.confirmLeavePage`), {
          cancelTitle: this.$t(`buttons.no`),
          okTitle: this.$t(`buttons.yes`)
        })
        .then(confirmed => {
          if (confirmed) {
            next();
          }
        })
        .catch(err => {
          console.log('error confirming leaving page', err);
        });
    }
  },
  computed: {
    filteredFiles: function() {
      let searchStrings = this.searchString
        .toLowerCase()
        .trim()
        .split(' ');

      return this.files.filter(file => {
        for (let index = 0; index < searchStrings.length; index++) {
          const searchPart = searchStrings[index];

          let fileFound =
            Utils.containsString(file.title, searchPart) ||
            Utils.containsString(file.documentTypeName, searchPart) ||
            Utils.containsString(file.fileName, searchPart) ||
            Utils.containsString(new Date(file.documentDate).toLocaleString(), searchPart);
          if (!fileFound) return false;
        }
        return true;
      });
    },
    selectedFiles: function() {
      let selectedFiles = [];
      this.filteredFiles.forEach(file => {
        if (file && file.isSelected && file.id) {
          selectedFiles.push(file);
        }
      });
      return selectedFiles;
    },

    disableShowButton: function() {
      if (this.selectedFiles.length !== 1) {
        return true;
      }

      if (this.selectedFiles[0].contentType === 'application/pdf') {
        return false;
      }

      return true;
    },
    disableDeleteButton: function() {
      if (this.selectedFiles.length === 0) {
        return true;
      }

      return false;
    }
  },
  mounted() {
    this.fetchFiles();
  },
  methods: {
    fetchFiles() {
      if (this.isFetchingSuppliers) return;

      this.isFetchingSuppliers = true;
      this.files = [];

      DocumentApi.getDocuments()
        .then(response => {
          console.log('fetch suppliers successful');
          response.result.forEach(file => {
            this.addFileToList(file);
          });
          this.isFetchingSuppliers = false;
        })
        .catch(error => {
          console.log(error);
          this.isFetchingSuppliers = false;
        });
    },
    showFile() {
      if (!this.selectedFiles || this.selectedFiles.length !== 1) {
        return;
      }

      DocumentApi.getFileEmbeddedLink(this.selectedFiles[0].id)
        .then(response => {
          console.log('show response: ', response);
          this.viewerLink = response.url;
          this.$modal.show('modal-show-document');
        })
        .catch(error => {
          console.log('error getting download link: ', error);
          Toast.showErrorMessage(this, this.$t(`messages.error.download`));
        });
    },
    showDocumentDetails() {
      if (!this.selectedFiles || this.selectedFiles.length !== 1) {
        Toast.showErrorMessage(this, this.$t(`messages.error.multipleFiles`));
        return;
      }
      this.$bvModal.show('modal-document-details');
    },
    deleteSelectedDocuments() {
      this.$bvModal
        .msgBoxConfirm(this.$t(`messages.info.confirmDeleteDocument`), {
          cancelTitle: this.$t(`buttons.no`),
          okTitle: this.$t(`buttons.yes`)
        })
        .then(confirmed => {
          if (confirmed) {
            this.selectedFiles.forEach(file => {
              this.deleteDocument(file.id);
            });
          }
        })
        .catch(err => {
          console.log('error confirming deletion', err);
        });
    },
    deleteDocument(fileId, supplierId) {
      DocumentApi.deleteDocument(fileId, supplierId)
        .then(response => {
          console.log('success deleting document: ', response);
          Toast.showSuccessMessage(this, this.$t(`messages.success.delete`));
          this.fetchFiles();
        })
        .catch(error => {
          console.log('error deleting document: ', error);
          Toast.showErrorMessage(this, this.$t(`messages.error.delete`));
        });
    },
    addFileToList(file) {
      console.log('Add file: ', file);
      file.imageClass = Utils.getImageForFileName(file.fileName);

      if (file.id === this.selectedId) {
        file.isSelected = true;
      } else {
        file.isSelected = false;
      }

      this.files.push(file);
    }
  }
};
</script>
