const en = {
  links: {
    home: 'Home',
    logout: 'Sign out',
    login: 'Sign in',
    documents: 'Show documents',
    documentProperties: 'Manage document properties',
    secure: 'Show secure',
    subscribe: 'Subscribe',
    upload: 'Upload file',
    suppliers: 'Manage suppliers',
    users: 'Manage users',
    defineDocuments: 'Manage document types',
    supplierEvaluation: 'Supplier self-disclosure',
    supplierEvaluationBasicInformation: 'Basic Information',
    supplierEvaluationContactDetails: 'Contact Details',
    supplierEvaluationRisksAndProperties: 'Risks and Properties',
    supplierEvaluationMaterialMasterData: 'Material master data',
    supplierEvaluationCertificates: 'Certificates',
    barcode: 'Create barcodes',
    orders: 'Show orders',
    globalConfiguration: 'Global Configurations'
  },
  messages: {
    error: {
      multipleFiles: 'You may only select one File',
      getting: 'An error occured while getting Properties',
      forbidden: 'You are not allowed to see this page',
      pageNotFound: 'The requested page could not be found',
      uploading: 'An error occured while uploading. ',
      tryAgain: 'Please try again or contact your administrator if the error remains.',
      download: 'An error occured while downloading.',
      missingFileName: 'Missing file name',
      atLeastOneGroup: 'Select at least one role',
      delete: 'An error occurred while deleting',
      emptyFile: 'Empty files cannot be uploaded.',
      save: 'An error occured while saving.',
      stillInUse: 'The item is in use and therefore may not be deleted.',
      invalidEmail: "The user's email address is invalid. Therefore the user cannot be enabled."
    },
    info: {
      createDocumentPropertyHeader: 'New document properties',
      documentPropertyTitel: 'Titel',
      homeLatestDocuments: 'Latest documents',
      homeWelcome: 'Welcome to our supplier portal',
      welcomeHeader: 'Welcome to our supplier portal',
      welcomeLogin: 'Please sign in here:',
      subscription: 'Subscribe our cool web service!',
      uploadFile: 'Select the files you want to upload',
      selectFile: 'Select files or drop them here...',
      selectDocType: 'Select document type...',
      noFiles: 'There are no files, yet',
      noFilesFound: 'No files found',
      noDocumentPropertiesFound: 'No document properties found',
      noDocumentTypesFound: 'No document types defined',
      confirmDownload: 'Download all {count} files?',
      noSuppliersFound: 'No suppliers found',
      hoverButtonAdd: 'Add column',
      confirmDeleteSupplier: 'Do you really want to delete this supplier?',
      noUsersFound: 'No users found',
      confirmDeleteUser: 'Do you really want to delete this user?',
      confirmDeleteDocument: 'Do you really want to delete this document(s)?',
      confirmDeleteDocumentType: 'Do you really want to delete this document type?',
      noOrdersFound: 'No orders found',
      overdueDocuments: 'Overdue documents',
      supplierDeletionWarning: 'Your account is marked for deletion. Your files and users will ultimately deleted on {date}.',
      supplierMarkedForDeletion: 'This supplier is marked for deletion and will be ultimately deleted on {date}.',
      requiredField: 'Required field',
      uploadStatus: 'Upload status',
      confirmEnableContact: 'Do you want to enable this contact as a user for the supplier portal?',
      confirmDisableContact: 'Do you want to disable this contact as a user for the supplier portal?',
      contactDeleted: 'This user is marked for deletion and will be removed entirely on {date}.',
      contactEnabled: 'This user is enabled for the supplier portal.',
      confirmEvaluationRelease: 'Do you really want to release the supplier self-disclosure? After that the values cannot be updated.',
      showingLastYearsEvaluation: 'No values have been saved for current year. The shown values are taken from the disclosure from {year}.',
      confirmLeavePage: 'There are still unsaved changes. Do you really want to leave this page?'
    },
    success: {
      logout: 'Logout successful',
      upload: 'File uploaded successfully',
      saveSupplier: 'Supplier saved successfully',
      saveUser: 'User saved successfully',
      saveDocumentProperty: 'Document property saved successfully',
      delete: 'Deleted successfully',
      documentType: 'Document type saved successfully',
      evaluation: 'Self-disclosure uploaded successfully',
      enableUser: 'User enabled successfully',
      disableUser: 'User disabled successfully'
    }
  },
  questions: {
    basicInformation: {
      pleaseChoose: 'Please choose an option',
      corporateName: 'Corporate Name',
      address: 'Adress',
      addressStreet: 'Street',
      addressCity: 'City',
      addressZip: 'Zip',
      legalFormOfCorporate: 'Legal form of corporate',
      dunsNumber: 'DUNS-Number',
      industrialSector: 'Industrial sector',
      naceCode: 'NACE code',
      areaOfActivity: 'Area of activity',
      areaOfActivityOption1: 'Producer',
      areaOfActivityOption2: 'Service Producer',
      areaOfActivityOption3: 'Trader resp. Distributor',
      partOfCompanyGroup: 'Part of company group',
      partOfCompanyGroupOption1: 'Yes',
      partOfCompanyGroupOption2: 'No',
      employeesTotal: 'Employees: Total',
      employeesRDQA: 'Employees: Consisting of R&D / QA',
      employeesProduction: 'Employees: Consisting of Production',
      employeesAdministration: 'Employees: Consisting of Administration',
      turnoverLastYear: 'Turnover in the last business year',
      ourCustomerNumber: 'Our Customer number',
      yourSupplierNumber: 'Your supplier number with VSM',
      packagingGuaranteedBy: 'Transportation and sales packaging as defined by the Packaging Ordinance is guaranteed by',
      packagingGuaranteedByOption1: 'The transport and sales packaging is taken back using a licensed recycling system.',
      packagingGuaranteedByOption2: 'We fetch our packaging ourselves.',
      packagingGuaranteedByOption3: 'We issue a credit note for disposal purposes.',
      packagingGuaranteedByOption4: 'We use disposal packaging for ALL delivered goods.',
      recyclingSystemInformation:
        // eslint-disable-next-line max-len
        'Please provide more specific information on the recycling system, the amount of the credit note you issue for disposal purposes or any other comment concerning the field of disposal',
      existingCertifications: 'Existing certifications',
      existingCertificationsKindOfCertification: 'Kind of certification',
      existingCertificationsNumber: 'Number',
      existingCertificationsRevision: 'Revision',
      existingCertificationsTopic: 'Topic',
      existingCertificationsValidUntil: 'Certification valid until'
    },
    contactDetails: {
      name: 'Company name',
      companyAddress: 'Company address',
      address: 'Address',
      phone: 'Telephone (switchboard)',
      email: 'Email',
      fax: 'Fax',
      firstLastName: 'First, last name',
      processingOfOrders: 'Processing of orders: preferably organisational email adress',
      generalData: 'General data/ Availabillities',
      commercialContact: 'Commercial contact',
      internalSales: 'Internal sales',
      superior: 'Superior',
      fieldSales: 'Field sales',
      technicalQualityRelatedContact: 'Technical/ Quality related contact person',
      technicalContact: 'Technical contact person',
      qualityRelatedContact: 'Quality related contact person',
      furtherContactPersons: 'Further contact persons Availabillities',
      furtherContactPersonsBusiness: 'Business',
      emailContacts: 'Contact Email addresses',
      emailSafetyDataSheets: 'E-mail address for the query of safety data sheets',
      emailTechnicalDataSheets: 'E-mail address for the request of technical data sheets',
      emailVendorDeclarations: 'E-mail address for inquiring (long-term) supplier declarations (if the supplier is from Europe)',
      emailIso: 'Email address for querying ISO certificates',
      emailEndUse: 'E-mail address for the query of final declarations',
      takeContactValues: 'Take contact information',
      selectContact: 'Select contact'
    },
    risksAndProperties: {
      deptRepaymentCapacity: 'What is your dept repayment capacity?',
      deptRepaymentCapacityOption1: 'less than three years',
      deptRepaymentCapacityOption2: 'between three and five years',
      deptRepaymentCapacityOption3: 'between five and seven years',
      deptRepaymentCapacityOption4: 'between seven and nice years',
      deptRepaymentCapacityOption5: 'between nine and 11 years',
      deptRepaymentCapacityOption6: 'longer than 11 years',
      equityRatio: 'What was your equity ratio in the last financial year?',
      productLiabilityInsurance: 'What is the covering of your product liability insurance?',
      productLiabilityInsuranceOption1: 'Covering larger or equal EUR 5 million a claim AND larger or equal EUR 10 million a year',
      productLiabilityInsuranceOption2: 'Covering smaller than EUR 5 million a claim OR EUR 10 million a year',
      productLiabilityInsuranceOption3: 'No product liability insurance maintained',
      thirdPartyLiabilityInsurance: 'What is the covering of your commercial third party liability insurance?',
      thirdPartyLiabilityInsuranceOption1: 'Covering larger or equal EUR 5 million a claim AND larger or equal EUR 10 million a year',
      thirdPartyLiabilityInsuranceOption2: 'Covering smaller than EUR 5 million a claim OR EUR 10 million a year',
      thirdPartyLiabilityInsuranceOption3: 'No product liability insurance maintained',
      totalSales: 'Is one of your customers responsible for a larger share of relevant total sales than 15% (business unit or coporation)?',
      totalSalesOption1: 'Yes',
      totalSalesOption2: 'No',
      totalSaleShare: 'What is the share of total sales in your main sector?',
      totalSaleShareOption1: 'significantly below 30%',
      totalSaleShareOption2: 'less than 30%',
      totalSaleShareOption3: 'greater than 30%',
      totalSaleShareOption4: 'greater than 50%',
      // eslint-disable-next-line max-len
      regionOfGoodsProduced:
        // eslint-disable-next-line max-len
        'In which region do you produce the goods delivered to VSM AG (please choose multiple (please choose multiple regions if necessary)?',
      regionOfGoodsProducedOption1: 'Africa',
      regionOfGoodsProducedOption2: 'Asia (excl. China)',
      regionOfGoodsProducedOption3: 'China',
      regionOfGoodsProducedOption4: 'Europe (geographically)',
      regionOfGoodsProducedOption5: 'North America',
      regionOfGoodsProducedOption6: 'Oceania and Australia',
      regionOfGoodsProducedOption7: 'South America',
      modesOfTransport:
        // eslint-disable-next-line max-len
        'How many modes of transport do you usually use when delivering from the above mentioned production facility (please also give multiple answers if necessary?)',
      modesOfTransportOption1: '1',
      modesOfTransportOption2: '2',
      modesOfTransportOption3: '3',
      modesOfTransportOption4: '4',
      durationOfDelivery:
        // eslint-disable-next-line max-len
        'How long does it take to deliver goods from your dispatching location (only logistic lead time: loading the vessel until delivery at VSM AG)?',
      durationOfDeliveryOption1: 'Possible delivery within one day',
      durationOfDeliveryOption2: 'Possible delivery within three days',
      durationOfDeliveryOption3: 'Delivery takes longer than three days',
      averageStockKeeping: 'How is your average stock-keeping in days compared to your replenishment time for the respective item?',
      averageStockKeepingOption1: 'Stock-keeping is equal or higher than twice the replenishment time',
      averageStockKeepingOption2: 'Stock-keeping equals the replenishment time',
      averageStockKeepingOption3: 'Stock-keeping is smaller than the replenishment time',
      internalGuidelines: 'Did you set up internal guidelines concerning your employees compliance?',
      internalGuidelinesOption1: 'There are internal guidelines',
      internalGuidelinesOption2: 'There are no internal guidelines, but the employees are regularly sensitized',
      internalGuidelinesOption3: 'There are no internal guidelines',
      complianceEnsurance: 'How do you ensure the compliance to rules and laws of your suppliers?',
      complianceEnsuranceOption1: 'Our supplier compliance is checked during our own audits',
      complianceEnsuranceOption2: 'The suppliers declare their compliance',
      complianceEnsuranceOption3: 'This requirement is part of our general terms and conditions',
      complianceEnsuranceOption4: 'There are no such requirements towards our suppliers',
      measuresToCompensateOutage:
        'By which measures are you able to compensate an outage of either your production or distribution location?',
      developmentDeliveredGoods: 'What possible development concerning the delivered goods do you anticipate for the future?',
      supplyChain: 'Which steps along your supply chain did you generally take?',
      businessJudging: 'When judging our common business, where do you see possible new ways, products or insights to deepen our business?',
      risks: 'What risks for your business do you see on a mid-term forecast?',
      anythingElse: 'Is there anything else that you would like us to know?'
    },
    materialMasterData: {
      vsmItem: 'VSM Item',
      vsmItemDescripton: 'VSM Item description',
      itemId: 'Your Item ID',
      yourItemDescription: 'Your Item description'
    }
  },
  cognitoGroups: {
    administrator: 'Administrator',
    clerk: 'Clerk',
    supplier: 'Supplier'
  },
  form: {
    firstName: 'First name',
    files: 'Files',
    lastName: 'Last name',
    emailAddress: 'E-mail address',
    comment: 'Comment',
    fileName: 'File name',
    lastModified: 'Last modified',
    selectconfigurationForExport: 'Select document types for export',
    size: 'Size',
    search: 'Search...',
    date: 'Date',
    description: 'Description',
    translation: 'Translation',
    documentNumber: 'Document number',
    translation: 'Translation',
    createDocumentPropertyHeader: 'New document property',
    documentPropertyTitel: 'Title',
    documentProperty: 'Property',
    documentType: 'Document type',
    orderNumber: 'Order number',
    supplier: 'Supplier',
    supplierEvaluation: 'Supplier self-disclosure',
    exportConfiguration: 'Export configuration',
    importConfiguration: 'Einstellungen importieren',
    supplierName: 'Supplier name',
    supplierNumber: 'Supplier number',
    supplierId: 'Supplier ID',
    tenantNumber: 'Tenant',
    documentDate: 'Document date',
    group: 'Role',
    groups: 'Roles',
    groupSelectedLabel: 'Selected',
    users: 'Users',
    title: 'Title',
    confirmationDate: 'Confirmation date',
    name: 'Name',
    positionNumber: 'Pos. no.',
    materialNumber: 'Material no.',
    supplierMaterialNumber: 'Supplier material no.',
    materialDescription: 'Material description',
    lotNumber: 'Lot number',
    weight: 'Weight',
    deadline: 'Review date',
    reason: 'Reason',
    materialDescription: 'Material name',
    documentCategory: 'Document category',
    showSupplierEvaluation: 'Show supplier self-disclosure',
    exportEvaluationConfig: 'Export configuration for supplier self-disclosure',
    emailShort: 'Email: ',
    phoneShort: 'Phone: ',
    propertyTypeAlphanumeric: 'Alphanumeric',
    propertyTypeNumeric: 'Numeric',
    propertyTypeDate: 'Date',
    type: 'Typ',
    foundIn: 'Found in',
    orderNumberLong: 'Long order number'
  },
  buttons: {
    subscribe: 'Subscribe',
    delete: 'Delete',
    download: 'Download',
    upload: 'Upload',
    cancel: 'Cancel',
    show: 'Show',
    add: 'Add',
    save: 'Save',
    back: 'Back',
    edit: 'Edit',
    yes: 'Yes',
    no: 'No',
    send: 'Send',
    barcode: 'Generate barcode',
    barcodes: 'Generate barcodes',
    print: 'Print',
    showDetails: 'Show Details',
    selectAll: 'Select all',
    clearAll: 'Clear all',
    next: 'Next',
    close: 'Close',
    retry: 'Retry',
    exportAllDocumentTypes: 'Export all document types',
    exportSelectedDocumentTypes: 'Export document types',
    export: 'Export',
    properties: 'Properties',
    enable: 'Enable',
    disable: 'Disable',
    release: 'Release'
  },
  headers: {
    documents: 'Documents',
    documentProperties: 'Document Properties',
    documentTypes: 'Document Types',
    tabEvaluation: 'Supplier Self-disclosure',
    tabDocumentTypes: 'Document Types',
    error: 'Error',
    success: 'Success',
    suppliers: 'Suppliers',
    supplierDetails: 'Supplier',
    users: 'Users',
    userDetails: 'User',
    orders: 'Orders',
    tabBasic: 'Basic Information',
    tabProperties: 'Properties',
    tabMandatory: 'Mandatory Properties',
    availableProps: 'Available Properties',
    selectedProps: 'Selected Properties',
    availableDefaultHeader: 'Available Values',
    selectedDefaultHeader: 'Selected Values',
    mandatoryAndOrder: 'Define required fields and their order',
    globalConfiguration: 'Global Configuration',
    availableDocumentTypes: 'Available Document Types',
    selectedDocumentTypes: 'Selected Document Types',
    evaluation: 'Supplier self-disclosure',
    basicInformation: 'Basic Information',
    risksAndProperties: 'Risks and Properties',
    materialMasterData: 'Material Master Data'
  },
  // Dies sind die overrides für VeeValidate Error messages
  validation: {
    required: '{_field_} is required',
    hasFileExtension: 'Please provide a file extension',
    email: 'Please provide a valid e-mail address',
    max: 'Maximum {max} characters are allowed',
    numeric: 'Only numeric values are allowed.'
  }
};

export default en;
