<template>
  <div class="container-fluid text-center">
    <h2>{{ $t(`messages.error.pageNotFound`) }}</h2>
    <router-link to="/">
      {{ $t(`links.home`) }}
    </router-link>
  </div>
</template>
<script>
export default {
  mounted() {}
};
</script>
