import Vue from 'vue';
import Router from 'vue-router';
// import cognito from './auth/cognito';
import UserInfoStore from './auth/user-info-store';
import Constants from './common/constants';
import NotFound from './components/navigation/NotFound.vue';
import Forbidden from './components/navigation/Forbidden.vue';
import ErrorComponent from './components/navigation/Error.vue';
import DocumentList from './components/document/DocumentList.vue';
import SupplierList from './components/supplier/SupplierList.vue';
import UserList from './components/user/UserList.vue';
import SupplierEvaluation from './components/evaluation/SupplierEvaluation.vue';
import DocumentTypeList from './components/documentType/DocumentTypeList.vue';
import SupplierDetails from './components/supplier/SupplierDetails.vue';
import UserDetails from './components/user/UserDetails.vue';
import OrderList from './components/order/OrderList.vue';
import OrderPositions from './components/order/OrderPositions.vue';
import Home from './components/navigation/Home.vue';
import Welcome from './components/navigation/Welcome.vue';
import Barcode from './components/barcode/Barcode.vue';
import GlobalConfiguration from './components/configuration/GlobalConfiguration.vue';
import DocumentProperty from './components/documentProperty/DocumentPropertyList.vue';
import MsalAB2C from './auth/MsalAB2C';

Vue.use(Router);

function requireAuth(to, from, next) {
  console.log('requireauth');
  if (MsalAB2C.AppAuthProvider.getAccount() != null) {
    console.log('requireAuth: User is signed in');
    MsalAB2C.getUserInfo().then(
      response => {
        UserInfoStore.setLoggedIn(true);
        console.log('User signed in successfully: ', response);
        UserInfoStore.setAzureInfo(response);
        Vue.prototype.$eventHub.$emit('logged-in');
        proceed(to, from, next);
      },
      err => {
        console.log('User not signed in: ', err);
      }
    );
  } else {
    console.log('requireAuth: User is NOT signed in');
    MsalAB2C.AppAuthProvider.loadAuthModule();
    UserInfoStore.setLoggedIn(false);
    next({
      path: '/login',
      query: { redirect: to.fullPath }
    });
  }
}

function proceed(to, from, next) {
  console.log('UserInfoStore: ', UserInfoStore);
  if (UserInfoStore.state.azureInfo) {
    if (to.meta.group) {
      if (UserInfoStore.state.isInGroup(to.meta.group)) {
        next();
      } else {
        next('/forbidden');
      }
    } else {
      next();
    }
  } else {
    next('/forbidden');
  }
}
const loginRequest = {
  scopes: ['openid', 'email', 'profile']
};
export default new Router({
  mode: 'history',
  base: '/',
  routes: [
    { path: '/404', name: 'notFound', component: NotFound },
    { path: '*', redirect: '/404' },
    { path: '/forbidden', name: 'forbidden', component: Forbidden },
    {
      path: '/',
      name: 'welcome',
      component: Welcome,
      beforeEnter(_to, _from, next) {
        MsalAB2C.AppAuthProvider.loadAuthModule();

        if (MsalAB2C.AppAuthProvider.getAccount() != null) {
          next('/home');
        } else {
          console.log('loadAuthmodule after init');
          next();
        }
      }
    },
    {
      path: '/home',
      name: 'home',
      component: Home,
      beforeEnter: requireAuth
    },
    {
      path: '/firstSignIn',
      name: 'firstSignIn',
      beforeEnter(_to, _from, next) {
        console.log('IsFirstSignIn?');
        MsalAB2C.AppAuthProvider.MSALProvider.handleRedirectPromise()
          .then(redirectResponse => {
            console.log('Redirect Response from ResetPassword', redirectResponse);
            if (!redirectResponse) {
              MsalAB2C.AppAuthProvider.resetPassword(window.location.origin);
            } else {
              MsalAB2C.AppAuthProvider.MSALProvider.logout().then(() => {
                next('/login');
              });
            }
          })
          .catch(error => {
            console.error(error);
            MsalAB2C.AppAuthProvider.loadAuthModule();
          });

        //if (error.message.indexOf('AADB2C90118') > -1) {
        //  MsalAB2C.AppAuthProvider.MSALProvider.logout().then(() => {
        //    next('login');
        //  });
        //}
      }
    },
    {
      path: '/login',
      name: 'login',
      beforeEnter(_to, _from, _next) {
        console.log('beforeEnter login');
        MsalAB2C.AppAuthProvider.login('loginRedirect').then(resp => {
          console.log(resp);
        });
      }
    },
    // {
    //   path: '/callback',
    //   name: 'callback',
    //   beforeEnter(_to, _from, _next) {
    //     const currUrl = window.location.href;
    //     console.log('callback');
    //     cognito.auth.parseCognitoWebResponse(currUrl);
    //   }
    // },
    {
      path: '/logout',
      name: 'logout',
      beforeEnter(to, from, next) {
        MsalAB2C.logout();
        next('/');
      }
    },
    {
      path: '/error',
      name: 'error',
      component: ErrorComponent
    },
    {
      path: '/documents',
      name: 'documents',
      component: DocumentList,
      beforeEnter: requireAuth,
      meta: {
        group: Constants.supplierGroup
      },
      props: route => ({ selectedId: route.query.selectedId })
    },
    {
      path: '/orders',
      name: 'orders',
      component: OrderList,
      beforeEnter: requireAuth,
      meta: {
        group: Constants.supplierGroup
      }
    },
    {
      path: '/orders/:id',
      name: 'orderpositions',
      component: OrderPositions,
      beforeEnter: requireAuth,
      meta: {
        group: Constants.supplierGroup
      },
      props: true
    },
    {
      path: '/barcode/:id/:pos',
      name: 'barcode',
      component: Barcode,
      beforeEnter: requireAuth,
      meta: {
        group: Constants.supplierGroup
      },
      props: route => ({ lotNumber: route.query.lotNumber, weight: route.query.weight, id: route.params.id, pos: route.params.pos })
    },
    {
      path: '/suppliers',
      name: 'suppliers',
      component: SupplierList,
      beforeEnter: requireAuth,
      meta: {
        group: Constants.clerkGroup
      }
    },
    {
      path: '/suppliers/:id',
      name: 'supplierdetails',
      component: SupplierDetails,
      beforeEnter: requireAuth,
      meta: {
        group: Constants.clerkGroup
      },
      props: true
    },
    {
      path: '/users',
      name: 'users',
      component: UserList,
      beforeEnter: requireAuth,
      meta: {
        group: Constants.adminGroup
      }
    },
    {
      path: '/users/:id',
      name: 'userdetails',
      component: UserDetails,
      beforeEnter: requireAuth,
      meta: {
        group: Constants.adminGroup
      },
      props: true
    },
    {
      path: '/suppliers/:supplierId/users/:id',
      name: 'supplieruserdetails',
      component: UserDetails,
      beforeEnter: requireAuth,
      meta: {
        group: Constants.adminGroup
      },
      props: true
    },
    {
      path: '/documentTypes',
      name: 'documentTypeList',
      component: DocumentTypeList,
      beforeEnter: requireAuth,
      meta: {
        group: Constants.adminGroup
      }
    },
    {
      path: '/documentProperties',
      name: 'documentProperties',
      component: DocumentProperty,
      beforeEnter: requireAuth,
      meta: {
        group: Constants.adminGroup
      }
    },
    {
      path: '/globalConfiguration',
      name: 'globalConfiguration',
      component: GlobalConfiguration,
      beforeEnter: requireAuth,
      meta: {
        group: Constants.adminGroup
      }
    },
    {
      path: '/evaluation',
      name: 'supplierevaluation',
      component: SupplierEvaluation,
      beforeEnter: requireAuth,
      meta: {
        group: Constants.supplierGroup
      }
    }
  ]
});
