<template>
  <ValidationObserver v-slot="{ valid }" class="container-fluid">
    <ViewHeader :title="$t(`headers.supplierDetails`)"></ViewHeader>
    <div class="row">
      <div class="col-12">
        <div class="btn-group mb-3">
          <button type="button" class="btn btn-primary mr-2" @click="navigateToList">
            <i class="far fa-chevron-left"></i>
            {{ $t(`buttons.back`) }}
          </button>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="col-9">
        <div class="form-row">
          <div class="col-6">
            <div class="form-group">
              <label for="orderNumber">{{ $t(`form.orderNumber`) }}</label>
              <input id="orderNumber" :readonly="true" :value="order.orderNumber" type="text" class="form-control" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <hr />
    <div v-if="!showSpinner" class="row mt-1">
      <table class="table">
        <thead>
          <tr>
            <th scope="col" style="width: 5%">
              {{ $t(`form.positionNumber`) }}
            </th>
            <th scope="col" style="width: 15%">
              {{ $t(`form.materialNumber`) }}
            </th>
            <th scope="col" style="width: 15%">
              {{ $t(`form.supplierMaterialNumber`) }}
            </th>
            <th scope="col" style="width: 20%">
              {{ $t(`form.materialDescription`) }}
            </th>
            <th scope="col" style="width: 20%">
              {{ $t(`form.lotNumber`) }}
            </th>
            <th scope="col" style="width: 20%">
              {{ $t(`form.weight`) }}
            </th>
            <th scope="col" style="width: 5%"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="position in order.orderPositions" :key="position.positionNumber">
            <td>
              {{ position.positionNumber }}
            </td>
            <td>
              {{ position.materialNumber }}
            </td>
            <td>
              {{ position.supplierMaterialNumber }}
            </td>
            <td>
              {{ position.materialDescription }}
            </td>
            <td>
              <ValidationProvider v-slot="{ errors, classes }" :name="$t(`form.lotNumber`)" rules="required|max:25">
                <CustomInput
                  id="lotNumber"
                  v-model="position.lotNumber"
                  :classes="classes"
                  :is-mandatory="true"
                  :errors="errors"
                ></CustomInput>
              </ValidationProvider>
            </td>
            <td>
              <ValidationProvider v-slot="{ errors, classes }" :name="$t(`form.weight`)" rules="required|max:25">
                <CustomInput id="weight" v-model="position.weight" :classes="classes" :is-mandatory="true" :errors="errors"></CustomInput>
              </ValidationProvider>
            </td>

            <td>
              <button
                :class="valid ? 'activeBtn btn' : 'btn'"
                :title="$t(`buttons.barcode`)"
                type="button"
                :disabled="!valid"
                @click="showBarcode(position)"
              >
                <i class="far fa-barcode-read fa-2x"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <spinner :show-spinner="showSpinner" />
  </ValidationObserver>
</template>

<script>
import ViewHeader from '../common/ViewHeader';
import OrderApi from '../../services/api/order';
import router from '../../router';
import Toast from '../../common/toast';
import Vue from 'vue';
import Spinner from '../common/Spinner';
import CustomInput from '../common/CustomInput.vue';

export default {
  name: 'OrderPositions',
  components: {
    ViewHeader,
    Spinner,
    CustomInput
  },
  props: {
    id: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      order: {},
      showSpinner: false
    };
  },
  mounted() {
    this.fetchOrder();
  },
  methods: {
    fetchOrder() {
      this.showSpinner = true;

      OrderApi.getOrder(this.id)
        .then(response => {
          console.log('fetch successful: ', response);
          this.order = response;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.showSpinner = false;
        });
    },
    navigateToList() {
      router.push({ name: 'orders' });
    },
    showBarcode(position) {
      router.push({
        name: 'barcode',
        params: { id: this.order.id, pos: position.positionNumber },
        query: { lotNumber: position.lotNumber, weight: position.weight }
      });
    }
  }
};
</script>

<style scoped>
.table > tbody > tr > td {
  vertical-align: baseline;
}
.activeBtn:hover {
  color: var(--secondary);
}
</style>
