var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('ViewHeader',{attrs:{"title":_vm.$t("headers.userDetails")}}),_c('ValidationObserver',{ref:"userObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var dirty = ref.dirty;
return [_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"btn-group mb-3"},[_c('button',{staticClass:"btn btn-primary mr-2",attrs:{"type":"button"},on:{"click":_vm.navigateToList}},[_c('i',{staticClass:"far fa-chevron-left"}),_vm._v(" "+_vm._s(_vm.$t("buttons.back"))+" ")]),_c('button',{staticClass:"btn btn-primary mr-2",attrs:{"type":"button","disabled":!valid || (!_vm.isNew && !dirty)},on:{"click":_vm.save}},[_c('i',{staticClass:"far fa-save"}),_vm._v(" "+_vm._s(_vm.$t("buttons.save"))+" ")]),(!_vm.isNew)?_c('button',{staticClass:"btn btn-danger",attrs:{"type":"button"},on:{"click":_vm.deleteUser}},[_c('i',{staticClass:"far fa-trash-alt"}),_vm._v(" "+_vm._s(_vm.$t("buttons.delete"))+" ")]):_vm._e()])])]),(!_vm.loadingUser)?_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-9"},[_c('ValidationProvider',{attrs:{"name":_vm.$t("form.emailAddress"),"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('div',{staticClass:"form-group "},[_c('label',{attrs:{"for":"emailAddress"}},[_vm._v(_vm._s(_vm.$t("form.emailAddress")))]),_c('CustomInput',{attrs:{"id":"emailAddress","readonly":!_vm.isNew,"classes":classes,"is-mandatory":true,"errors":errors},model:{value:(_vm.user.emailAddress),callback:function ($$v) {_vm.$set(_vm.user, "emailAddress", $$v)},expression:"user.emailAddress"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"typo__label"},[_vm._v(_vm._s(_vm.$t("form.groups")))]),_c('div',{staticClass:"input-group"},[_c('multiselect',{staticClass:"multi-align",class:errors[0] ? 'invalid-groups' : '',attrs:{"label":"description","select-label":"","placeholder":"","selected-label":_vm.$t("form.groupSelectedLabel"),"deselect-label":_vm.$t("buttons.delete"),"track-by":"groupName","options":_vm.groups,"multiple":true,"hide-selected":true,"loading":_vm.loadingGroups,"disabled":_vm.disableGroups},on:{"input":_vm.groupsChanged},model:{value:(_vm.user.groups),callback:function ($$v) {_vm.$set(_vm.user, "groups", $$v)},expression:"user.groups"}}),_c('font-awesome-icon',{staticClass:"required-prop ml-1",attrs:{"title":_vm.$t("messages.info.requiredField"),"icon":['far', 'asterisk'],"size":"xs"}})],1),(errors[0])?_c('small',{staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(_vm.$t("messages.error.atLeastOneGroup"))+" ")]):_vm._e()])]}}],null,true)})],1)]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }