<template>
  <div v-if="document" class="padding-right">
    <select v-if="existingDocumentTypes" v-model="document.documentType" class="form-control" @change="setDocumentProperties()">
      <option :value="null" disabled="disabled" hidden="hidden">
        {{ $t('messages.info.selectDocType') }}
      </option>
      <option v-for="(item, typeIndex) in existingDocumentTypes" :key="typeIndex" :value="item">
        {{ item[docTypeDisplay] }}
      </option>
    </select>
    <hr />
    <div v-if="document.documentType">
      <!-- <ValidationProvider v-slot="{ errors, classes }" class="form-group row" :name="$t(`form.title`)" rules="required">
        <label class="col-4 col-form-label" for="title">
          {{ $t(`form.title`) + ':' }}
        </label>
        <div class="col-8">
          <CustomInput id="title" v-model="document.title" :classes="classes" :is-mandatory="true" :errors="errors"></CustomInput>
        </div>
      </ValidationProvider> -->
      <ValidationProvider
        v-for="(prop, propertyIndex) in document.documentType.properties"
        :key="propertyIndex"
        v-slot="{ errors, classes }"
        class="form-group row"
        :name="prop.name"
        :rules="prop.isMandatory ? 'required' : ''"
      >
        <label class="col-4 col-form-label" :for="prop.name + propertyIndex">{{ prop[displayValue] + ':' }}</label>
        <div class="col-8">
          <CustomInput
            :id="prop.name + propertyIndex"
            v-model="document.properties[propertyIndex].value"
            :input-type="getInputType(prop.type)"
            :classes="classes"
            :is-mandatory="prop.isMandatory"
            :errors="errors"
          >
          </CustomInput>
        </div>
      </ValidationProvider>
      <!-- 
      <ValidationProvider v-slot="{ errors, classes }" class="form-group row" :name="$t(`form.fileName`)" rules="required|hasFileExtension">
        <label class="col-4 col-form-label" for="fileName">{{ $t(`form.fileName`) + ':' }}</label>
        <div class="col-8">
          <CustomInput id="fileName" v-model="document.fileName" :classes="classes" :is-mandatory="true" :errors="errors"></CustomInput>
        </div>
      </ValidationProvider> -->
      <!-- <ValidationProvider v-slot="{ errors, classes }" class="form-group row" :name="$t(`form.documentDate`)" rules="required">
        <label class="col-4 col-form-label" for="documentDate">{{ $t(`form.documentDate`) + ':' }}</label>
        <div class="col-8">
          <CustomInput
            id="documentDate"
            v-model="document.documentDate"
            input-type="date"
            :classes="classes"
            :is-mandatory="true"
            :errors="errors"
          ></CustomInput>
        </div>
      </ValidationProvider> -->
    </div>
  </div>
</template>
<script>
import CustomInput from '../common/CustomInput.vue';

export default {
  name: 'DocumentDetailsEditor',
  components: {
    CustomInput
  },
  props: {
    document: {
      type: Object,
      default: () => {
        documentType: {
        }
      }
    },
    displayValue: {
      type: String,
      default: () => 'name'
    },
    existingDocumentTypes: {
      type: Array,
      default: () => []
    },
    docTypeDisplay: {
      type: String,
      default: () => 'name'
    }
  },
  methods: {
    getInputType(type) {
      switch (type) {
        case 0:
          return 'text';
        case 1:
          return 'number';
        case 2:
          return 'date';
      }
    },
    setDocumentProperties() {
      this.document.properties = [];
      this.document.documentType.properties.forEach(element => {
        let typeCopy = Object.assign({}, element);
        this.document.properties.push(typeCopy);
      });
      console.log(this.document.properties);
    }
  }
};
</script>
<style scoped>
.form-control {
  border-radius: 0.25rem !important;
}

.required-prop {
  color: var(--secondary);
}

.padding-right {
  padding-right: 15px;
}

.hide-me {
  opacity: 0;
}
</style>
