import axios from 'axios';
import AxiosDefaults from './axios-defaults';

export default {
  getContacts(supplierId) {
    return axios.get('/contacts?supplierId=' + supplierId, AxiosDefaults.requestConfig).then(response => response.data);
  },
  getSupplierContacts() {
    return axios.get('/contacts/current', AxiosDefaults.requestConfig).then(response => response.data);
  },
  putInvitation(invitation) {
    return axios.put('/contacts/invitations', invitation, AxiosDefaults.requestConfig).then(response => response.data);
  }
};
