<template>
  <table class="table table-hover">
    <thead>
      <tr>
        <th scope="col" style="width: 5%"></th>
        <th scope="col" style="width: 30%">
          {{ $t(`form.emailAddress`) }}
        </th>
        <th scope="col" style="width: 30%">
          {{ $t(`form.group`) }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="user in users" :key="user.id">
        <td>
          <div :title="$t(`buttons.edit`)" @click="edit(user.id, user.supplierId)">
            <i class="far fa-edit"></i>
          </div>
        </td>
        <td>
          {{ user.emailAddress }}
        </td>
        <td>
          <span v-for="group in user.groups" :key="group.groupName">
            <b-badge variant="secondary" style="margin:5px">{{ $t(`cognitoGroups.${group.groupName}`) }}</b-badge>
          </span>
        </td>
      </tr>
      <tr v-if="!showSpinner && users.length === 0" style="background:none">
        <td colspan="4">
          <div class="jumbotron">
            {{ $t(`messages.info.noUsersFound`) }}
          </div>
        </td>
      </tr>
      <tr v-if="showSpinner" style="background:none">
        <td colspan="4">
          <spinner :show-spinner="showSpinner" />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import router from '../../router';
import Spinner from '../common/Spinner';

export default {
  name: 'UserTable',
  components: {
    Spinner
  },
  props: {
    users: {
      type: Array,
      default: () => []
    },
    showSpinner: {
      type: Boolean,
      default: () => false
    }
  },
  methods: {
    edit: function(id, supplierId) {
      if (supplierId) {
        router.push({ name: 'supplieruserdetails', params: { id: id, supplierId: supplierId } });
      } else {
        router.push({ name: 'userdetails', params: { id: id } });
      }
    }
  }
};
</script>

<style scoped>
.fa-edit:hover {
  color: var(--secondary);
  cursor: pointer;
}
</style>
