<template>
  <div class="container-fluid">
    <div v-if="supplier.deletionDate" class="alert alert-danger" role="alert">
      {{ $t(`messages.info.supplierDeletionWarning`, { date: new Date(supplier.deletionDate).toLocaleDateString() }) }}
    </div>
    <div class="text-center">
      <img id="homeLogo" src="../../assets/logo_gross.png" :alt="$t(`links.home`)" class="navbarBrand" align="right" />
      <h1 id="homeWelcome">
        {{ $t(`messages.info.homeWelcome`) }}
      </h1>
      <img id="companyPicture" src="../../assets/VSM-Unternehmenszentrale.jpg" :alt="$t(`links.home`)" />
    </div>
    <br />
    <br />
    <b-tabs v-if="isSupplier" content-class="mt-3">
      <b-tab :title="$t(`messages.info.overdueDocuments`)" :active="deadlines && deadlines.length !== 0">
        <h4>
          {{ $t(`messages.info.overdueDocuments`) }}
        </h4>
        <br />
        <table class="table table-hover">
          <thead>
            <tr>
              <th scope="col">
                {{ $t(`form.materialNumber`) }}
              </th>
              <th scope="col">
                {{ $t(`form.materialDescription`) }}
              </th>
              <th scope="col">
                {{ $t(`form.documentCategory`) }}
              </th>
              <th scope="col">
                {{ $t(`form.deadline`) }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(deadline, index) in deadlines" :key="index">
              <td>{{ deadline.materialNumber }}</td>
              <td>{{ deadline.materialDescription }}</td>
              <td>{{ deadline.documentType }}</td>
              <td>{{ deadline.reminderDate | localeDate }}</td>
            </tr>
          </tbody>
        </table>
      </b-tab>
      <b-tab :title="$t(`messages.info.homeLatestDocuments`)" :active="!deadlines || deadlines.length === 0">
        <h4>{{ $t(`messages.info.homeLatestDocuments`) }}</h4>
        <br />
        <table class="table table-hover">
          <thead>
            <tr>
              <th scope="col">
                {{ $t(`form.title`) }}
              </th>
              <th scope="col">
                {{ $t(`form.documentType`) }}
              </th>
              <th scope="col">
                {{ $t(`form.date`) }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="doc in documents" :key="doc.id" class="clickable" @click="goToDoc(doc.id)">
              <td>{{ doc.title }}</td>
              <td>{{ doc[docTypeDisplay] }}</td>
              <td>{{ doc.lastUpdated | localeDate }}</td>
            </tr>
          </tbody>
        </table>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import constants from '../../common/constants';
import UserApi from '../../services/api/user';
import DeadlineApi from '../../services/api/deadline';
import router from '../../router';
import UserInfoStore from '../../auth/user-info-store';
import Constants from '../../common/constants';

export default {
  name: 'Home',
  data() {
    return {
      adminGroup: constants.adminGroup,
      clerkGroup: constants.clerkGroup,
      supplierGroup: constants.supplierGroup,
      documents: [],
      deadlines: [],
      supplier: [],
      isSupplier: false
    };
  },
  computed: {
    currentLanguage: function() {
      return navigator.language.split('-')[0];
    },
    docTypeDisplay: function() {
      if (this.currentLanguage === 'de') return 'documentTypeName';
      else return 'documentTypeTranslation';
    }
  },
  mounted() {
    if (UserInfoStore.state.isInGroup(Constants.supplierGroup)) {
      this.isSupplier = true;
      this.getLatestUserDocumens();
      this.getDeadlines();
      this.getSupplier();
    }
  },
  methods: {
    getLatestUserDocumens() {
      UserApi.getUserDocuments()
        .then(response => {
          console.log('fetch docs successful', response);
          this.documents = response.result;
        })
        .catch(error => {
          console.log(error);
        });
    },
    getDeadlines() {
      DeadlineApi.getDeadlines()
        .then(response => {
          console.log('fetch deadlines successful', response);
          this.deadlines = response.result;
        })
        .catch(error => {
          console.log(error);
        });
    },
    getSupplier() {
      UserApi.getSupplierForCurrentUser()
        .then(response => {
          console.log('fetch supplier successful', response);
          this.supplier = response;
        })
        .catch(error => {
          console.log(error);
        });
    },
    goToDoc(docId) {
      router.push({ path: 'documents', query: { selectedId: docId } });
    }
  }
};
</script>

<style scoped>
.navbarBrand {
  margin-right: 1%;
}
.container-fluid {
  margin-top: 1%;
}
#companyPicture {
  margin-right: 2%;
}
#homeWelcome {
  margin-left: 7.25%;
}
.clickable:hover {
  cursor: pointer;
}
</style>
