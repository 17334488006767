<template>
  <form>
    <div class="h4">
      <label>{{ $t(`questions.contactDetails.generalData`) }}</label>
    </div>
    <label>{{ $t(`questions.contactDetails.name`) }}</label>
    <input v-model="contactDetailsData.name" type="text" class="form-control" :disabled="isReleased" @change="updateValue" />
    <label>{{ $t(`questions.contactDetails.phone`) }}</label>
    <input v-model="contactDetailsData.phone" type="text" class="form-control" :disabled="isReleased" @change="updateValue" />
    <label>{{ $t(`questions.contactDetails.companyAddress`) }}</label>
    <div class="form-row">
      <div class="col-7">
        <input
          v-model="contactDetailsData.addressStreet"
          type="text"
          class="form-control"
          :disabled="isReleased"
          :placeholder="$t(`questions.basicInformation.addressStreet`)"
          @change="updateValue"
        />
      </div>
      <div class="col">
        <input
          v-model="contactDetailsData.addressCity"
          type="text"
          class="form-control"
          :disabled="isReleased"
          :placeholder="$t(`questions.basicInformation.addressCity`)"
          @change="updateValue"
        />
      </div>
      <div class="col">
        <input
          v-model="contactDetailsData.addressZip"
          type="text"
          class="form-control"
          :disabled="isReleased"
          :placeholder="$t(`questions.basicInformation.addressZip`)"
          @change="updateValue"
        />
      </div>
    </div>
    <br />
    <div class="h5">
      <label>{{ $t(`questions.contactDetails.processingOfOrders`) }}</label>
    </div>
    <label>{{ $t(`questions.contactDetails.email`) }}</label>
    <input v-model="contactDetailsData.email" type="text" class="form-control" :disabled="isReleased" @change="updateValue" />
    <label>{{ $t(`questions.contactDetails.fax`) }}</label>
    <input v-model="contactDetailsData.fax" type="text" class="form-control" :disabled="isReleased" @change="updateValue" />
    <br />
    <div class="h4">
      <label>{{ $t(`questions.contactDetails.commercialContact`) }}</label>
    </div>
    <div class="h5">
      <label>{{ $t(`questions.contactDetails.internalSales`) }}</label>
    </div>
    <div class="form-row">
      <div id="internalSalesContactSelect" class="form-group col-sm-6">
        <label for="internalSalesContactSelect">{{ $t(`questions.contactDetails.takeContactValues`) }}</label>
        <select
          v-model="contactDetailsData.internalSales.contactMetaData"
          class="form-control"
          :disabled="isReleased"
          @change="updateContactValues('internalSales')"
        >
          <option :hidden="true" :disabled="true" :value="undefined">
            {{ $t(`questions.contactDetails.selectContact`) }}
          </option>
          <option v-for="(contact, contactIndex) in contacts" :key="contactIndex" :value="contact">
            {{ contact.commaSeparatedFullName }}
          </option>
        </select>
      </div>
    </div>
    <div v-for="(item, index) in questions" :key="'internal' + index" class="form-group">
      <label>{{ item.question }}</label>
      <input
        v-model="contactDetailsData.internalSales[item.id]"
        type="text"
        class="form-control"
        :disabled="isReleased"
        @change="updateValue"
      />
    </div>
    <div class="h5">
      <label>{{ $t(`questions.contactDetails.superior`) }}</label>
    </div>
    <div class="form-row">
      <div id="internalSalesSuperiorContactSelect" class="form-group col-sm-6">
        <label for="internalSalesSuperiorContactSelect">{{ $t(`questions.contactDetails.takeContactValues`) }}</label>
        <select
          v-model="contactDetailsData.internalSalesSuperior.contactMetaData"
          class="form-control"
          :disabled="isReleased"
          @change="updateContactValues('internalSalesSuperior')"
        >
          <option :hidden="true" :disabled="true" :value="undefined">
            {{ $t(`questions.contactDetails.selectContact`) }}
          </option>
          <option v-for="(contact, contactIndex) in contacts" :key="contactIndex" :value="contact">
            {{ contact.commaSeparatedFullName }}
          </option>
        </select>
      </div>
    </div>
    <div v-for="(item, index) in questions" :key="'internalsup' + index" class="form-group">
      <label>{{ item.question }}</label>
      <input
        v-model="contactDetailsData.internalSalesSuperior[item.id]"
        type="text"
        class="form-control"
        :disabled="isReleased"
        @change="updateValue"
      />
    </div>
    <div class="h4">
      <label>{{ $t(`questions.contactDetails.fieldSales`) }}</label>
    </div>
    <div class="form-row">
      <div id="externalSalesContactSelect" class="form-group col-sm-6">
        <label for="externalSalesContactSelect">{{ $t(`questions.contactDetails.takeContactValues`) }}</label>
        <select
          v-model="contactDetailsData.externalSales.contactMetaData"
          class="form-control"
          :disabled="isReleased"
          @change="updateContactValues('externalSales')"
        >
          <option :hidden="true" :disabled="true" :value="undefined">
            {{ $t(`questions.contactDetails.selectContact`) }}
          </option>
          <option v-for="(contact, contactIndex) in contacts" :key="contactIndex" :value="contact">
            {{ contact.commaSeparatedFullName }}
          </option>
        </select>
      </div>
    </div>
    <div v-for="(item, index) in questions" :key="'external' + index" class="form-group">
      <label>{{ item.question }}</label>
      <input
        v-model="contactDetailsData.externalSales[item.id]"
        type="text"
        class="form-control"
        :disabled="isReleased"
        @change="updateValue"
      />
    </div>
    <div class="h5">
      <label>{{ $t(`questions.contactDetails.superior`) }}</label>
    </div>
    <div class="form-row">
      <div id="externalSalesSuperiorContactSelect" class="form-group col-sm-6">
        <label for="externalSalesSuperiorContactSelect">{{ $t(`questions.contactDetails.takeContactValues`) }}</label>
        <select
          v-model="contactDetailsData.externalSalesSuperior.contactMetaData"
          class="form-control"
          :disabled="isReleased"
          @change="updateContactValues('externalSalesSuperior')"
        >
          <option :hidden="true" :disabled="true" :value="undefined">
            {{ $t(`questions.contactDetails.selectContact`) }}
          </option>
          <option v-for="(contact, contactIndex) in contacts" :key="contactIndex" :value="contact">
            {{ contact.commaSeparatedFullName }}
          </option>
        </select>
      </div>
    </div>
    <div v-for="(item, index) in questions" :key="'externalsup' + index" class="form-group">
      <label>{{ item.question }}</label>
      <input
        v-model="contactDetailsData.externalSalesSuperior[item.id]"
        type="text"
        class="form-control"
        :disabled="isReleased"
        @change="updateValue"
      />
    </div>
    <div class="h4">
      <label>{{ $t(`questions.contactDetails.technicalQualityRelatedContact`) }}</label>
    </div>
    <div class="h5">
      <label>{{ $t(`questions.contactDetails.technicalContact`) }}</label>
    </div>
    <div class="form-row">
      <div id="technicalContactContactSelect" class="form-group col-sm-6">
        <label for="technicalContactContactSelect">{{ $t(`questions.contactDetails.takeContactValues`) }}</label>
        <select
          v-model="contactDetailsData.technicalContact.contactMetaData"
          class="form-control"
          :disabled="isReleased"
          @change="updateContactValues('technicalContact')"
        >
          <option :hidden="true" :disabled="true" :value="undefined">
            {{ $t(`questions.contactDetails.selectContact`) }}
          </option>
          <option v-for="(contact, contactIndex) in contacts" :key="contactIndex" :value="contact">
            {{ contact.commaSeparatedFullName }}
          </option>
        </select>
      </div>
    </div>
    <div v-for="(item, index) in questions" :key="'technical' + index" class="form-group">
      <label>{{ item.question }}</label>
      <input
        v-model="contactDetailsData.technicalContact[item.id]"
        type="text"
        class="form-control"
        :disabled="isReleased"
        @change="updateValue"
      />
    </div>
    <div class="h5">
      <label>{{ $t(`questions.contactDetails.qualityRelatedContact`) }}</label>
    </div>
    <div class="form-row">
      <div id="qualityContactContactSelect" class="form-group col-sm-6">
        <label for="qualityContactContactSelect">{{ $t(`questions.contactDetails.takeContactValues`) }}</label>
        <select
          v-model="contactDetailsData.qualityContact.contactMetaData"
          class="form-control"
          :disabled="isReleased"
          @change="updateContactValues('qualityContact')"
        >
          <option :hidden="true" :disabled="true" :value="undefined">
            {{ $t(`questions.contactDetails.selectContact`) }}
          </option>
          <option v-for="(contact, contactIndex) in contacts" :key="contactIndex" :value="contact">
            {{ contact.commaSeparatedFullName }}
          </option>
        </select>
      </div>
    </div>
    <div v-for="(item, index) in questions" :key="'quality' + index" class="form-group">
      <label>{{ item.question }}</label>
      <input
        v-model="contactDetailsData.qualityContact[item.id]"
        type="text"
        class="form-control"
        :disabled="isReleased"
        @change="updateValue"
      />
    </div>
    <div class="h4">
      <label>{{ $t(`questions.contactDetails.furtherContactPersons`) }}</label>
    </div>
    <div class="form-row">
      <div id="furtherContactContactSelect" class="form-group col-sm-6">
        <label for="furtherContactContactSelect">{{ $t(`questions.contactDetails.takeContactValues`) }}</label>
        <select
          v-model="contactDetailsData.furtherContact.contactMetaData"
          class="form-control"
          :disabled="isReleased"
          @change="updateContactValues('furtherContact')"
        >
          <option :hidden="true" :disabled="true" :value="undefined">
            {{ $t(`questions.contactDetails.selectContact`) }}
          </option>
          <option v-for="(contact, contactIndex) in contacts" :key="contactIndex" :value="contact">
            {{ contact.commaSeparatedFullName }}
          </option>
        </select>
      </div>
    </div>
    <label>{{ $t(`questions.contactDetails.furtherContactPersonsBusiness`) }}</label>
    <input
      v-model="contactDetailsData.furtherContact.business"
      type="text"
      class="form-control"
      :disabled="isReleased"
      @change="updateValue"
    />
    <div v-for="(item, index) in questions" :key="'further' + index" class="form-group">
      <label>{{ item.question }}</label>
      <input
        v-model="contactDetailsData.furtherContact[item.id]"
        type="text"
        class="form-control"
        :disabled="isReleased"
        @change="updateValue"
      />
    </div>
    <div class="h4">
      <label>{{ $t(`questions.contactDetails.furtherContactPersons`) }}</label>
    </div>
    <div class="form-row">
      <div id="furtherFurtherContactContactSelect" class="form-group col-sm-6">
        <label for="furtherFurtherContactContactSelect">{{ $t(`questions.contactDetails.takeContactValues`) }}</label>
        <select
          v-model="contactDetailsData.furtherFurtherContact.contactMetaData"
          class="form-control"
          :disabled="isReleased"
          @change="updateContactValues('furtherFurtherContact')"
        >
          <option :hidden="true" :disabled="true" :value="undefined">
            {{ $t(`questions.contactDetails.selectContact`) }}
          </option>
          <option v-for="(contact, contactIndex) in contacts" :key="contactIndex" :value="contact">
            {{ contact.commaSeparatedFullName }}
          </option>
        </select>
      </div>
    </div>
    <label>{{ $t(`questions.contactDetails.furtherContactPersonsBusiness`) }}</label>
    <input
      v-model="contactDetailsData.furtherFurtherContact.business"
      type="text"
      class="form-control"
      :disabled="isReleased"
      @change="updateValue"
    />
    <div v-for="(item, index) in questions" :key="'furtherfurther' + index" class="form-group">
      <label>{{ item.question }}</label>
      <input
        v-model="contactDetailsData.furtherFurtherContact[item.id]"
        type="text"
        class="form-control"
        :disabled="isReleased"
        @change="updateValue"
      />
    </div>
    <div class="form-group">
      <h4>{{ $t(`questions.contactDetails.emailContacts`) }}</h4>
      <br />
      <label>{{ $t(`questions.contactDetails.emailSafetyDataSheets`) }}</label>
      <input
        v-model="contactDetailsData.emailSafetyDataSheets"
        type="text"
        class="form-control"
        :disabled="isReleased"
        @change="updateValue"
      />
    </div>
    <div class="form-group">
      <label>{{ $t(`questions.contactDetails.emailTechnicalDataSheets`) }}</label>
      <input
        v-model="contactDetailsData.emailTechnicalDataSheets"
        type="text"
        class="form-control"
        :disabled="isReleased"
        @change="updateValue"
      />
    </div>
    <div class="form-group">
      <label>{{ $t(`questions.contactDetails.emailVendorDeclarations`) }}</label>
      <input
        v-model="contactDetailsData.emailVendorDeclarations"
        type="text"
        class="form-control"
        :disabled="isReleased"
        @change="updateValue"
      />
    </div>
    <div class="form-group">
      <label>{{ $t(`questions.contactDetails.emailIso`) }}</label>
      <input v-model="contactDetailsData.emailIso" type="text" class="form-control" :disabled="isReleased" @change="updateValue" />
    </div>
    <div class="form-group">
      <label>{{ $t(`questions.contactDetails.emailEndUse`) }}</label>
      <input v-model="contactDetailsData.emailEndUse" type="text" class="form-control" :disabled="isReleased" @change="updateValue" />
    </div>
  </form>
</template>

<script>
import UserInfoStore from '../../auth/user-info-store';
import constants from '../../common/constants';
import ContactApi from '../../services/api/contact';

export default {
  name: 'SupplierEvaluationContactDetails',
  props: {
    contactDetails: {
      type: Object,
      default: () => {}
    },
    isReleased: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      questions: [
        { question: this.$t(`questions.contactDetails.firstLastName`), id: 'firstLastName' },
        { question: this.$t(`questions.contactDetails.address`), id: 'address' },
        { question: this.$t(`questions.contactDetails.email`), id: 'email' },
        { question: this.$t(`questions.contactDetails.phone`), id: 'phone' },
        { question: this.$t(`questions.contactDetails.fax`), id: 'fax' }
      ],
      contactDetailsData: {},
      contacts: []
    };
  },
  created() {
    this.contactDetailsData = Object.assign({}, this.contactDetails);
    this.getContacts();
  },
  methods: {
    updateValue() {
      this.$emit('contactDetailsUpdate', this.contactDetailsData);
    },
    updateContactValues(contactType) {
      console.log('type: ', contactType);
      let data = this.contactDetailsData[contactType];

      if (data && data.contactMetaData) {
        console.log('data: ', data);
        data.firstLastName = data.contactMetaData.commaSeparatedFullName;
        data.email = data.contactMetaData.emailAddress;
        data.phone = data.contactMetaData.phoneNumber;

        this.updateValue();
      }
    },
    getContacts() {
      ContactApi.getSupplierContacts().then(response => {
        console.log('contacts: ', response);
        if (response && response.result) {
          this.contacts = response.result;
        }
      });
    }
  }
};
</script>
