<template>
  <b-modal id="modal-selectConfig" role="dialog" size="xl" no-close-on-backdrop no-close-on-esc>
    <template slot="modal-header">
      <h5>{{ $t(`form.selectconfigurationForExport`) }}</h5>
    </template>
    <template slot="default">
      <div v-if="allDocumentTypes">
        <div class="row">
          <div class="col-12">
            <a v-if="!allSelected" class="float-right mr-3" href="#" @click="selectAll(true)">
              <h6>{{ $t(`buttons.selectAll`) }}</h6>
            </a>
            <a v-if="allSelected" class="float-right mr-3" href="#" @click="selectAll(false)">
              <h6>{{ $t(`buttons.clearAll`) }}</h6>
            </a>
          </div>
        </div>
        <div role="tablist">
          <b-card v-for="(type, index) in allDocumentTypes" :key="index" no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <h5 class="m-1" style="margin-bottom:0">
                <div class="row">
                  <div class="col-11">
                    <font-awesome-icon
                      v-if="type.isVisible"
                      class="mr-2"
                      :icon="['far', 'caret-square-up']"
                      @click="type.isVisible = !type.isVisible"
                    ></font-awesome-icon>
                    <font-awesome-icon
                      v-if="!type.isVisible"
                      class="mr-2"
                      :icon="['far', 'caret-square-down']"
                      @click="type.isVisible = !type.isVisible"
                    ></font-awesome-icon>
                    {{ type.name }}
                  </div>
                  <div class="col-1">
                    <div class="custom-control custom-checkbox float-right">
                      <input :id="'isSelected_' + index" v-model="type.isSelected" type="checkbox" class="custom-control-input" />
                      <label class="custom-control-label" :for="'isSelected_' + index"></label>
                    </div>
                  </div>
                </div>
              </h5>
            </b-card-header>
            <b-collapse :visible="type.isVisible" accordion="my-accordion" role="tabpanel">
              <b-card-body>
                <div class="list-group">
                  <div
                    v-for="(prop, propIndex) in type.properties"
                    :key="propIndex"
                    class="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
                  >
                    <div>
                      <font-awesome-icon
                        v-if="prop.type !== null && prop.type !== undefined"
                        class="fa-sm mr-2 low-opacity"
                        :icon="['far', selectIcon(prop)]"
                      ></font-awesome-icon>
                      {{ prop.name }}
                    </div>
                  </div>
                </div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </div>
    </template>
    <template slot="modal-footer">
      <div class="w-100">
        <button type="button" class="btn btn-primary float-right" @click="exportConfigurations">
          {{ $t(`buttons.save`) }}
        </button>
        <button type="button" class="btn btn-secondary float-right mr-1" @click="hide">
          {{ $t(`buttons.cancel`) }}
        </button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import DocumentTypesApi from '../../services/api/documentType';
import utils from '../../common/utils';

export default {
  name: 'SelectExportConfigurationDialog',
  data() {
    return {
      allDocumentTypes: []
    };
  },
  computed: {
    allSelected: function() {
      return this.allDocumentTypes.every(type => {
        return type.isSelected;
      });
    }
  },
  mounted() {
    this.getAllConfigurations();
  },
  methods: {
    selectAll(select) {
      this.allDocumentTypes.forEach(type => {
        type.isSelected = select;
      });
    },
    selectIcon: function(item) {
      switch (item.type) {
        case 0:
          return 'text';
        case 1:
          return 'calculator';
        case 2:
          return 'calendar-alt';
      }
    },
    getAllConfigurations() {
      DocumentTypesApi.getDocumentTypes().then(typeResponse => {
        console.log('get type successful ', typeResponse);
        if (typeResponse) {
          typeResponse.forEach(element => {
            element.isVisible = false;
            element.isSelected = false;
          });

          this.allDocumentTypes = typeResponse;
        }
      });
    },
    exportConfigurations() {
      var exportConfiguration = { documentTypes: [] };

      this.allDocumentTypes.forEach(type => {
        if (type && type.isSelected) {
          exportConfiguration.documentTypes.push(type);
        }
      });

      utils.exportJson(exportConfiguration, 'config.json');
    },
    hide() {
      this.$emit('hidedialog');
      this.$bvModal.hide('modal-selectConfig');
    }
  }
};
</script>
<style scoped>
*:not(input) {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
