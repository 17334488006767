<template>
  <div class="container-fluid">
    <ViewHeader :title="$t(`headers.documentTypes`)"></ViewHeader>
    <div class="btn-group">
      <span class="float-left mb-3">
        <button type="button" class="btn btn-primary" @click="openEditDialog(undefined)">
          <i class="far fa-plus"></i>
          {{ $t(`buttons.add`) }}
        </button>
      </span>
    </div>
    <table class="table table-hover">
      <thead>
        <tr>
          <th scope="col" style="width: 5%"></th>
          <th scope="col" style="width: 30%">
            {{ $t(`form.documentType`) }}
          </th>
          <th scope="col" style="width: 30%">
            {{ $t(`form.translation`) }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in existingDocumentTypes" :key="index">
          <td>
            <div class="clickable" :title="$t(`buttons.edit`)" @click="openEditDialog(item.id)">
              <i class="far fa-edit"></i>
            </div>
          </td>
          <td>
            {{ item.name }}
          </td>
          <td>
            {{ item.translation }}
          </td>
        </tr>
        <tr v-if="!showSpinner && existingDocumentTypes.length === 0" style="background:none">
          <td colspan="4">
            <div class="jumbotron">
              {{ $t(`messages.info.noDocumentTypesFound`) }}
            </div>
          </td>
        </tr>
        <tr v-if="showSpinner" style="background:none">
          <td colspan="4">
            <spinner :show-spinner="showSpinner" />
          </td>
        </tr>
      </tbody>
    </table>
    <DocumentTypeDialog
      v-if="showDialog"
      :id="typeToEdit"
      @hidedialog="resetDialog"
      @success-save="showSuccessMessage($t(`messages.success.documentType`))"
      @success-delete="showSuccessMessage($t(`messages.success.delete`))"
    ></DocumentTypeDialog>
  </div>
</template>
<script>
import router from '../../router';
import Toast from '../../common/toast';
import ViewHeader from '../common/ViewHeader';
import Spinner from '../common/Spinner';
import DocumentTypesApi from '../../services/api/documentType';
import DocumentTypeDialog from './DocumentTypeDialog.vue';

export default {
  name: 'DocumentTypeList',
  components: {
    ViewHeader,
    Spinner,
    DocumentTypeDialog
  },
  props: {
    documentTypes: {
      type: Array,
      default: () => []
    },
    showSpinner: {
      type: Boolean,
      default: () => false
    }
  },
  data: function() {
    return {
      existingDocumentTypes: [],
      documentTypeName: '',
      typeToEdit: '',
      showDialog: false
    };
  },
  mounted() {
    this.getDocumentTypes();
  },
  methods: {
    openEditDialog(id) {
      this.showDialog = true;
      this.typeToEdit = id;
      this.$nextTick(() => {
        this.$bvModal.show('modal-documentType');
      });
    },
    resetDialog() {
      console.log('reset!');
      this.showDialog = false;
    },
    showSuccessMessage(message) {
      Toast.showSuccessMessage(this, message);
      this.getDocumentTypes();
    },
    getDocumentTypes() {
      DocumentTypesApi.getDocumentTypes().then(response => {
        console.log('get successful ', response);
        if (response) {
          this.existingDocumentTypes = response;
        }
      });
    }
  }
};
</script>

<style scoped>
.clickable:hover {
  color: var(--secondary);
  cursor: pointer;
}
</style>
