<template>
  <ValidationObserver ref="list" v-slot="{ valid, passes, failed }">
    <b-modal
      id="modal-upload"
      title="Modal title"
      role="dialog"
      size="xl"
      dialog-class="high-dialog"
      content-class="high-dialog"
      body-class="high-body"
      no-close-on-backdrop
      no-close-on-esc
      @hide="clearFiles"
    >
      <template slot="modal-header">
        <h5>
          {{ showStatus ? $t(`messages.info.uploadStatus`) : $t(`messages.info.uploadFile`) }}
        </h5>
        <div class="col-6">
          <div v-if="!showStatus" id="customFile" class="custom-file">
            <input
              id="files"
              ref="selectedFiles"
              type="file"
              multiple
              class="custom-file-input"
              @change="setFiles"
              @drop.prevent="setFiles"
              @dragover.prevent
            />
            <label class="custom-file-label text-truncate" for="files">
              {{ $t(`messages.info.selectFile`) }}
            </label>
          </div>
        </div>
      </template>
      <template slot="default">
        <transition name="fade-out-in">
          <DocumentTab
            v-if="documents && documents.length > 0 && !showStatus"
            ref="documentTab"
            :documents="documents"
            :existing-document-types="existingDocumentTypes"
          ></DocumentTab>
          <UploadStatus
            v-if="documents && documents.length > 0 && showStatus"
            :documents="documents"
            :doc-type-display="currentLanguage === 'de' ? 'name' : 'translation'"
            @retry="uploadDocument"
          />
        </transition>
      </template>
      <template slot="modal-footer" slot-scope="{ cancel }">
        <div class="w-100">
          <button id="closeDialog" type="button" class="btn btn-secondary" :disabled="disableCloseButton" @click="cancel">
            {{ showStatus ? $t(`buttons.close`) : $t(`buttons.cancel`) }}
          </button>
          <button
            v-if="documents.length > 0 && activeIndex !== documents.length - 1"
            id="nextDialog"
            type="button"
            class="btn btn-primary float-right"
            :disabled="isActiveDocumentEmpty || failed || showStatus"
            @click="passes(setNext)"
          >
            {{ $t(`buttons.next`) }}
            <font-awesome-icon v-if="!showStatus" :icon="['far', 'chevron-right']"></font-awesome-icon>
          </button>
          <button
            v-if="documents.length > 0 && activeIndex === documents.length - 1 && !showStatus"
            type="button"
            class="btn btn-primary float-right"
            :disabled="isActiveDocumentEmpty || failed"
            @click="passes(submitDocuments)"
          >
            <font-awesome-icon :icon="['far', 'arrow-to-top']"></font-awesome-icon>
            {{ $t(`buttons.upload`) }}
          </button>
          <button
            v-if="documents.length > 1 && !showStatus"
            id="backDialog"
            type="button"
            class="btn btn-primary float-right mr-1"
            :disabled="documents.length === 0 || activeIndex === 0 || showStatus"
            @click="setPrevious"
          >
            <font-awesome-icon v-if="!showStatus" :icon="['far', 'chevron-left']"></font-awesome-icon>
            {{ $t(`buttons.back`) }}
          </button>
        </div>
      </template>
    </b-modal>
  </ValidationObserver>
</template>
<script>
import Vue from 'vue';
import collapsableCard from '../common/CollapsableCard.vue';
import DocumentApi from '../../services/api/document';
import UserApi from '../../services/api/user';
import S3Api from '../../services/api/s3';
import SupplierApi from '../../services/api/supplier';
import Toast from '../../common/toast';
import DocumentTypesApi from '../../services/api/documentType';
import DocumentTab from './DocumentTab.vue';
import UploadStatus from './UploadStatus.vue';

export default {
  name: 'DocumentUpload',
  components: {
    DocumentTab,
    UploadStatus
  },
  data() {
    return {
      showStatus: false,
      existingDocumentTypes: [],
      documents: [],
      supplier: {},
      loadingSupplier: false,
      activeIndex: ''
    };
  },
  computed: {
    disableCloseButton: function() {
      if (!this.showStatus) return false;

      if (this.documents && this.documents.length > 0) {
        var pendingDoc = this.documents.find(doc => {
          return doc.status === 'pending';
        });

        if (pendingDoc) {
          return true;
        }
      }

      return false;
    },
    isActiveDocumentEmpty: function() {
      if (!this.documents || this.documents.length === 0) return true;
      if (!this.documents[this.activeIndex] || !this.documents[this.activeIndex].documentType) return true;
      return false;
    },
    currentLanguage: function() {
      return navigator.language.split('-')[0];
    }
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNavigation);

    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('beforeunload', this.preventNavigation);
    });
  },
  mounted() {
    this.fetchSupplier();
    this.getDocumentTypes();
  },
  methods: {
    preventNavigation(event) {
      if (!this.disableCloseButton) return;

      event.preventDefault();
      event.returnValue = '';
    },
    getDocumentTypes() {
      DocumentTypesApi.getDocumentTypes().then(response => {
        console.log('get successful ', response);
        if (response) {
          this.existingDocumentTypes = response;
        }
      });
    },
    submitDocuments() {
      if (!this.documents || this.documents.length === 0 || this.showStatus) {
        return;
      }
      this.showStatus = true;

      for (var i = 0; i < this.documents.length; i++) {
        let document = this.documents[i];
        this.uploadDocument(document);
      }
    },
    uploadDocument(document) {
      document.status = 'pending';

      if (document.supplier) {
        document.supplierId = document.supplier.id;
      }

      document.contentType = document.file.type;
      document.documentTypeId = document.documentType.id;

      DocumentApi.postDocument(document)
        .then(response => {
          console.log('post lambda success response: ', response);

          if (!response || !response.presignedUrlModel || !response.presignedUrlModel.httpHeaders)
            this.handleError('Response is empty. Cannot upload document.');

          var presignedModel = response.presignedUrlModel;

          let config = {
            headers: {}
          };

          Object.keys(presignedModel.httpHeaders).forEach(function(key) {
            config.headers[key] = presignedModel.httpHeaders[key];
          });

          Object.keys(presignedModel.httpContentHeaders).forEach(function(key) {
            config.headers[key] = presignedModel.httpContentHeaders[key];
          });

          S3Api.putObject(presignedModel.url, document.file, config)
            .then(() => {
              console.log('Document successfully uploaded!');
              this.$emit('documentUploaded', response.document);
              document.status = 'success';
            })
            .catch(error => {
              DocumentApi.deleteDocument(response.document.id, response.document.supplierId).finally(() => {
                document.status = 'error';
                console.log('Error uploading document to s3: ', error);
              });
            });
        })
        .catch(error => {
          document.status = 'error';
          console.log('Error uploading document to lambda: ', error);
        });
    },
    clearFiles(event) {
      this.documents = [];
      this.showStatus = false;
    },
    fetchSupplier() {
      if (this.loadingSupplier) return;

      this.loadingSupplier = true;
      this.supplier = {};

      UserApi.getSupplierForCurrentUser()
        .then(response => {
          console.log("fetch current user's supplier successful", response);
          this.supplier = response;
          this.loadingSupplier = false;
        })
        .catch(error => {
          console.log(error);
          this.loadingSupplier = false;
        });
    },
    setFiles(event) {
      console.log('setFiles');
      let selectedRef;

      if (event && event.dataTransfer && event.dataTransfer.files.length > 0) {
        console.log('drop event');
        selectedRef = event.dataTransfer.files;
      } else if (this.$refs.selectedFiles.files.length > 0) {
        console.log('change event');
        selectedRef = this.$refs.selectedFiles.files;
      } else {
        console.log('No files selected');
        return;
      }

      // FileList-Objekt in Document Array umwandeln
      for (let i = 0; i < selectedRef.length; i++) {
        if (selectedRef[i].size <= 0) {
          Toast.showErrorMessage(this, this.$t(`messages.error.emptyFile`));
          continue;
        }

        console.log('file: ', selectedRef[i]);
        var documentObject = {
          // title: selectedRef[i].name.replace(/\.[^/.]+$/, ''),
          title: selectedRef[i].name,
          fileName: selectedRef[i].name,
          documentDate: new Date().toISOString().split('T')[0], // YYYY-MM-DD
          sizeInBytes: selectedRef[i].size,
          contentType: selectedRef[i].type,
          file: selectedRef[i],
          supplier: this.supplier,
          documentType: null,
          properties: [],
          status: ''
        };

        if (i === 0 && this.documents.length === 0) {
          documentObject.isActiveDocument = true;
          this.activeIndex = 0;
        } else {
          documentObject.isActiveDocument = false;
        }

        this.documents.push(documentObject);
      }

      // reset input
      const input = this.$refs.selectedFiles;
      input.type = 'text';
      input.type = 'file';
    },
    scrollToActiveItem() {
      this.$refs.documentTab.scrollToActiveItem(this.activeIndex);
    },
    setNext() {
      if (this.activeIndex === this.documents.length - 1) {
        return;
      }

      this.documents.forEach(doc => {
        doc.isActiveDocument = false;
      });
      let nextIndex = this.activeIndex + 1;

      this.documents[nextIndex].isActiveDocument = true;
      this.activeIndex = nextIndex;

      this.scrollToActiveItem();
    },
    setPrevious() {
      if (this.activeIndex === 0) {
        return;
      }

      this.documents.forEach(doc => {
        doc.isActiveDocument = false;
      });
      let prevIndex = this.activeIndex - 1;

      this.documents[prevIndex].isActiveDocument = true;
      this.activeIndex = prevIndex;

      this.scrollToActiveItem();
    }
  }
};
</script>
<style scoped>
.fade-out-in-enter-active,
.fade-out-in-leave-active {
  transition: opacity 0.25s;
}

.fade-out-in-enter-active {
  transition-delay: 0.25s;
}

.fade-out-in-enter,
.fade-out-in-leave-to {
  opacity: 0;
}

*:not(input) {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>

<style>
.high-dialog {
  height: 90% !important;
}

.high-body {
  /* 100% = dialog height, 120px = header + footer */
  max-height: calc(100% - 120px) !important;
  overflow-y: auto !important;
}
</style>
