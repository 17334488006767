import {
  PublicClientApplication,
  AccountInfo,
  RedirectRequest,
  PopupRequest,
  SilentRequest,
  AuthenticationResult,
  InteractionRequiredAuthError,
  EndSessionRequest,
  Configuration
} from '@azure/msal-browser';
import UserInfoStore from './user-info-store';
const AuthModule = (config, scopes) => {
  const MSALProvider = new PublicClientApplication({ ...config });
  let account = null;
  let token = { current: undefined };

  const getAccount = () => {
    const currentAccounts = MSALProvider.getAllAccounts();
    if (currentAccounts === null) {
      console.log('No accounts detected');
      return null;
    }
    if (currentAccounts.length > 1) {
      console.log('Multiple accounts detected, need to add choose account code.');
      return currentAccounts[0];
    } else if (currentAccounts.length === 1) {
      return currentAccounts[0];
    }
  };
  const handleResponse = async response => {
    try {
      if (response !== null) {
        account = response.account;
      } else {
        account = getAccount() || null;
      }
      if (account == null) {
        UserInfoStore.setLoggedOut();
        //window.location.href = '/error';
        //window.location.href = '/error';
      } else {
        //Todo
        console.log('On Success result');
        UserInfoStore.setLoggedIn(true);

        window.location.href = '/';
      }
      console.log(UserInfoStore.state.loggedIn);
      return account;
    } catch (error) {
      console.error(error);
    }
  };

  const login = async signInType => {
    try {
      if (signInType === 'loginPopup') {
        const resp = await MSALProvider.loginPopup({ ...config, scopes });
        handleResponse(resp);
      } else if (signInType === 'loginRedirect') {
        await MSALProvider.loginRedirect({ scopes: scopes });
      }
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };
  const attemptSsoSilent = async () => {
    try {
      const resp = await MSALProvider.ssoSilent({ ...config, scopes });
      account = getAccount() || null;
    } catch (error) {
      console.error('Silent Error: ' + error);
      if (error instanceof InteractionRequiredAuthError) {
        login('loginPopup');
      }
      console.error(error);
    }
  };

  const getTokenPopup = async (silentRequest, interactiveRequest) => {
    try {
      const response = await MSALProvider.acquireTokenPopup(interactiveRequest);
      token.current = response.accessToken;
      return response.accessToken;
    } catch (e) {
      console.log('silent token acquisition fails.');
      if (e instanceof InteractionRequiredAuthError) {
        console.log('acquiring token using redirect');
        const resp = await MSALProvider.acquireTokenPopup(interactiveRequest);
        if (resp) {
          token.current = resp.accessToken;
          return resp.accessToken;
        } else {
          throw new Error('Token response was null');
        }
      } else {
        console.error(e);
        return undefined;
      }
    }
  };

  const getTokenRedirect = async (silentRequest, interactiveRequest) => {
    try {
      const response = await MSALProvider.acquireTokenSilent(silentRequest);
      token.current = response.accessToken;
      return response;
    } catch (e) {
      console.log('silent token acquisition fails. ', e);
      if (e instanceof InteractionRequiredAuthError) {
        console.log('acquiring token using redirect');
        const resp = await MSALProvider.acquireTokenRedirect(interactiveRequest);
      } else {
        console.log('token redirect after solent fails');
      }
    }
  };

  const logout = async () => {
    try {
      if (account) {
        const logOutRequest = {
          account: account
        };
        MSALProvider.logout(logOutRequest);
      } else {
        const account = getAccount();
        MSALProvider.logout({ account: account || undefined });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const loadAuthModule = async () => {
    try {
      console.log('Pre-handleRedirectPromise');
      const resp = await MSALProvider.handleRedirectPromise();
      console.log('Post-handleRedirectPromise');

      console.log(resp);

      if (resp) {
        console.log(resp.idTokenClaims['acr'] == config.auth.resetPasswordAuthority);

        if (resp.idTokenClaims['tfp'] == config.auth.resetPasswortPolicy) {
          await logout();
        }

        return await handleResponse(resp);
      }
    } catch (error) {
      console.error(error);
      if (error.message.indexOf('AADB2C90118') > -1) {
        await MSALProvider.loginRedirect({ authority: config.auth.resetPasswordAuthority });
      }
    }
  };

  const resetPassword = async redirectUrl => {
    await MSALProvider.loginRedirect({ authority: config.auth.resetPasswordAuthority, redirectUri: redirectUrl });
  };

  const getProfileTokenRedirect = async () => {
    try {
      if (account) {
        const token = await getTokenRedirect(
          { account: getAccount(), scopes: ['User.Read', 'offline_access'] },
          { ...config, scopes: ['User.Read', 'offline_access'] }
        );
        console.log(token);
        return token;
      }
    } catch (error) {
      console.error(error);
    }
    return;
  };

  const getGroupToken = async () => {
    let response = null;
    try {
      const silentscopes = ['User.Read', 'Group.Read', 'offline_access'];
      if (getAccount()) {
        const resp = await getTokenRedirect(
          {
            account: getAccount(),
            scopes: ['https://vsmsupplierportal.onmicrosoft.com/66948131-96e0-478c-8658-686c12693b0c/access_api']
          },
          {
            scopes: ['https://vsmsupplierportal.onmicrosoft.com/66948131-96e0-478c-8658-686c12693b0c/access_api']
          }
        );
        console.log('getgrouptoken.intern.expires on:', resp.expiresOn);
        console.log('getgrouptoken.intern.fromCache:', resp.fromCache);
        console.log('getgrouptoken.intern.idToken:', resp.idToken);
        console.log('getgrouptoken.intern.idTokenClaims:', resp.idTokenClaims);
        console.log('getgrouptoken.intern.accessToken:', resp.accessToken);
        response = resp;
        return resp;
      }
    } catch (error) {
      console.error(error);
      const resp = await getTokenRedirect(
        {
          account: getAccount(),
          scopes: ['https://graph.microsoft.com/User.Read']
        },
        {
          ...config,
          scopes: ['https://graph.microsoft.com/User.Read']
        }
      );
    }
    return response;
  };

  const getProfileTokenPopup = async () => {
    try {
      const acc = account || getAccount();
      if (acc) {
        const resp = await getTokenPopup(
          { account: acc, scopes: ['openid', 'profile', 'User.Read'] },
          { ...config, scopes: ['openid', 'profile', 'User.Read'] }
        );
        return resp;
      } else throw new Error('no account found');
    } catch (error) {
      throw error;
    }
  };

  const getAccessToken = async () => {
    try {
      if (account != null) {
        const token = await getTokenRedirect(
          { account: account, scopes: ['openid', 'profile', 'User.Read', 'email'] },
          { ...config, scopes: ['openid', 'profile', 'User.Read', 'email'] }
        );
        return token;
      } else {
        loadAuthModule();
        await MSALProvider.loginRedirect({ scopes });
      }
    } catch (error) {
      throw error;
    }
  };

  const getToken = () => {
    return { ...token }.current;
  };

  return {
    getToken,
    getAccount,
    loadAuthModule,
    getTokenRedirect,
    getTokenPopup,
    getProfileTokenPopup,
    getProfileTokenRedirect,
    attemptSsoSilent,
    login,
    logout,
    handleResponse,
    getGroupToken,
    resetPassword,
    MSALProvider
  };
};

export default AuthModule;
