import { Configuration, LogLevel } from '@azure/msal-browser';
import AuthModule from './AuthModule';

const config = {
  auth: {
    clientId: '66948131-96e0-478c-8658-686c12693b0c',
    authority: 'https://vsmsupplierportal.b2clogin.com/vsmsupplierportal.onmicrosoft.com/B2C_1_Sign_In',
    resetPasswordAuthority: 'https://vsmsupplierportal.b2clogin.com/vsmsupplierportal.onmicrosoft.com/B2C_1_ResetPassword',
    resetPasswortPolicy: 'B2C_1_ResetPassword',
    postLogoutRedirectUri: window.location.origin,
    redirectUri: window.location.origin,
    navigateToLoginRequestUrl: true,
    knownAuthorities: ['vsmsupplierportal.b2clogin.com'],
    response_type: 'code',
    validateAuthority: false
  },
  cache: { cacheLocation: 'localStorage', storeAuthStateInCookie: false },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          console.info(containsPii);
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      }
    }
  }
};

export const authProvider = AuthModule(config, [
  'openid',
  'profile',
  'email',
  'offline_access',
  'https://vsmsupplierportal.onmicrosoft.com/66948131-96e0-478c-8658-686c12693b0c/access_api'
]);

export default authProvider;
