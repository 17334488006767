<template>
  <div class="container-fluid">
    <ViewHeader :title="$t(`headers.userDetails`)"></ViewHeader>
    <ValidationObserver ref="userObserver" v-slot="{ valid, dirty }">
      <div class="row">
        <div class="col-12">
          <div class="btn-group mb-3">
            <button type="button" class="btn btn-primary mr-2" @click="navigateToList">
              <i class="far fa-chevron-left"></i>
              {{ $t(`buttons.back`) }}
            </button>
            <button type="button" class="btn btn-primary mr-2" :disabled="!valid || (!isNew && !dirty)" @click="save">
              <i class="far fa-save"></i>
              {{ $t(`buttons.save`) }}
            </button>
            <button v-if="!isNew" type="button" class="btn btn-danger" @click="deleteUser">
              <i class="far fa-trash-alt"></i>
              {{ $t(`buttons.delete`) }}
            </button>
          </div>
        </div>
      </div>
      <div v-if="!loadingUser" class="form-row">
        <div class="col-9">
          <ValidationProvider v-slot="{ errors, classes }" :name="$t(`form.emailAddress`)" rules="required|email">
            <div class="form-group ">
              <label for="emailAddress">{{ $t(`form.emailAddress`) }}</label>
              <CustomInput
                id="emailAddress"
                v-model="user.emailAddress"
                :readonly="!isNew"
                :classes="classes"
                :is-mandatory="true"
                :errors="errors"
              ></CustomInput>
            </div>
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }" rules="required">
            <div class="form-group">
              <label class="typo__label">{{ $t(`form.groups`) }}</label>
              <div class="input-group">
                <multiselect
                  v-model="user.groups"
                  label="description"
                  select-label=""
                  placeholder=""
                  :selected-label="$t(`form.groupSelectedLabel`)"
                  :deselect-label="$t(`buttons.delete`)"
                  track-by="groupName"
                  :options="groups"
                  :multiple="true"
                  :hide-selected="true"
                  :loading="loadingGroups"
                  :class="errors[0] ? 'invalid-groups' : ''"
                  class="multi-align"
                  :disabled="disableGroups"
                  @input="groupsChanged"
                ></multiselect>
                <font-awesome-icon
                  :title="$t(`messages.info.requiredField`)"
                  :icon="['far', 'asterisk']"
                  size="xs"
                  class="required-prop ml-1"
                ></font-awesome-icon>
              </div>
              <small v-if="errors[0]" style="color:red">
                {{ $t(`messages.error.atLeastOneGroup`) }}
              </small>
            </div>
          </ValidationProvider>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import ViewHeader from '../common/ViewHeader';
import router from '../../router';
import Toast from '../../common/toast';
import Vue from 'vue';
import UserApi from '../../services/api/user';
import Multiselect from 'vue-multiselect';
import CustomInput from '../common/CustomInput.vue';

export default {
  name: 'UserDetails',
  components: {
    ViewHeader,
    Multiselect,
    CustomInput
  },
  props: {
    id: {
      type: String,
      default: () => ''
    },
    supplierId: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      user: {
        id: this.id,
        supplierId: this.supplierId
      },
      groups: [],
      selectedGroups: [],
      loadingUser: false,
      loadingGroups: false
    };
  },
  computed: {
    isNew: function() {
      return this.user.id === 'new';
    },
    disableGroups: function() {
      if (this.user.supplierId) {
        return true;
      }
      return false;
    }
  },
  mounted() {
    this.fetchUser();
    this.fetchGroups();

    if (!this.isNew) {
      Vue.nextTick().then(() => {
        this.$refs.userObserver.validate();
      });
    }
  },
  methods: {
    fetchUser() {
      if (this.isNew) {
        this.loadingUser = false;
        return;
      }
      this.loadingUser = true;

      UserApi.getUser(this.user.id, this.user.supplierId)
        .then(response => {
          console.log('fetch user successful: ', response);
          this.setUser(response);
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loadingUser = false;
        });
    },
    fetchGroups() {
      this.loadingGroups = true;

      UserApi.getGroups()
        .then(response => {
          console.log('fetch groups successful: ', response);
          response.forEach(group => {
            group.description = this.$t(`cognitoGroups.${group.groupName}`);
            this.groups.push(group);
          });
        })
        .catch(error => {
          console.log(error);
          Toast.showErrorMessage(this, this.$t(`messages.error.delete`));
        })
        .finally(() => {
          this.loadingGroups = false;
        });
    },
    setUser(user) {
      user.groups.forEach(group => {
        group.description = this.$t(`cognitoGroups.${group.groupName}`);
      });
      this.user = user;
      this.$refs.userObserver.reset();
    },
    groupsChanged() {
      Vue.nextTick().then(() => {
        this.$refs.userObserver.validate();
      });
    },
    navigateToList() {
      router.push({ name: 'users' });
    },
    save() {
      if (this.isNew) {
        this.postUser();
      } else {
        this.putUser();
      }
    },
    deleteUser() {
      this.$bvModal
        .msgBoxConfirm(this.$t(`messages.info.confirmDeleteUser`), {
          cancelTitle: this.$t(`buttons.no`),
          okTitle: this.$t(`buttons.yes`)
        })
        .then(confirmed => {
          if (confirmed) {
            UserApi.deleteUser(this.user.id, this.user.supplierId)
              .then(response => {
                console.log('delete successful ', response);
                this.navigateToList();
              })
              .catch(error => {
                console.log(error);
                Toast.showErrorMessage(this, this.$t(`messages.error.delete`));
              });
          }
        })
        .catch(err => {
          console.log('error confirming deletion', error);
        });
    },
    putUser() {
      UserApi.putUser(this.user, this.user.id)
        .then(response => {
          console.log('put successful ', response);
          this.setUser(response);
          Toast.showSuccessMessage(this, this.$t(`messages.success.saveUser`));
        })
        .catch(error => {
          console.log(error);
          Toast.showErrorMessage(this, this.$t(`messages.error.save`));
        });
    },
    postUser() {
      UserApi.postUser(this.user)
        .then(response => {
          console.log('post successful ', response);
          this.setUser(response);
          Toast.showSuccessMessage(this, this.$t(`messages.success.saveUser`));
        })
        .catch(error => {
          console.log(error);
          Toast.showErrorMessage(this, this.$t(`messages.error.save`));
        });
    }
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
.multiselect {
  width: calc(100% - 18px);
}

.multiselect__spinner:before,
.multiselect__spinner:after {
  border-color: var(--primary) transparent transparent;
}

.multiselect__tag {
  background: var(--primary);
}

.multiselect__option--highlight {
  background: var(--primary);
}
.multiselect__option--highlight:after {
  background: var(--primary);
}

.multiselect__tag-icon:after {
  color: black;
}

.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background: var(--danger);
}

.multiselect__tag-icon:focus:after,
.multiselect__tag-icon:hover:after {
  color: white;
}
</style>

<style scoped>
.invalid-groups {
  border-color: red;
  border-style: solid;
  border-radius: 5px;
  border-width: 1px;
}

.multi-align {
  overflow: visible;
}

/* .form-control {
  border-radius: 0.25rem !important;
} */

.required-prop {
  color: var(--secondary);
}
</style>
